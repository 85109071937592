import {
    Dialog,


    DialogContent,
    // DialogActions,
    DialogTitle, TextField
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from '@material-ui/icons/Search';
import { Column } from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React, { PureComponent } from 'react';
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Config from '../../../../config/index';
import * as W05F0007Actions from '../../../../redux/W0X/W05F0007/W05F0007_actions';
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
const styles = {
    paper: {
        alignSelf: 'start',
        overflowX: 'hidden'
    },
    searchTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#222B45',
        fontSize: '12px',
        marginTop: 17
    }
};


class cbLoadmore extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            loading: false,
            dataGrid: [],
            totalDataGrid: 0,
            selectedRowKeys: [],
            filterGridValue: [],

        };

        this.filter = this.props.filter;

        this.tmpSelectedRowData = [];
        this.tmpSelectedRowKeys = [];
        this.changePage = false;
        this.timer = null;
    }
    getCbData = (filter) => {
        this.setState({ loading: true });
        switch (this.props.keyExpr) {
            case 'UnitPackID':
                this.props.W05F0007Actions.getcbUnitPack(filter, (error, data) => {
                    if (error) {
                        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                    let temp = [];
                    data.rows.map((item) => {
                        temp.push({ UnitPackID: item.ObjectID, UnitPackName: item.ObjectNameU });
                        return temp;
                    });
                    this.setState({ dataGrid: temp, totalDataGrid: data.total, loading: false });
                });
                break;
            case 'WarehousePacking':
                this.props.W05F0007Actions.getcbWarehousePacking(filter, (error, data) => {
                    if (error) {
                        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                    this.setState({ dataGrid: data.rows, totalDataGrid: data.total, loading: false });
                });
                break;
            case 'VesselID':
                this.props.W05F0007Actions.getCbDelivery(filter, (error, data) => {
                    if (error) {
                        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                    this.setState({ dataGrid: data.rows, totalDataGrid: data.total, loading: false });
                });
                break;
            default:
                break;


        }
    }
    open = () => {
        const { onOpen } = this.props;
        this.getCbData(this.filter);
        this.setState({
            isOpen: true,
            selectedRowKeys: this.tmpSelectedRowKeys
        }, () => {
            if (onOpen) onOpen();
        });

    };
    close = (e) => {
        const { onClose } = this.props;
        this.setState({
            isOpen: false
        }, () => {
            if (onClose) onClose(e);
        });
    };

    onSave = () => {
        const { onSave } = this.props;
        if (onSave) onSave(this.tmpSelectedRowData);
        this.close();
    };
    // setSelected = (e) => {
    //     const { keyExpr } = this.props;
    //     const currentSelectedRowKeys = e.selectedRowsData;
    //     const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
    //     if (currentDeselectedRowKeys.length > 0) {
    //         if (currentDeselectedRowKeys.length === 1) {
    //             this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
    //                 return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
    //             });
    //             this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
    //                 return currentDeselectedRowKeys.indexOf(e) < 0;
    //             });
    //         } else {
    //             currentDeselectedRowKeys.forEach(val => {
    //                 if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
    //                     this.tmpSelectedRowKeys.push(val);
    //                 }
    //             });
    //         }

    //     } else if (currentSelectedRowKeys.length > 0) {
    //         currentSelectedRowKeys.forEach((val) => {
    //             if (this.tmpSelectedRowKeys.indexOf(val[keyExpr]) < 0) {
    //                 this.tmpSelectedRowData.push(val);
    //                 this.tmpSelectedRowKeys.push(val[keyExpr]);
    //             }
    //         });
    //     }
    // };
    onSelectionChanged = (e) => {
        const { mode, keyExpr } = this.props;
        if (this.changePage) {
            const selectedRowsData = e.selectedRowsData;
            if (selectedRowsData.length > 0) {
                selectedRowsData.forEach((val) => {
                    this.tmpSelectedRowData = [val]
                    this.tmpSelectedRowKeys = [val[keyExpr]];
                });
            }
            this.setState({ selectedRowKeys: this.tmpSelectedRowKeys });
            this.changePage = false;
        } else {
            if (mode === 'single') {
                const selectedRowsData = e.selectedRowsData;
                const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
                if (currentDeselectedRowKeys.length > 0) {
                    // console.log('currentDeselectedRowKeys', currentDeselectedRowKeys)
                    // currentDeselectedRowKeys.forEach(val => {
                    //     if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    //         this.tmpSelectedRowKeys = [];
                    //         this.tmpSelectedRowData = []
                    //     }
                    // })
                } else if (selectedRowsData.length > 0) {
                    // console.log('selectedRowsData', selectedRowsData)
                    selectedRowsData.forEach((val) => {
                        this.tmpSelectedRowData = [val]
                        this.tmpSelectedRowKeys = [val[keyExpr]];
                    });
                }
                this.setState({ selectedRowKeys: this.tmpSelectedRowKeys });
            }
            // else {
            //     this.setSelected(e);
            //     this.setState({
            //         selectedRowKeys: e.selectedRowKeys
            //     });
            // }

        }
    };
    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.getCbData(this.filter);
        this.changePage = true;
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.getCbData(this.filter);
        this.changePage = true;
    };
    onSearch = () => {
        this.getCbData(this.filter);
    }
    render() {
        const { dataSource, column, itemPerPage, title, component, children, className, style, fullWidth, maxWidth, mode,
            keyExpr, transition, onOpen, onClose, typeShort, skipPerPage, pagerFullScreen,
            reference, isStrSearch, dialogProps, listPerPage, classes,
            ...props } = this.props;
        const { isOpen, dataGrid, loading, totalDataGrid } = this.state;
        const popupTransitions = Config.helpers.popupTransitions;
        const Trans = transition && popupTransitions[transition] ? popupTransitions[transition] : Config.popupTransition;
        const searchFieldWidth = maxWidth === "sm" ? 12 : 6;
        if (props.onSave) delete props.onSave;
        return (
            <React.Fragment >
                <Dialog
                    {...dialogProps}
                    open={isOpen}
                    className={className}
                    style={style}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth ? maxWidth : "md"}
                    PaperProps={{
                        style: styles.paper,
                    }}
                    aria-labelledby={"alert-dialog-title"}
                    aria-describedby={"alert-dialog-description"}
                    onEntered={this._onEntered}
                    onClose={this.close}
                    TransitionComponent={Trans}
                >
                    <DialogTitle
                        disableTypography
                        className={"center"}
                        style={{ justifyContent: 'space-between', height: 50, margin: '4px 0' }}>
                        <Typography className={"mgr10 text-uppercase"} variant={"h6"}>{title ? title : ''}</Typography>
                        <div className={'display_row'} {...(window.innerWidth < 576 ? { style: { width: '100%', justifyContent: 'flex-end' } } : {})}>
                            <ButtonGeneral
                                name={Config.lang('ERP_Dong1')}
                                typeButton={"cancel"}
                                // disabled={loading}
                                style={{ textTransform: 'uppercase', marginRight: 15 }}
                                size={"large"}
                                onClick={this.close} />
                            <ButtonGeneral name={Config.lang("ERP_Chon")}
                                color={"info"}
                                variant={"contained"}
                                loading={loading}
                                style={{ textTransform: 'uppercase' }}
                                size={"large"}
                                onClick={this.onSave} />
                        </div>
                    </DialogTitle>
                    <DialogContent dividers style={{ height: "100%" }} className={"content"}>
                        <FormGroup>
                            {isStrSearch &&
                                <Row >
                                    <Col xs={12} sm={12} md={searchFieldWidth} lg={searchFieldWidth}>
                                        <span className={classes.searchTitle}>{Config.lang("ERP_Tim_kiem")}</span>
                                        <TextField
                                            variant={"outlined"}
                                            fullWidth
                                            onChange={(e) => {
                                                clearTimeout(this.timer);
                                                const value = e.target.value;
                                                this.timer = setTimeout(() => {
                                                    this.filter.StrSearch = value;
                                                    this.onSearch();
                                                }, 1000);
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton disabled={false}
                                                            aria-label="search"
                                                            style={{ marginRight: -21 }}
                                                            onClick={this.onSearch}
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Col>
                                </Row>}
                            <GridContainer
                                reference={ref => this.dataGrid = ref}
                                totalItems={totalDataGrid}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                listPerPage={listPerPage}
                                dataSource={dataGrid}
                                disabled={false}
                                keyExpr={keyExpr}
                                gridProps={{
                                    style: { maxHeight: 520 }
                                }}
                                pagerFullScreen={false}
                                showBorders={false}
                                columnAutoWidth={true}
                                typeShort={window.innerWidth < 768}
                                loadPanel={{
                                    enabled: loading
                                }}
                                height={"calc(100vh - 300px)"}
                                selection={{
                                    allowSelectAll: false,
                                    mode: "multiple",
                                    selectAllMode: "",
                                    showCheckBoxesMode: "always"
                                }}
                                allowColumnResizing={true}
                                filterRow={{
                                    visible: false,
                                    showOperationChooser: false,
                                }}
                                filterPanel={{
                                    visible: false,
                                }}
                                filterBuilderPopup={{
                                    onInitialized: (e) => {
                                        e.component.dispose();
                                        e.element.remove();
                                    }
                                }}
                                selectedRowKey={this.tmpSelectedRowKeys}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onSelectionChanged={this.onSelectionChanged}
                            >
                                {column.map((e) => {
                                    return (
                                        <Column
                                            key={e.dataField}
                                            caption={e.caption}
                                            dataField={e.dataField}
                                            width={e.width}
                                            alignment={e.alignment ? e.alignment : "left"}
                                        />
                                    );
                                })}
                            </GridContainer>
                        </FormGroup>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}
cbLoadmore.defaultProps = {
    isStrSearch: true,
    mode: 'single'
};
cbLoadmore.propTypes = {
    dataSource: PropTypes.any,
    column: PropTypes.any,
    keyExpr: PropTypes.string,
    open: PropTypes.bool,
    value: PropTypes.any,
    mode: PropTypes.string, //'single', 'multiple'
    className: PropTypes.string,
    style: PropTypes.string,
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,

    onClose: PropTypes.func,
    onSave: PropTypes.func,

    isStrSearch: PropTypes.bool,
    dialogProps: PropTypes.any,
    selectedRowKeys: PropTypes.any
};

const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W05F0007Actions: bindActionCreators(W05F0007Actions, dispatch)
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }), withStyles(styles))(cbLoadmore);