import React, { Component } from 'react';
import Config from '../../../../config/index';
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import Divider from "@material-ui/core/Divider";
import Modal from '../../../common/modal/modal';
import ButtonGeneral from "../../../common/button/button-general";
import { FormGroup, Row, Col } from "react-bootstrap";
import { FormLabel as Label } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import moment from 'moment';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as W05F0007Actions from '../../../../redux/W0X/W05F0007/W05F0007_actions';
import { Combo } from "../../../common/form-material";
import Filter from "../../../filter/filter";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckBox from 'devextreme/ui/check_box';
import Typography from "@material-ui/core/Typography";
const styles = {
    wordWrap: {
        '& div': {
            whiteSpace: 'normal !important',
            maxWidth: '130px',
            textAlign: 'right'
        }
    },
    showFilter: {
        '& .filter-input': {
            display: 'block !important'
        }
    }
};
class W05F0007PopupSO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataGridSO: [],
            totalDataGridSO: 0,
            selectedRowKeys: [],
            dataCboListObjects: {
                rows: [],
                total: 0
            },
            dataCboListInventoris: {
                rows: [],
                total: 0
            },
            loadingCboListObjects: false,
            loadingCboListInventories: false,
            ContractNo: '',
            VoucherNo: '',
            strSearch: '',
            LocationNo: this.props.LocationNo,
            cbLocationNo: [],
        }
        this.filter = {
            ContractNo: '',
            Buyer: '',
            VoucherNo: '',
            InventoryID: '',
            StrSearch: '',
            skip: 0,
            limit: 15,
            Language: Config.language,
            MappingID: this.props.MappingID,
            LocationNo: this.props.LocationNo,
        }
        this.tmpSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
        this.filterCboListObject = {
            strSearch: "",
            ObjectTypeID: "",
            skip: 0,
            limit: 10
        };
        this.filterCboListInventories = {
            strSearch: "",
            ContractID: "",
            skip: 0,
            limit: 10
        };
        this.changePage = false;
        this.isFirst = true;
        this.oldKey = [];
    }
    componentDidMount() {
        this.getSaleContract();
        const { selectedRowKeysSO } = this.props;
        this.oldKey = selectedRowKeysSO.map(rs => { return rs; });
    }
    getSaleContract = () => {
        this.setState({ loading: true });
        this.props.W05F0007Actions.getSaleContract(this.filter, (error, data) => {
            if (error) {
                let message = error.message || Config.lang('ERP_Loi_chua_xac_dinh');
                Config.popup.show("INFO", message);
                return false;
            }
            this.tmpSelectedRowKeys = this.props.selectedRowKeysSO;
            this.setState({ totalDataGridSO: data.total, dataGridSO: data.rows, loading: false, selectedRowKeys: this.tmpSelectedRowKeys });
        });
    }
    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ['loading' + key]: status });
    };
    loadCboListObjects = (isReset) => {
        const { skip, limit, ObjectTypeID, strSearch } = this.filterCboListObject;
        const params = {
            ObjectTypeID: ObjectTypeID ? ObjectTypeID : "KH",
            StrSearch: strSearch ? strSearch : "",
            skip: skip,
            limit: limit
        };
        this.setLoading('CboListObjects', true);
        this.props.W05F0007Actions.getListObjects(params, (error, data) => {
            this.setLoading('CboListObjects', false);
            if (error) {
                let message = error.message || Config.lang('ERP_Loi_chua_xac_dinh');
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListObjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboListObjects: {
                        rows: isReset ? rows : dataCboListObjects.rows.concat(rows),
                        total: total
                    }
                });
            }
        });
    };
    getCboListInventories = (isReset) => {
        const { skip, limit, ContractID, strSearch } = this.filterCboListInventories;
        const params = {
            ContractID: ContractID ? ContractID : "",
            StrSearch: strSearch ? strSearch : "",
            skip: skip,
            limit: limit
        };
        this.setLoading('CboListInventories', true);
        this.props.W05F0007Actions.getInventories(params, (error, data) => {
            this.setLoading('CboListInventories', false);
            if (error) {
                let message = error.message || Config.lang('ERP_Loi_chua_xac_dinh');
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListInventoris } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboListInventoris: {
                        rows: isReset ? rows : dataCboListInventoris.rows.concat(rows),
                        total: total
                    }
                });
            }
        });
    };

    getCbLocationNo = () => {
        this.props.W05F0007Actions.getCbLocationNo({ Language: Config.language || 84, FormID: 'W05F0007' }, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ cbLocationNo: data });
        });
    }

    onLoadDataFilter = () => {
        this.loadCboListObjects();
        this.getCboListInventories();
        this.getCbLocationNo();
    };
    // setSelected = async (e, cb) => {
    //     const keyExpr = 'QuotationItemID';
    //     const currentSelectedRowKeys = e.selectedRowsData;
    //     const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
    //     if (currentDeselectedRowKeys.length > 0) {
    //         this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
    //             return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
    //         });
    //         this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
    //             return currentDeselectedRowKeys.indexOf(e) < 0;
    //         });
    //         if (cb) cb();
    //     } else if (currentSelectedRowKeys.length > 0) {
    //         if (this.isFirst) {
    //             if (this.state.selectedRowKeys.length > 0) {
    //                 this.tmpSelectedRowKeys = this.state.selectedRowKeys;
    //                 this.tmpSelectedRowData = currentSelectedRowKeys;
    //             } else {
    //                 this.tmpSelectedRowData = currentSelectedRowKeys;
    //                 currentSelectedRowKeys.forEach(rs => {
    //                     this.tmpSelectedRowKeys.push(rs[keyExpr]);
    //                 });
    //             }
    //             this.isFirst = false;
    //         } else {
    //             await currentSelectedRowKeys.forEach((val) => {
    //                 if (this.tmpSelectedRowKeys.indexOf(val[keyExpr]) < 0) {
    //                     const isValid = this.tmpSelectedRowData.find(rs => rs[keyExpr] !== val[keyExpr]);
    //                     if (isValid !== undefined) {
    //                         this.tmpSelectedRowData.push(val);
    //                     }
    //                     this.tmpSelectedRowKeys.push(val[keyExpr]);
    //                 }
    //             });
    //             this.isFirst = false;
    //         }
    //         if (cb) cb();
    //         // console.log(this.tmpSelectedRowKeys)
    //     }

    // };
    // onSelectionChanged = (e) => {
    //     if (this.changePage) {
    //         this.setState({ selectedRowKeys: this.tmpSelectedRowKeys });
    //         this.changePage = false;
    //     } else {
    //         this.setSelected(e, () => {
    //             this.setState({
    //                 selectedRowKeys: e.selectedRowKeys
    //             });
    //         });

    //     }
    // };

    handleChecked = (currentSelectedRowKeys, keyExpr) => {
        currentSelectedRowKeys.forEach((val) => {
            if (this.tmpSelectedRowData.indexOf(val) < 0) {
                this.tmpSelectedRowData.push(val);
                this.tmpSelectedRowKeys.push(val[keyExpr]);
            }
        });
    }

    handleUnChecked = (currentDeselectedRowKeys, keyExpr) => {
        this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
        });
        this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e) < 0;
        });
    }

    onSelectionChanged = (e) => { // Fix bug chuyển trang mấy check lần đầu tiên
        const keyExpr = "QuotationItemID";
        const currentSelectedRowKeys = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (this.changePage) {
            if (currentSelectedRowKeys.length > 0) {
                this.handleChecked(currentSelectedRowKeys, keyExpr);
            }
            this.changePage = false;
        } else {
            if (currentDeselectedRowKeys.length > 0) {// UnSelect
                this.handleUnChecked(currentDeselectedRowKeys, keyExpr);
            }
            else if (currentSelectedRowKeys.length > 0) {
                this.handleChecked(currentSelectedRowKeys, keyExpr);
            }
            this.setState({ selectedRowKeys: e.selectedRowKeys });
        }
    }

    onSelect = () => {
        this.tmpSelectedRowData.map(item => {
            item.isUsed = 0;
            item.MAPQuantity = 0;
            item.Quantity = item.SUMQuantity;
            return item;
        });
        const uniqueDataKeys = [...new Set(this.tmpSelectedRowKeys)];
        this.props.refW05F0007.getDataPopup(this.tmpSelectedRowData, uniqueDataKeys, 'SO');
        this.props.refW05F0007.onOpenList('SO');
    }
    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.changePage = true;
        this.getSaleContract();
    }
    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.changePage = true;
        this.getSaleContract();
    };
    handleFilterChange(key, data) {
        if (!key) return false;
        switch (key) {
            case "ContractNo":
                // this.filter.ContractNo = data.target.value;
                this.setState({ ContractNo: data.target.value });
                break;
            case "VoucherNo":
                this.setState({ VoucherNo: data.target.value });
                break;
            case "ObjectID":
                this.filter.Buyer = data.value !== null ? data.value.ObjectID : '';
                break;
            case "InventoryID":
                this.filter.InventoryID = data.value !== null ? data.value.InventoryID : '';
                break;
            case "LocationNo":
                this.setState({ LocationNo: data.value });
                break;
            default:
                break;
        }
    }
    onFilter = () => {
        this.filter.ContractNo = this.state.ContractNo;
        this.filter.VoucherNo = this.state.VoucherNo;
        this.filter.LocationNo = this.state.LocationNo;
        this.getSaleContract();
    }
    onSearch = (e) => {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({ strSearch: e.target.value }, () => {
                this.filter.StrSearch = this.state.strSearch;
                this.getSaleContract();
            });
        }, 500
        )
    };
    renderFilters = () => {
        const { dataCboListObjects, loadingCboListObjects, dataCboListInventoris, loadingCboListInventories } = this.state;
        const { ContractNo, cbLocationNo, LocationNo } = this.state;
        const { Buyer, InventoryID, } = this.filter;
        return (
            <Filter
                onTextChanged={this.onSearch}
                placeholder={Config.lang("ERP_Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                className='hidden'
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_So_hop_dong")} </Label>
                                <TextField
                                    label=''
                                    variant="outlined"
                                    value={ContractNo}
                                    onChange={(e) => this.handleFilterChange("ContractNo", e)}
                                    fullWidth
                                />
                            </FormGroup>
                            {/* <FormGroup>
                                <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_Dot_trien_khai")} </Label>
                                <TextField
                                    label=''
                                    variant="outlined"
                                    value={VoucherNo}
                                    onChange={(e) => this.handleFilterChange("VoucherNo", e)}
                                    fullWidth
                                />
                            </FormGroup> */}
                            <FormGroup>
                                <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("Lo")} </Label>
                                <Combo
                                    dataSource={cbLocationNo}
                                    displayExpr={'LocationNoDes'}
                                    valueExpr={'LocationNo'}
                                    value={LocationNo}
                                    stylingMode={'outlined'}
                                    label={''}
                                    showClearButton={true}
                                    onValueChanged={(e) => this.handleFilterChange("LocationNo", e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_Khach_hang")} </Label>
                                <Combo.LoadMore
                                    dataSource={dataCboListObjects}
                                    skip={this.filterCboListObject.skip}
                                    limit={this.filterCboListObject.limit}
                                    displayExpr={'ObjectNameU'}
                                    keyExpr={'ObjectID'}
                                    valueExpr={'ObjectID'}
                                    value={Buyer}
                                    loading={loadingCboListObjects}
                                    stylingMode={'outlined'}
                                    label={''}
                                    showClearButton={true}
                                    onValueChanged={(e) => this.handleFilterChange("ObjectID", e)}
                                    onInputChanged={(e) => {
                                        this.filterCboListObject.strSearch = e.target.value;
                                        this.filterCboListObject.skip = 0;
                                        this.loadCboListObjects(true);
                                    }}
                                    itemRender={(e) => {
                                        const { data } = e;
                                        if (!data) return null;
                                        return data.ObjectID + ' - ' + data.ObjectNameU;
                                    }}
                                    onLoadMore={(e) => {
                                        this.filterCboListObject.skip = e.skip;
                                        this.filterCboListObject.limit = e.limit;
                                        this.loadCboListObjects();
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_Hang_hoa")} </Label>
                                <Combo.LoadMore
                                    dataSource={dataCboListInventoris}
                                    skip={this.filterCboListInventories.skip}
                                    limit={this.filterCboListInventories.limit}
                                    displayExpr={'InventoryNameU'}
                                    keyExpr={'InventoryID'}
                                    valueExpr={'InventoryID'}
                                    value={InventoryID}
                                    loading={loadingCboListInventories}
                                    stylingMode={'outlined'}
                                    label={''}
                                    showClearButton={true}
                                    onValueChanged={(e) => this.handleFilterChange("InventoryID", e)}
                                    onInputChanged={(e) => {
                                        this.filterCboListInventories.strSearch = e.target.value;
                                        this.filterCboListInventories.skip = 0;
                                        this.getCboListInventories(true);
                                    }}
                                    itemRender={(e) => {
                                        const { data } = e;
                                        if (!data) return null;
                                        return data.InventoryID + ' - ' + data.InventoryNameU;
                                    }}
                                    onLoadMore={(e) => {
                                        this.filterCboListInventories.skip = e.skip;
                                        this.filterCboListInventories.limit = e.limit;
                                        this.getCboListInventories();
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("ERP_Tim_kiem")}
                                    size={"large"}
                                    typeButton={"search"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onFilter}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    render() {
        const { totalDataGridSO, dataGridSO, loading } = this.state;
        const { openedSO, classes, cellRenderNumber } = this.props;
        return (
            <div>
                <Modal open={openedSO}
                    fullWidth={true}
                    maxWidth={'xl'}
                    className={classes.showFilter}
                >
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                            <div className={"display_row align-center"}>
                                <Typography variant="h6" className={"mgr10"}>{Config.lang("ERP_Danh_sach_hop_dong_ban")}</Typography>
                            </div>
                            <div>
                                <ButtonGeneral name={Config.lang('ERP_Dong1')}
                                    typeButton={'cancel'}
                                    disabled={loading}
                                    className={"mgr5"}
                                    style={{ textTransform: 'uppercase' }}
                                    size={"large"}
                                    onClick={() => this.props.refW05F0007.onOpenList('SO')} />
                                <ButtonGeneral name={Config.lang('ERP_Chon')}
                                    color={"primary"}
                                    variant={"contained"}
                                    loading={loading}
                                    style={{ textTransform: 'uppercase' }}
                                    size={"large"}
                                    onClick={this.onSelect} />
                            </div>
                        </div>
                    </Modal.Title>
                    <Divider />
                    <Modal.Content>
                        <FormGroup>
                            <Row >
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_Tim_kiem_doi_tuong")} </Label>
                                    <div >{this.renderFilters()}</div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <GridContainer
                            reference={ref => this.dataGridPopupSO = ref}
                            totalItems={totalDataGridSO}
                            itemPerPage={this.filter.limit}
                            skipPerPage={this.filter.skip}
                            listPerPage={[15, 30, 45, 60]}
                            dataSource={dataGridSO}
                            disabled={false}
                            keyExpr={"QuotationItemID"}

                            gridProps={{
                                style: {
                                    minHeight: 400,
                                }
                            }}
                            pagerFullScreen={false}
                            showBorders={false}
                            showRowLines={false}
                            columnAutoWidth={true}
                            typeShort={window.innerWidth < 768}
                            loadPanel={{
                                enabled: loading
                            }}
                            height={"calc(100vh - 250px)"}
                            selection={{
                                allowSelectAll: true,
                                mode: "multiple",
                                selectAllMode: "",
                                showCheckBoxesMode: "always"
                            }}
                            allowColumnResizing={true}
                            filterRow={{
                                visible: false,
                                showOperationChooser: false,
                            }}
                            filterPanel={{
                                visible: false,
                            }}
                            selectedRowKey={this.tmpSelectedRowKeys}
                            onChangePage={this.onChangePage}
                            onChangePerPage={this.onChangePerPage}
                            onSelectionChanged={this.onSelectionChanged}
                            onCellPrepared={e => {
                                const isValid = this.oldKey.find(rs => rs === e.row?.data?.QuotationItemID);
                                if (isValid !== undefined) {
                                    let editor = CheckBox.getInstance(e.cellElement.querySelector(".dx-select-checkbox"));
                                    editor && editor.option("disabled", true);
                                    e.cellElement.style.pointerEvents = 'none';
                                }
                            }}
                        >
                            <Column
                                dataField={'ContractID'}
                                alignment={"left"}
                                visible={false}
                            />
                            <Column
                                caption={Config.lang("ERP_So_hop_dong")} dataField={'ContractNo'} minWidth={150}
                                fixed
                            />
                            <Column
                                caption={Config.lang("ERP_Khach_hang")} dataField={'Buyer'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Dot_trien_khai")} dataField={'VoucherNo'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_tong_cua_dot_trien_khai")} cssClass={classes.wordWrap} dataField={'MTotalQuantity'} width={150}
                                cellRender={(e) => cellRenderNumber(e.displayValue)}
                            />
                            <Column
                                caption={Config.lang("Lo")} dataField={'LocationNo'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Ma_lot")} dataField={'LotNo'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Ma_hang")} dataField={'InventoryID'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Ten_hang")} dataField={'InventoryNameU'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_lot")} dataField={'TotalQuantity'} minWidth={150}
                                cellRender={(e) => cellRenderNumber(e.displayValue)}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_da_mapping")} dataField={'FMAPQuantity'} minWidth={150}
                                cellRender={(e) => cellRenderNumber(e.displayValue)}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_con_lai")} dataField={'SUMQuantity'} minWidth={150}
                                cellRender={(e) => cellRenderNumber(e.displayValue)}
                            />
                            <Column
                                caption={Config.lang("ERP_Quy_cach_dong_goi")} dataField={'PackingMethodName'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Loai_bao_dong_goi")} dataField={'PackingTypeName'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Nhan_mac")} dataField={'MarkingName'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Thong_tin_bao_bi")} dataField={'PackNote'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Thoi_gian_giao_hang_theo_lot")} width={300}
                                alignment="center"
                                cellRender={(e) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                        <Col md={6}>
                                            <Label >{e.data.DeliveryDateFrom !== null ? moment(e.data.DeliveryDateFrom).format('DD/MM/YYYY') : ''}</Label>
                                        </Col>
                                        <Label >-</Label>
                                        <Col md={6}>
                                            <Label >{e.data.DeliveryDateTo !== null ? moment(e.data.DeliveryDateTo).format('DD/MM/YYYY') : ''}</Label>
                                        </Col>
                                    </div>
                                )}
                            />
                            <Column
                                caption={Config.lang("He_so_quy_cach_dong_goi")} dataField={'PackingFactor'} minWidth={150}
                                cellRender={(e) => cellRenderNumber(e.displayValue)}
                            />
                        </GridContainer>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W05F0007Actions: bindActionCreators(W05F0007Actions, dispatch)
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(W05F0007PopupSO);