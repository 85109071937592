import React, { Component } from 'react'
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import Config from "../../../../config";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
    customPadding: {
        '& .dx-datagrid-headers': {
            '& td': {
                paddingTop: '5px !important',
                paddingBottom: '5px !important',
            }
        }
    }
}
class W05F0006MappingList extends Component {
    render() {
        const { loadMapping, mappingSources, cellRender, isLoadDone, classes } = this.props;
        return (
            <div className={classes.customPadding}>
                <GridContainer
                    dataSource={mappingSources || []}
                    loading={loadMapping}
                    disabled={!isLoadDone}
                    showBorders={false}
                    keyExpr={"MappingItemID"}
                    typePaging={"normal"}
                    pagerFullScreen={false}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    showRowLines={false}
                    height={Config.getHeightGrid() / 3}
                >
                    <Column
                        caption={Config.lang("ERP_Ma_mapping")}
                        dataField={"MappingID"}
                        alignment={"left"}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_khoa_dong_mapping")}
                        dataField={"MappingItemID"}
                        alignment={"left"}
                        width={245}
                        visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_khoa_hop_dong_ban")}
                        dataField={"ContractIDS"}
                        alignment={"left"}
                        width={245}
                        visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_khoa_dot_trien_khai")}
                        dataField={"QuotationID"}
                        alignment={"left"}
                        width={245}
                        visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_khoa_lot")}
                        dataField={"QuotationItemID"}
                        alignment={"left"}
                        width={145}
                        visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_khoa_hop_dong_mua")}
                        dataField={"ContractIDP"}
                        alignment={"right"}
                        width={245}
                        visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_khoa_dong_hop_dong")}
                        dataField={"PurchaseItemID"}
                        alignment={"right"}
                        width={245}
                        visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_So_mapping")}
                        dataField={"MappingNo"}
                        alignment={"left"}
                        width={145}
                    />
                    <Column
                        caption={Config.lang("ERP_So_hop_dong_mua")}
                        dataField={"ContractNo"}
                        alignment={"left"}
                        width={245}
                    />
                    <Column
                        caption={Config.lang("ERP_Nha_cung_cap")}
                        dataField={"Supplier"}
                        alignment={"left"}
                        width={145}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_lot")}
                        dataField={"LotNo"}
                        alignment={"left"}
                        width={145}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_hang")}
                        alignment={"left"}
                        cssClass={"no-padding"}
                    >
                        <Column
                            caption={Config.lang("ERP_Hop_dong_mua")}
                            dataField={"InventoryIDPO"}
                            alignment={"left"}
                            cssClass={"no-padding"}
                            width={185}
                        />
                    </Column>
                    <Column
                        caption={Config.lang("ERP_Ten_hang")}
                        cssClass={"no-padding"}
                    >
                        <Column
                            caption={Config.lang("ERP_Hop_dong_mua")}
                            dataField={"InventoryNamePO"}
                            width={185}
                            cssClass={"no-padding"}
                        />
                    </Column>
                    <Column
                        caption={Config.lang("ERP_Ma_hang")}
                        cssClass={"no-padding"}
                    >
                        <Column
                            caption={Config.lang("ERP_Hop_dong_ban")}
                            dataField={"InventoryIDSO"}
                            width={185}
                            cssClass={"no-padding"}
                        />
                    </Column>
                    <Column
                        caption={Config.lang("ERP_Ten_hang")}
                        cssClass={"no-padding"}
                    >
                        <Column
                            caption={Config.lang("ERP_Hop_dong_ban")}
                            dataField={"InventoryNameSO"}
                            cssClass={"no-padding"}
                            width={185}
                        />
                    </Column>
                    <Column
                        caption={Config.lang("ERP_So_luong")}
                        alignment={"right"}
                        cssClass={"no-padding"}
                    >
                        <Column
                            caption={Config.lang("ERP_Bao_yeu_cau")}
                            dataField={"QuantityPack"}
                            alignment={"right"}
                            cssClass={"no-padding"}
                            width={115}
                        />
                    </Column>
                    <Column
                        caption={Config.lang("ERP_So_luong_yeu_cau")}
                        alignment={"right"}
                        cssClass={"no-padding"}
                    >
                        <Column
                            caption={Config.lang("Mapping")}
                            dataField={"Quantity"}
                            alignment={"right"}
                            cssClass={"no-padding"}
                            width={185}
                        />
                    </Column>
                    <Column
                        caption={Config.lang("ERP_So_luong")}
                        alignment={"right"}
                        cssClass={"no-padding"}
                    >
                        <Column
                            caption={Config.lang("ERP_Thuc_hien_mapping")}
                            dataField={"QuantityActual"}
                            alignment={"right"}
                            cssClass={"no-padding"}
                            width={185}
                        />
                    </Column>
                    <Column
                        caption={Config.lang("ERP_So_luong_con_lai")}
                        dataField={"QuantityRemain"}
                        alignment={"right"}
                        width={185}
                    />
                    <Column caption={Config.lang("ERP_Thoi_gian_dong_hang")} alignment="center" >
                        <Column
                            caption={Config.lang("ERP_Tu")}
                            dataField={"StartDate"}
                            alignment={"center"}
                            dataType={"date"}
                            width={150}
                            format={'dd/MM/yyyy'}
                        />
                        <Column
                            caption={Config.lang("ERP_Den")}
                            dataField={"EndDate"}
                            alignment={"center"}
                            dataType={"date"}
                            width={150}
                            format={'dd/MM/yyyy'}
                        />
                    </Column>
                    <Column
                        alignment={"right"}
                        fixed={true}
                        fixedPosition={"right"}
                        visible={!Config.isMobile}
                        cellRender={(e) => cellRender(e)}
                    />
                </GridContainer>
            </div>
        )
    }
}
export default withStyles(styles)(W05F0006MappingList);