import React, { Component } from 'react';
import { Tabs, Tab, Radio } from "@material-ui/core";
import { Col } from "react-bootstrap";
import { FormLabel as Label, } from "@material-ui/core";
import Config from '../../../../config/index';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import GridContainer from "../../../grid-container/grid-container";
import { Column, } from "devextreme-react/data-grid";
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import moment from 'moment';
import withStyles from "@material-ui/core/styles/withStyles";
import CheckBox from 'devextreme/ui/check_box';
import GridActionBar from '../../../grid-container/grid-actionbar';
import { ButtonIcon, Tooltip } from 'diginet-core-ui/components';
const styles = {
    dataGridHover: {
        left: '0 !important',
        '& button': {
            marginBottom: '0 !important',
            height: '10px',
            width: '10px',
        }
    },
    columnGrid: {
        height: '37px !important',
        minHeight: '37px !important',
    },
    wordWrap: {
        '& div': {
            whiteSpace: 'normal !important',
            maxWidth: '130px',
            textAlign: 'right'
        }
    },
    radioButton: {
        width: '61px !important'
    }
};
class W05F0007SO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 1,
        }
    }
    componentDidMount() {
        if (this.props.onRefSO) this.props.onRefSO(this);
    }
    handleChanged = (event, value) => {
        if (!this.props.disabledAllForSave) {
            this.setState({ indexTab: value });
        }
    };
    onDelete = (e) => {
        const dataFilter = this.props.dataGridMappingInfo.filter(rs => rs.QuotationItemID === e.data.QuotationItemID);
        if (dataFilter.length > 0) {
            Config.popup.show("INFO", "Dữ liệu đã được sử dụng. Bạn không được xóa");
        } else {
            Config.popup.show(
                "YES_NO",
                Config.lang("ERP_Ban_co_chac_muon_xoa?"),
                () => {
                    e.component.deleteRow(e.rowIndex);
                    this.onChangeSO(e.data);
                    this.props.refW05F0007.onCheckDelete();
                    this.props.refW05F0007.setIsUpdate();
                    // this.gridTab0.instance.saveEditData();
                },
                () => {
                    //no
                }
            );
        }
    }
    renderActions = (e) => {
        return (
            <GridActionBar >
                <Tooltip title={Config.lang('Xoa')}>
                    <ButtonIcon
                        circular
                        size="small"
                        viewType="text"
                        name="delete"
                        onClick={() => this.onDelete(e)} />
                </Tooltip>
            </GridActionBar>
        )
    }
    onChangeSO = (data) => {
        let { dataGridSO, isSwapMapping, itemSwap, oldDataGridSOSwap, disabledAllForSave } = this.props;
        if (disabledAllForSave) return false;
        if (isSwapMapping) {
            let dataSOFilter = oldDataGridSOSwap.map(rs => ({ ...rs }));
            dataSOFilter.map(item => {
                if (itemSwap.QuotationItemID === item.QuotationItemID) {
                    item.Quantity = itemSwap.Quantity + item.Quantity;
                    item.MAPQuantity = item.MAPQuantity - itemSwap.Quantity;
                }
                if (item.QuotationItemID === data.QuotationItemID) {
                    item.Quantity = item.Quantity - itemSwap.Quantity;
                    item.MAPQuantity = item.MAPQuantity + itemSwap.Quantity;
                    item.isUsed = 1;
                } else {
                    item.isUsed = 0;
                }
                return item;
            });
            this.props.refW05F0007.onSetDataGridSwap('dataGridSO', dataSOFilter);
        } else {
            dataGridSO.map(item => {
                if (item.QuotationItemID === data.QuotationItemID) {
                    item.isUsed = 1;
                } else {
                    item.isUsed = 0;
                }
                return item;
            });
            this.props.refW05F0007.onCheckSO();
        }
        this.setState({ loading: false });

    }
    cellRenderRadio = (e) => {
        return (
            <Radio style={{ width: 21, height: 21 }} size="small" color="primary" onChange={() => this.onChangeSO(e.data)} value={e.data.isUsed} checked={e.data.isUsed === 1 ? true : false} />
        )
    }

    setSelectedRowDetail = (e) => {
        if (!e) return;
        let { dataGridSO } = this.props;
        let rowIndex = 0;
        dataGridSO.map((item, index) => {
            if (item.QuotationItemID === e.QuotationItemID) {
                rowIndex = index;
                item.isUsed = 1;
            } else {
                item.isUsed = 0;
            }
            return item;
        });
        if (this.gridTab1) {
            this.gridTab1.instance.selectRowsByIndexes([rowIndex]);
        } else {
            this.gridTab0.instance.selectRowsByIndexes([rowIndex]);
        }
    }

    render() {
        const { indexTab } = this.state;
        const { dataGridSO, isView, classes, refW05F0007, isSwapMapping, itemSwap, disabledAllForSave } = this.props;


        return (
            <div>
                <div className="text-row">
                    {!isSwapMapping && !disabledAllForSave &&
                        <Tooltip title={Config.lang('Them')}>
                            <div className="text-row cursor-pointer user-select" onClick={() => !isView && this.props.refW05F0007.onOpenList('SO')}>
                                {!isView && <AddCircleIcon />}
                                <Label style={{ fontWeight: 500, color: 'black' }} className="cursor-pointer pdl10 text-title" >{Config.lang('ERP_Hop_dong_ban')}</Label>
                            </div>
                        </Tooltip>
                    }
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        value={indexTab}
                        variant="scrollable"
                        scrollButtons={Config.isMobile ? "on" : "off"}
                        onChange={this.handleChanged}
                    >
                        <Tab
                            value={0}
                            label={Config.lang("ERP_Thong_tin_chung")}
                            onClick={() => { }}
                        />
                        <Tab
                            value={1}
                            label={Config.lang("ERP_Chi_tiet")}
                            onClick={() => { }}
                        />
                    </Tabs>
                </div>
                <TabContent
                    style={{ paddingBottom: 15, backgroundColor: '#fff' }}
                    activeKey={indexTab}
                    disableSwipe={true}
                    disableTranstion={true}
                    lazyLoading={true}
                >
                    <TabPanel index={0}>
                        <React.Fragment>
                            <GridContainer
                                reference={ref => this.gridTab0 = ref}
                                dataSource={dataGridSO}
                                columnAutoWidth={true}
                                listPerPage={[5, 10, 15, 30, 45, 60]}
                                itemPerPage={5}
                                typePaging={"normal"}
                                pagerFullScreen={false}
                                keyExpr={'QuotationItemID'}
                                elementAttr={{ class: `w05f0007-grid` }}
                                height={300}
                                typeShort={true}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                sorting={{
                                    mode: "none"
                                }}
                                onCellPrepared={e => {
                                    if (isSwapMapping) {
                                        if (e.row?.data && itemSwap.Quantity > e.row?.data?.Quantity && e.row?.data?.isUsed !== 1) {
                                            let editor = CheckBox.getInstance(e.cellElement.querySelector(".dx-select-checkbox"));
                                            editor && editor.option("disabled", true);
                                            e.cellElement.style.pointerEvents = 'none';
                                            e.cellElement.style.backgroundColor = '#efefef';
                                        }
                                    }
                                }}
                            >
                                <Column
                                    caption={Config.lang("ERP_Chon")} alignment="center" dataField={'isUsed'} minWidth={50} cellRender={this.cellRenderRadio} visible={!isView}
                                    cssClass={classes.columnGrid + ' ' + classes.radioButton}
                                />
                                <Column width={10} />
                                <Column
                                    caption={Config.lang("ERP_So_hop_dong")} dataField={'ContractNo'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Khach_hang")} dataField={'BuyerName'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Phuong_tien")} dataField={'Transportation'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Dot_trien_khai")} dataField={'VoucherNo'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Thoi_gian_giao_hang_du_kien")} width={300}
                                    dataField={'DeliveryDate'}
                                    alignment="center"
                                    cellRender={(e) => (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                            <Col md={6}>
                                                <Label >{e.data.ValidFrom !== null ? moment(e.data.ValidFrom).format('DD/MM/YYYY') : ''}</Label>
                                            </Col>
                                            <Label >-</Label>
                                            <Col md={6}>
                                                <Label >{e.data.ValidTo !== null ? moment(e.data.ValidTo).format('DD/MM/YYYY') : ''}</Label>
                                            </Col>
                                        </div>
                                    )}
                                />
                                <Column
                                    caption={Config.lang("ERP_So_luong_tong_cua_dot_trien_khai")} cssClass={classes.wordWrap} dataField={'TotalQuantity'} allowEditing={false} width={180}
                                />
                                <Column
                                    cellRender={this.renderActions}
                                    visible={(!isView || Config.isMobile) && !isSwapMapping && !disabledAllForSave}
                                    alignment='center'
                                    width={80}
                                    cssClass={classes.columnGrid}
                                    fixed={true}
                                    fixedPosition={"right"}
                                />
                            </GridContainer>
                        </React.Fragment>
                    </TabPanel>
                    <TabPanel index={1}>
                        <React.Fragment>
                            <GridContainer
                                reference={ref => this.gridTab1 = ref}
                                dataSource={dataGridSO}
                                columnAutoWidth={true}
                                listPerPage={[5, 10, 15, 30, 45, 60]}
                                itemPerPage={5}
                                typePaging={"normal"}
                                pagerFullScreen={false}
                                keyExpr={'QuotationItemID'}
                                elementAttr={{ class: `w05f0007-grid` }}
                                height={300}
                                typeShort={true}
                                showColumnLines={false}
                                showRowLines={false}
                                hoverStateEnabled={true}
                                sorting={{
                                    mode: "none"
                                }}
                                onCellPrepared={e => {
                                    if (isSwapMapping) {
                                        if (e.row?.data && itemSwap.Quantity > e.row?.data?.Quantity && e.row?.data?.isUsed !== 1) {
                                            let editor = CheckBox.getInstance(e.cellElement.querySelector(".dx-select-checkbox"));
                                            editor && editor.option("disabled", true);
                                            e.cellElement.style.pointerEvents = 'none';
                                            e.cellElement.style.backgroundColor = '#efefef';
                                        }
                                    }
                                }}
                            >
                                <Column
                                    caption={Config.lang("ERP_Chon")} alignment="center" dataField={'isUsed'} minWidth={50} cellRender={this.cellRenderRadio} visible={!isView}
                                    cssClass={classes.columnGrid + ' ' + classes.radioButton}
                                />
                                <Column width={10} />
                                <Column
                                    caption={Config.lang("ERP_Ma_lot")} dataField={'LotNo'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Ma_hang")} dataField={'InventoryID'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Ten_hang")} dataField={'InventoryNameU'} allowEditing={false} minWidth={200}
                                />
                                <Column
                                    caption={Config.lang("ERP_So_luong_lot")} dataField={'TotalQuantity'} allowEditing={false} minWidth={150}
                                    cellRender={refW05F0007.renderNumberFormat}
                                />
                                <Column
                                    caption={Config.lang("ERP_So_luong_co_the_mapping")} cssClass={classes.wordWrap} dataField={'SUMQuantity'} allowEditing={false} width={150}
                                    cellRender={refW05F0007.renderNumberFormat}
                                />
                                <Column
                                    caption={Config.lang("ERP_So_luong_da_mapping")} dataField={'MAPQuantity'} allowEditing={false} minWidth={200}
                                    cellRender={refW05F0007.renderNumberFormat}
                                />
                                <Column
                                    caption={Config.lang("ERP_So_luong_con_lai")} dataField={'Quantity'} allowEditing={false} minWidth={150}
                                    cellRender={refW05F0007.renderNumberFormat}
                                />

                                <Column
                                    caption={Config.lang("ERP_Quy_cach_dong_goi")} dataField={'PackingMethodName'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Loai_bao_dong_goi")} dataField={'PackingTypeName'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Nhan_mac")} dataField={'MarkingName'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Thong_tin_bao_bi")} dataField={'PackNote'} allowEditing={false} minWidth={150}
                                />

                                <Column
                                    caption={Config.lang("ERP_Giam_dinh")} dataField={'EstimateName'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Phun_trung")} dataField={'Fumigation'} allowEditing={false} minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Thoi_gian_giao_hang_theo_lot")} width={300}
                                    dataField={'DeliveryDate'}
                                    alignment="center"
                                    cellRender={(e) => (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                            <Col md={6}>
                                                <Label >{e.data.DeliveryDateFrom !== null ? moment(e.data.DeliveryDateFrom).format('DD/MM/YYYY') : ''}</Label>
                                            </Col>
                                            <Label >-</Label>
                                            <Col md={6}>
                                                <Label >{e.data.DeliveryDateTo !== null ? moment(e.data.DeliveryDateTo).format('DD/MM/YYYY') : ''}</Label>
                                            </Col>
                                        </div>
                                    )}
                                />
                                <Column
                                    cellRender={this.renderActions}
                                    visible={(!isView || Config.isMobile) && !isSwapMapping && !disabledAllForSave}
                                    alignment='center'
                                    width={80}
                                    cssClass={classes.columnGrid}
                                    fixed={true}
                                    fixedPosition={"right"}
                                />
                            </GridContainer>
                        </React.Fragment>
                    </TabPanel>
                </TabContent>
            </div>
        );
    }
}

export default withStyles(styles)(W05F0007SO);
