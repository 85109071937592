import React, { Component } from 'react';
import { FormGroup, } from "react-bootstrap";
import { FormLabel as Label, } from "@material-ui/core";
import ButtonGeneral from "../../../common/button/button-general";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from '../../../../config/index';
import { browserHistory } from 'react-router';
import W07F0007SO from './W05F0007SO';
import './W05F0007.scss';
import W05F0007PopupSO from './W05F0007PopupSO';
import W07F0007PO from './W05F0007PO';
import W05F0007PopupPO from './W05F0007PopupPO';
import W05F0007MappingInfo from './W05F0007MappingInfo';
import { Collapse } from 'reactstrap';
import * as W05F0007Actions from '../../../../redux/W0X/W05F0007/W05F0007_actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import W05F007PackingInfo from './W05F0007PackingInfo';
import { LoadPanel } from 'devextreme-react/load-panel';
import ViewHistory from '../../../common/view-history/ViewHistory';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import moment from 'moment';
class W05F0007 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isView: false,
            isEdit: false,
            isSave: false,
            isAdd: false,

            isOpenContract: false,
            isOpenInfoMapping: true,
            openedSO: false,
            dataGridSO: [],
            oldDataGridSO: [],
            selectedRowKeysPopupSO: [],

            openedPO: false,
            dataGridPO: [],
            oldDataGridPO: [],
            selectedRowKeysPopupPO: [],
            decimals: [],
            cbUnitPack: [],
            cbDelivery: [],
            cbDeliveryStatus: [],
            cbWareHousePacking: [],
            cbMarking: [],
            isMapping: true,
            isOpenInfoPacking: false,
            dataGridMappingInfo: [],
            oldDataGridMappingInfo: [],
            captionP: [],
            captionS: [],

            dataGridP: [],
            oldDataGridP: [],
            dataGridS: [],
            oldDataGridS: [],
            rowDataInfoMapping: null,
            MappingID: '',
            dataHistory: [],
            isOpenHistory: true,
            loading: false,
            onDelete: false,
            isUpdated: false,

            isSwapMapping: false,
            wasSwap: false,
            itemSwap: '',
            oldDataGridPOSwap: [],
            oldDataGridSOSwap: [],
            disabledAllForSave: false
        }
        this.tmpDataPO = [];
        this.key = 0;
        this.filterUnitPack = {
            StrSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 15
        };
        this.filterWareHousePacking = {
            StrSearch: "",
            skip: 0,
            limit: 15
        };
        this.filterHistory = {
            keyword: '',
            condition: '',
            skip: 0,
            limit: 10,
            sort: '',
        };
        this.filterCbDelivery = {
            StrSearch: "",
            skip: 0,
            limit: 15
        };

        this.isDeleteTemp = false;
        this.IsTempData = null;

    }
    getInfo = (flag) => {
        const { location } = this.props;
        if (location && location.state) {
            return {
                MappingID: location.state.MappingID,
                mode: location.state.mode,
                Mode: location.state.Mode,
                isW05F0013: location.state.isW05F0013,
                LocationNo: location.state.LocationNo,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W05F0006");
                return null;
            }
            return false;
        }
    };
    componentDidMount() {
        if (!this.getInfo(true)) return;
        this.getDecimals();
        this.getCation({ GroupInfo: 'P', InfoID: 'W05P' });
        this.getCation({ GroupInfo: 'S', InfoID: 'W05S' });
        // this.getCbDelivery();
        this.getcbDeliveryStatus();
        this.getCbMarking();
        const { MappingID, mode, isW05F0013 = false } = this.getInfo();
        if (mode) {
            this.setState({
                isView: mode === 'view', isEdit: mode === 'edit', isAdd: mode === 'add', MappingID: mode === 'add' ? '' : MappingID,
                isOpenContract: mode === 'add', openedSO: isW05F0013
            }, () => {
                if (mode !== 'add') {
                    this.getFormEdit(MappingID);
                    this.getHistory(MappingID)
                }
            });
        }
        if (mode !== 'view') {
            Config.checkRedirect(this.props.location.pathname);
        }
    }
    componentWillUnmount() {
        if (!this.state.isView) {
            Config.unCheckRedirect();
        }
    }
    getDecimals = () => {
        this.props.W05F0007Actions.getDecimals(null, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ decimals: data });
        });
    }
    getCation = (params) => {
        this.props.W05F0007Actions.getCation(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            switch (params.GroupInfo) {
                case 'P':
                    this.setState({ captionP: data });
                    break;
                case 'S':
                    this.setState({ captionS: data });
                    break;
                default:
                    break;
            }
        });
    }
    getCbDelivery = () => {
        this.props.W05F0007Actions.getCbDelivery(this.filterCbDelivery, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ cbDelivery: data });
        });
    }
    getcbDeliveryStatus = () => {
        this.props.W05F0007Actions.getcbDeliveryStatus({ Language: Config.language }, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ cbDeliveryStatus: data });
        });
    }
    getCbMarking = () => {
        this.props.W05F0007Actions.getCbMarking({ Language: Config.language }, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ cbMarking: data });
        });
    }
    setQuantityRemain = (dataGridDelivery) => {  //TÍnh QuantityRemain ngay từ lúc nhấn vào 
        let dataGridDeliveryResult = [];
        if (_.isEmpty(dataGridDelivery)) return dataGridDeliveryResult;
        dataGridDelivery.forEach((obj, idx) => {
            if (idx === 0) { // Vị trí đầu tiên 
                obj.QuantityRemain = obj.Quantity - (!_.isUndefined(obj.QuantityPack) ? Number(obj.QuantityPack) : 0);
            } else { // Các vị trí còn lại
                const previousIdx = idx - 1;
                obj.QuantityRemain = dataGridDelivery[previousIdx].QuantityRemain - (!_.isUndefined(obj.QuantityPack) ? Number(obj.QuantityPack) : 0);
            }
            dataGridDeliveryResult.push(obj);
        });
        return dataGridDeliveryResult;
    }
    getFormEdit = (MappingID) => {
        this.setState({ isSave: true });
        const { Mode } = this.getInfo();
        this.props.W05F0007Actions.getFormEdit({ MappingID: MappingID, Mode }, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                const formatField = ['StartDate', 'EndDate'];
                if (!_.isEmpty(data?.mapping)) { // Tính toán lại QuantityRemain cho Tab chi tiết giao hàng
                    data.mapping.forEach(item => {
                        formatField.forEach(f => {
                            if (item[f]) {
                                item[f] = moment(item[f]).format('YYYY-MM-DD');
                            }
                        });

                        if (!_.isEmpty(item?.delivery)) {
                            let fieldDat = Object.keys(item.delivery[0]);
                            fieldDat = fieldDat.filter(rs => rs.substring(0, 3) === 'Dat');
                            fieldDat.forEach(field => {
                                item.delivery.map(rs => {
                                    if (rs[field]) {
                                        rs[field] = moment(rs[field]).format('YYYY-MM-DD');
                                    }
                                    return rs;
                                });
                            });
                            // const dataGridDeliveryResult = this.setQuantityRemain(item.delivery);
                            // item.delivery = dataGridDeliveryResult
                        }
                        if (!_.isEmpty(item?.packing)) {
                            let fieldDat = Object.keys(item.packing[0]);
                            fieldDat = fieldDat.filter(rs => rs.substring(0, 3) === 'Dat');
                            fieldDat.forEach(field => {
                                item.packing.map(rs => {
                                    if (rs[field]) {
                                        rs[field] = moment(rs[field]).format('YYYY-MM-DD');
                                    }
                                    return rs;
                                });
                            });
                        }
                    })
                }
                this.isDeleteTemp = data.salesContract.length > 0 && Number(data.salesContract[0].IsTempData) !== 1;
                this.IsTempData = data.salesContract.length > 0 && data.salesContract[0]?.IsTempData;
                this.setState({ dataGridSO: data.salesContract, dataGridPO: data.purchaseContract, dataGridMappingInfo: data.mapping }, () => {
                    const oldDataGridSO = data.salesContract.map(rs => ({ ...rs }));
                    const oldDataGridPO = data.purchaseContract.map(rs => ({ ...rs }));
                    const oldDataGridMappingInfo = this.state.dataGridMappingInfo.map(rs => ({ ...rs, delivery: rs.delivery.map(rs1 => ({ ...rs1 })), packing: rs.packing.map(rs2 => ({ ...rs2 })) }));
                    this.setState({ oldDataGridSO: oldDataGridSO, oldDataGridPO: oldDataGridPO, oldDataGridMappingInfo: oldDataGridMappingInfo, isSave: false });
                });
            }
        });
    }
    getHistory = (MappingID) => {
        this.filterHistory.condition = JSON.stringify({ codeID: MappingID, formID: 'W05F0006' });
        this.props.W05F0007Actions.getHistory(this.filterHistory, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ dataHistory: data }, () => {

            });
        });
    }
    onOpenList = (mode) => {
        let temp = [];
        if (mode === 'SO') {
            this.state.dataGridSO.forEach(item => {
                temp.push(item.QuotationItemID)
            });
            this.setState({ openedSO: !this.state.openedSO, selectedRowKeysPopupSO: temp });
        } else {
            this.state.dataGridPO.forEach(item => {
                temp.push(item.PurchaseItemID)
            });
            this.setState({ openedPO: !this.state.openedPO, selectedRowKeysPopupPO: temp });
        }
    }
    getDataPopup = async (data, key, mode) => {
        let keyExpr = 'QuotationItemID';
        let dataFilter = [...this.state.dataGridSO];
        if (mode !== 'SO') {
            keyExpr = 'PurchaseItemID';
            dataFilter = [...this.state.dataGridPO];
        }
        let temp = [];
        let keyEmpty = [];
        key.forEach(item => {
            const dataTemp = dataFilter.find(rs => rs[keyExpr] === item);
            if (dataTemp !== undefined) {
                temp.push(dataTemp);
            } else {
                keyEmpty.push(item);
            }
        });
        data.forEach(item => {
            const dataValid = keyEmpty.find(rs => rs === item[keyExpr]);
            if (dataValid !== undefined) {
                temp.push(item);
            }
        });
        if (mode === 'SO') {
            this.setState({ dataGridSO: temp, selectedRowKeysPopupSO: key, openedSO: false, isUpdated: true });
        } else {
            this.setState({ dataGridPO: temp, selectedRowKeysPopupPO: key, openedPO: false, isUpdated: true });
        }
    }
    onCheckSO = () => {
        const isSO = this.state.dataGridSO.find(rs => rs.isUsed === 1);
        if (this.tmpDataPO.length > 0 && isSO !== undefined) {
            this.setState({ isMapping: false });
        } else {
            this.setState({ isMapping: true });
        }
    }
    onCheckPO = (data) => {
        const isSO = this.state.dataGridSO.find(rs => rs.isUsed === 1);
        this.tmpDataPO = data;
        if (data.length > 0 && isSO !== undefined) {
            this.setState({ isMapping: false });
        } else {
            this.setState({ isMapping: true });
        }
    }

    onMapping = () => {
        let dataFilter = [...this.state.dataGridMappingInfo];
        const _key = dataFilter[dataFilter.length - 1]?.MappingNo ?? 0;
        this.key = Number(_key);
        const isSO = this.state.dataGridSO.find(rs => rs.isUsed === 1);
        const { dataGridSO, dataGridPO } = this.state;
        this.tmpDataPO.forEach((item) => {
            this.key++;
            let el = {};
            el.ContractIDS = isSO.ContractID;
            el.QuotationID = isSO.QuotationID;
            el.QuotationItemID = isSO.QuotationItemID;
            el.ContractIDP = item.ContractID;
            el.PurchaseItemID = item.PurchaseItemID;
            el.MappingNo = this.key >= 10 && this.key < 100 ? '0' + (this.key) : this.key < 10 ? '00' + (this.key) : (this.key);
            el.ContractNo = item.ContractNo;
            el.Supplier = item.Supplier;
            el.LotNo = isSO.LotNo;
            el.InventoryIDPO = item.InventoryID;
            el.InventoryNamePO = item.InventoryNameU;
            el.InventoryIDSO = isSO.InventoryID;
            el.LotNo = isSO.LotNo;
            el.InventoryNameSO = isSO.InventoryNameU;
            el.MappingItemID = this.key;
            el.UnitPackID = isSO.UnitPackID !== undefined ? isSO.UnitPackID : '';
            el.UnitPackName = isSO.UnitPackName !== undefined ? isSO.UnitPackName : '';
            el.key = this.key;
            el.packing = [];
            el.delivery = [];
            el.StartDate = '';
            el.EndDate = '';
            el.PackingFactor = isSO.PackingFactor;
            el.Quantity = isSO.Quantity > item.Quantity ? item.Quantity : isSO.Quantity;
            el.QuantityPack = isSO.PackingFactor * el.Quantity;
            //SO
            const indexSo = dataGridSO.findIndex(rs => rs.QuotationItemID === isSO.QuotationItemID);
            dataGridSO[indexSo].Quantity = dataGridSO[indexSo].Quantity - el.Quantity;
            dataGridSO[indexSo].MAPQuantity = dataGridSO[indexSo].SUMQuantity - dataGridSO[indexSo].Quantity;
            //PO
            const indexPo = dataGridPO.findIndex(rs => rs.PurchaseItemID === item.PurchaseItemID);
            dataGridPO[indexPo].Quantity = dataGridPO[indexPo].Quantity - el.Quantity;
            dataGridPO[indexPo].MAPQuantity = dataGridPO[indexPo].SUMQuantity - dataGridPO[indexPo].Quantity;
            dataFilter.push(el);
        });
        this.refGridPO.setData();
        this.tmpDataPO = [];
        this.setState({ dataGridMappingInfo: dataFilter, isMapping: true, isUpdated: true });
    }
    sumData = (array, field) => {
        const SumQ = array.reduce((accumulator, currentValue) => {
            return (accumulator !== undefined ? Number(accumulator) : 0) + (currentValue[field] !== undefined ? Number(currentValue[field]) : 0)
        }, 0);
        return SumQ;
    }
    deleteDataMappingInfo = (QuotationItemID, PurchaseItemID, cb) => {
        const { dataGridMappingInfo, dataGridSO, dataGridPO } = this.state;
        const dataGridSOFilter = [...dataGridSO];
        const dataGridPOFilter = [...dataGridPO];
        let sumSO = 0;
        let sumPO = 0;
        dataGridSOFilter.map(item => {
            if (item.QuotationItemID === QuotationItemID) {
                dataGridMappingInfo.forEach(rs => {
                    if (rs.QuotationItemID === QuotationItemID) {
                        sumSO += (rs.Quantity !== undefined ? rs.Quantity : 0);
                    }
                });
                item.MAPQuantity = sumSO;
                item.Quantity = item.SUMQuantity - sumSO;
            }
            return item;
        });
        dataGridPOFilter.map(item => {
            if (item.PurchaseItemID === PurchaseItemID) {
                dataGridMappingInfo.forEach(rs => {
                    if (rs.PurchaseItemID === PurchaseItemID) {
                        sumPO += (rs.Quantity !== undefined ? rs.Quantity : 0);
                    }
                });
                item.MAPQuantity = sumPO;
                item.Quantity = item.SUMQuantity - sumPO;
            }
            return item;
        });
        if (cb) cb();
        this.setState({ dataGridSO: dataGridSOFilter, isUpdated: true });
    }
    setSumQuantity = async (oldData, newData, cb) => {
        const { dataGridMappingInfo, dataGridSO, dataGridPO } = this.state;
        const dataGridSOFilter = [...dataGridSO];
        const dataGridPOFilter = [...dataGridPO];
        const { QuotationItemID, PurchaseItemID } = oldData;
        let sumSO = 0;
        let sumPO = 0;
        let isCancel = false;
        dataGridSOFilter.map(item => {
            if (item.QuotationItemID === QuotationItemID) {
                dataGridMappingInfo.forEach(rs => {
                    if (rs.QuotationItemID === QuotationItemID) {
                        if (oldData.key === rs.key) {
                            sumSO += Number(newData.Quantity !== undefined ? newData.Quantity : 0);
                        } else {
                            sumSO += Number(rs.Quantity !== undefined ? rs.Quantity : 0)
                        }
                    }
                });
                if (item.SUMQuantity - sumSO < 0) {
                    isCancel = true;
                    if (cb) cb(true);
                } else {
                    item.MAPQuantity = sumSO;
                    item.Quantity = item.SUMQuantity - sumSO;
                }
            }
            return item;
        });
        if (!isCancel) {
            dataGridPOFilter.map(item => {
                if (item.PurchaseItemID === PurchaseItemID) {
                    dataGridMappingInfo.forEach(rs => {
                        if (rs.PurchaseItemID === PurchaseItemID) {
                            if (oldData.key === rs.key) {
                                sumPO += Number(newData.Quantity !== undefined ? newData.Quantity : 0);
                            } else {
                                sumPO += Number(rs.Quantity !== undefined ? rs.Quantity : 0)
                            }
                        }
                    });
                    if (item.SUMQuantity - sumPO < 0) {
                        isCancel = true;
                        if (cb) cb(true);
                    } else {
                        item.MAPQuantity = sumPO;
                        item.Quantity = item.SUMQuantity - sumPO;
                    }
                }
                return item;
            });
        }
        if (!isCancel) {
            if (cb) cb(isCancel);
            this.setState({ dataGridSO: dataGridSOFilter });
        }
    }
    setDataGridShip = async (oldData, newData, rowInx, rowData, cb) => {
        const dataFilter = [...this.state.dataGridS];
        let array = dataFilter.map(rs => ({ ...rs }));
        const { DeliveryID } = oldData;
        let min = 0;

        const dataFilterID = await array.filter(rs => rs.DeliveryID === DeliveryID);
        let dataQuantityRemainTemp = [];
        let QuantityRemainTemp = null;
        const indexID = dataFilterID.findIndex(item => item.DeliveryNo === oldData.DeliveryNo);
        dataFilterID.forEach((rs, index) => {
            if (indexID > index) {
                dataQuantityRemainTemp.push(rs.QuantityRemain);
            }
        });
        if (dataQuantityRemainTemp.length > 0) {
            QuantityRemainTemp = Math.min.apply(Math, dataQuantityRemainTemp);
        } else {
            QuantityRemainTemp = oldData.Quantity;
        }
        const isLast = rowInx === dataFilter.length - 1;
        let sum = 0;
        let isCanncel = false;
        dataFilter.map((rs, index) => {
            if (DeliveryID === rs.DeliveryID) {
                if (rowInx === 0) {
                    rs.IsUpdated = 1;
                    if (rowInx === 0 && index === 0) {
                        if (rs.Quantity - newData.QuantityPack < 0) {
                            cb(true);
                            isCanncel = true;
                            return null;
                        }
                        rs.QuantityRemain = rs.Quantity - newData.QuantityPack;
                        min = rs.Quantity - newData.QuantityPack;
                    } else {
                        rs.QuantityRemain = min - rs.QuantityPack;
                        min = min - rs.QuantityPack;
                    }
                } else if (isLast) {
                    if (rowInx === index) {
                        if (QuantityRemainTemp - newData.QuantityPack < 0) {
                            cb(true);
                            isCanncel = true;
                            return null;
                        }
                        rs.IsUpdated = 1;
                        rs.QuantityRemain = QuantityRemainTemp - newData.QuantityPack;
                    }
                } else if (rowInx > 0 && index > 0) {
                    rs.IsUpdated = 1;
                    if (rowInx === index) {
                        if (QuantityRemainTemp - newData.QuantityPack < 0) {
                            cb(true);
                            isCanncel = true;
                            return null;
                        }
                        rs.QuantityRemain = QuantityRemainTemp - newData.QuantityPack;
                        min = QuantityRemainTemp - newData.QuantityPack;
                    } else if (rowInx < index + 1) {
                        rs.QuantityRemain = min - rs.QuantityPack;
                        min = min - rs.QuantityPack;
                    }
                }
                if (oldData.DeliveryNo === rs.DeliveryNo) {
                    sum += (newData.QuantityPack !== undefined ? Number(newData.QuantityPack) : 0)
                } else {
                    sum += (rs.QuantityPack !== undefined ? Number(rs.QuantityPack) : 0)
                }
            } else {
                sum += (rs.QuantityPack !== undefined ? Number(rs.QuantityPack) : 0)
            }

            return rs;
        });
        if (!isCanncel) {
            if (sum <= rowData.Quantity) {
                this.state.dataGridMappingInfo.map(item => {
                    if (item.QuantityActual !== sum && rowData.MappingItemID === item.MappingItemID) {
                        item.QuantityActual = sum;
                        item.QuantityRemain = rowData.Quantity - sum;
                        item.delivery = dataFilter;
                    }
                    return item;
                });
                this.setState({ dataGridS: dataFilter });
            } else {
                this.state.dataGridMappingInfo.map(item => {
                    if (rowData.MappingItemID === item.MappingItemID) {
                        item.delivery = array;
                    }
                    return item;
                });
                if ("QuantityPack" in newData && !(sum > rowData.Quantity)) {
                    array = dataFilter.map(rs => {
                        if (rs.DeliveryItemID === dataFilter[rowInx].DeliveryItemID) {
                            rs.QuantityPack = newData.QuantityPack;
                        }
                        return ({ ...rs })
                    });
                }
                this.setState({ dataGridS: array });
            }
            if (cb) cb(sum > rowData.Quantity);
        } else {
            this.setState({ dataGridS: array });
        }

    }

    setDataGridMappingInfo = (dataFilter) => {
        this.setState({ dataGridMappingInfoTemp: dataFilter });
    }

    setDataGridPacking = async (e, rowIndex, cb) => {
        const { dataGridP, rowDataInfoMapping } = this.state;
        const dataGridPTemp = JSON.stringify(dataGridP);
        const dataGridPFilter = [...dataGridP];
        const { newData } = e;
        const isLast = rowIndex === dataGridPFilter.length - 1;
        let isCancel = false;

        await dataGridPFilter.map((rs, index) => {
            if (rowIndex === 0 && rowIndex === index) {
                if (rowDataInfoMapping.QuantityPack - newData.Num01 < 0) {
                    cb(true);
                    isCancel = true;
                    return null;
                }
                rs.Num02 = rowDataInfoMapping.QuantityPack - newData.Num01;
            } else if (isLast && index === rowIndex) {
                if (dataGridPFilter[rowIndex - 1].Num02 - newData.Num01 < 0) {
                    cb(true);
                    isCancel = true;
                    return null;
                }
                rs.Num02 = dataGridPFilter[rowIndex - 1].Num02 - newData.Num01;
            } else {
                if (rowIndex === index) {
                    if (dataGridPFilter[rowIndex - 1].Num02 - newData.Num01 < 0) {
                        cb(true);
                        isCancel = true;
                        return null;
                    }
                    rs.Num02 = dataGridPFilter[rowIndex - 1].Num02 - newData.Num01;
                } else if (rowIndex < index + 1) {
                    if (dataGridPFilter[index - 1].Num02 - dataGridPFilter[index].Num01 < 0) {
                        cb(true);
                        isCancel = true;
                        return null;
                    }
                    rs.Num02 = dataGridPFilter[index - 1].Num02 - dataGridPFilter[index].Num01;
                }
            }
            return rs;
        });
        if (isCancel) {
            this.setState({ dataGridP: JSON.parse(dataGridPTemp) });
        }
    }

    hideMapping = (cb) => {
        this.setState({ isOpenContract: false });
        if (cb) cb();
    }
    setRowDataInfoMapping = async (data) => {
        if (this.state.rowDataInfoMapping) {
            await this.refGridPackingInfo.onSaveSwipeTab(true, (isError) => {
                if (!_.isNull(data.delivery)) {
                    data.delivery.map(rs => {
                        if (_.isNull(rs.MarkingName)) {
                            rs.MarkingName = '';
                        }
                        if (_.isNull(rs.StatusName)) {
                            rs.StatusName = '';
                        }
                        return rs;
                    })
                }
                this.setState({ rowDataInfoMapping: data, dataGridP: data.packing, dataGridS: data.delivery, loading: true }, () => {
                    let keyP = 0;
                    let keyS = 0;
                    if (data.packing && data.packing.length > 0) {
                        keyP = data.packing[data.packing.length - 1].PackNo;
                        keyP = keyP.substring(keyP.lastIndexOf('.') + 1);
                    }
                    if (data.delivery && data.delivery.length > 0) {
                        keyS = data.delivery[data.delivery.length - 1].DeliveryNo;
                        keyS = keyS.substring(keyS.lastIndexOf('.') + 1);
                    }
                    this.refGridPackingInfo.setKey(Number(keyP), Number(keyS));
                    setTimeout(() => {
                        this.setState({ loading: false, isOpenInfoPacking: true });
                    }, 300);
                });
            });
        } else {
            if (!_.isNull(data.delivery)) {
                data.delivery.map(rs => {
                    if (_.isNull(rs.MarkingName)) {
                        rs.MarkingName = '';
                    }
                    if (_.isNull(rs.StatusName)) {
                        rs.StatusName = '';
                    }
                    return rs;
                })
            }
            this.setState({ rowDataInfoMapping: data, dataGridP: data.packing, dataGridS: data.delivery, loading: true }, () => {
                let keyP = 0;
                let keyS = 0;
                if (data.packing && data.packing.length > 0) {
                    keyP = data.packing[data.packing.length - 1].PackNo;
                    keyP = keyP.substring(keyP.lastIndexOf('.') + 1);
                }
                if (data.delivery && data.delivery.length > 0) {
                    keyS = data.delivery[data.delivery.length - 1].DeliveryNo;
                    keyS = keyS.substring(keyS.lastIndexOf('.') + 1);
                }
                this.refGridPackingInfo.setKey(Number(keyP), Number(keyS));
                setTimeout(() => {
                    this.setState({ loading: false, isOpenInfoPacking: true });
                }, 300);
            });
        }
    }
    setDataTemp = () => {
        this.setState({ loading: false });
    }
    onChangePageHistory = (page) => {
        this.filterHistory.skip = page * this.filterHistory.limit;
        this.getHistory(this.state.MappingID);

    }
    onChangePerPageHistory = (perPage) => {
        this.filterHistory.skip = 0;
        this.filterHistory.limit = perPage;
        this.getHistory(this.state.MappingID);
    }
    getCaptions = () => {
        const captions = {
            //MappingInfo
            QuantityPack: "ERP_Gia_mo_to_khai",
            Quantity: "ERP_So_luong_yeu_cau_cua_mapping",
            StartDate: "ERP_Thoi_gian_bat_dau_dong_hang",
            EndDate: "ERP_Thoi_gian_ket_thuc_dong_hang",

            //packing
            UnitPackName: "ERP_Don_vi_det_bao",
            WarehousePacking: "ERP_Kho_dong_hang",
        };
        return captions;
    }
    getCaptionsD = () => {
        // let skipFieldNameS = this.state.captionS.filter(rs => rs.Disabled !== 1).map((item, index) => {
        //     return item.FieldName;
        // });
        // skipFieldNameS = skipFieldNameS.concat(["IsUpdated", "QuantityRemain"]);
        //delivery
        const captions = {
            DeliveryID: "ERP_Phuong_tien_van_chuyen",
            PhoneNo: "ERP_Dien_thoai",
            QuantityPack: "ERP_So_luong_phuong_tien_da_dong_goi",
            Status: "ERP_Trang_thai_phuong_tien",
            MarkingName: "ERP_Nhan_mac"
        };
        return captions;
    }
    getOptions = (keyExpr, codeID) => {
        let options = null;
        switch (keyExpr) {
            case "QuotationItemID":
                options = {
                    codeID: codeID,
                    keyExpr: keyExpr,
                    formID: "W05F0006",
                    mode: ["add", "remove"],
                    // excludeFields: ["IsUpdated"],
                    itemRender: (e, type) => {
                        const { mode, data } = e;
                        if (mode === "addGrid") {
                            if (type === "description84") return "Lot hợp đồng bán";
                            if (type === "description01") return "Lot Of Sale Contract";
                            if (type === "newValue") return data.LotNo;
                        }
                        if (mode === "removeGrid") {
                            if (type === "description84") return "Lot hợp đồng bán";
                            if (type === "description01") return "Lot Of Sale Contract";
                            if (type === "oldValue") return data.LotNo;
                        }
                    }
                };
                break;
            case "PurchaseItemID":
                options = {
                    codeID: codeID,
                    keyExpr: keyExpr,
                    formID: "W05F0006",
                    mode: ["add", "removeGrid"],
                    // excludeFields: ["IsUpdated"],
                    itemRender: (e, type) => {
                        const { mode, data, } = e;
                        if (mode === "addGrid") {
                            if (type === "description84") return "Hàng hóa hợp đồng mua";
                            if (type === "description01") return "Inventory Of Purchase Contract";
                            if (type === "newValue") return data.InventoryID + ' - ' + data.InventoryNameU + ', Hợp đồng/Contract : ' + data.ContractNo;
                        }
                        if (mode === "removeGrid") {
                            if (type === "description84") return "Hàng hóa hợp đồng mua";
                            if (type === "description01") return "Inventory Of Purchase Contract";
                            if (type === "oldValue") return data.InventoryID + ' - ' + data.InventoryNameU + ', Hợp đồng/Contract : ' + data.ContractNo;
                        }
                    }
                };
                break;
            case "MappingItemID":
                let option = [];
                if (this.state.wasSwap) {
                    option = ["IsUpdated", "QuantityActual", "QuantityRemain", "packing", "delivery",
                        "ContractIDS", "QuotationID", "QuotationItemID", "LotNo", "InventoryIDSO", "InventoryNameSO",
                        "ContractIDP", "PurchaseItemID", "ContractNo", "Supplier", "InventoryIDPO", "InventoryNamePO",
                    ];
                } else {
                    option = ["IsUpdated", "QuantityActual", "QuantityRemain", "packing", "delivery"];
                }
                options = {
                    codeID: codeID,
                    keyExpr: keyExpr,
                    formID: "W05F0006",
                    excludeFields: option,
                    itemRender: (e, type) => {
                        const { mode, data, captions, dataField, oldData } = e;
                        if (mode === "addGrid") {
                            if (type === "action") return '0';
                            if (type === "description84") return "Mapping";
                            if (type === "description01") return "Mapping";
                            if (type === "newValue") return codeID;
                        }
                        if (mode === "editGrid") {
                            if (type === "description84") return Config.lang(captions[dataField], "vi") + ' ' + Config.lang('ERP_So_mapping', 'vi') + ' : ' + data.MappingNo;
                            if (type === "description01") return Config.lang(captions[dataField], "en") + ' ' + Config.lang('ERP_So_mapping', 'en') + ' : ' + data.MappingNo;
                            if (type === "newValue") {
                                if (dataField === 'StartDate') {
                                    return moment(data.StartDate).format('DD-MM-YYYY');
                                }
                                if (dataField === 'EndDate') {
                                    return moment(data.EndDate).format('DD-MM-YYYY');
                                }
                            }
                            if (type === "oldValue") {
                                if (dataField === 'StartDate') {
                                    return _.isNull(oldData.StartDate) ? "" : moment(oldData.StartDate).format('DD-MM-YYYY');
                                }
                                if (dataField === 'EndDate') {
                                    return _.isNull(oldData.EndDate) ? "" : moment(oldData.EndDate).format('DD-MM-YYYY');
                                }
                            }
                        }
                        if (mode === "removeGrid") {
                            if (type === "description84") return "Số Mapping";
                            if (type === "description01") return "Mapping No";
                            if (type === "oldValue") return data.MappingNo;
                        }
                    }
                };
                break;
            case "PackItemID":
                options = {
                    codeID: codeID,
                    keyExpr: keyExpr,
                    formID: "W05F0006",
                    excludeFields: ["IsUpdated", "WarehousePackingName", "QuantityRemain", "UnitPackID"],
                    itemRender: (e, type) => {
                        const { mode, data, captions, dataField, oldData } = e;
                        if (mode === "addGrid") {
                            if (type === "action") return '0';
                            if (type === "description84") return "Chi tiết bao bì";
                            if (type === "description01") return "Detail pack";
                            if (type === "newValue") return data.PackNo;
                        }
                        if (mode === "editGrid") {
                            let dataFilterP = this.state.captionP.filter(rs => rs.Disabled !== 1).find(rs => rs.FieldName === dataField);
                            if (type === "description84")
                                return (dataFilterP !== undefined ? dataFilterP.Caption84 : Config.lang(captions[dataField], "vi")) + ' ' + Config.lang('ERP_So_chi_tiet_bao_bi', 'vi') + ' : ' + data.PackNo;
                            if (type === "description01")
                                return (dataFilterP !== undefined ? dataFilterP.Caption01 : Config.lang(captions[dataField], "en")) + ' ' + Config.lang('ERP_So_chi_tiet_bao_bi', 'en') + ' : ' + data.PackNo;
                            if (type === "newValue") {
                                if (moment(data[dataField], moment.ISO_8601, true).isValid() || moment(data[dataField], 'YYYY/MM/DD', true).isValid()) {
                                    return moment(data[dataField]).format('DD-MM-YYYY');
                                }
                            }
                            if (type === "oldValue") {
                                if (moment(oldData[dataField], moment.ISO_8601, true).isValid() || moment(oldData[dataField], 'YYYY/MM/DD', true).isValid()) {
                                    return moment(oldData[dataField]).format('DD-MM-YYYY');
                                }
                            }
                        }
                        if (mode === "removeGrid") {
                            if (type === "description84") return "Chi tiết bao bì";
                            if (type === "description01") return "Detail pack";
                            if (type === "oldValue") return data.PackNo;
                        }
                    }
                };
                break;
            case "DeliveryItemID":
                options = {
                    codeID: codeID,
                    keyExpr: keyExpr,
                    formID: "W05F0006",
                    excludeFields: ["IsUpdated", "QuantityRemain", "isNew", "MarkingID"],
                    itemRender: (e, type) => {
                        const { mode, data, captions, dataField, oldData } = e;
                        if (mode === "addGrid") {
                            if (type === "action") return '0';
                            if (type === "description84") return "Chi tiết giao hàng";
                            if (type === "description01") return "Detail delivery";
                            if (type === "newValue") return data.DeliveryNo;
                        }
                        if (mode === "editGrid") {
                            let dataFilterS = this.state.captionS.filter(rs => rs.Disabled !== 1).find(rs => rs.FieldName === dataField);
                            if (type === "description84")
                                return (dataFilterS !== undefined ? dataFilterS.Caption84 : Config.lang(captions[dataField], "vi")) + ' ' + Config.lang('ERP_So_chi_tiet_giao_hang', 'vi') + ' : ' + data.DeliveryNo;
                            if (type === "description01")
                                return (dataFilterS !== undefined ? dataFilterS.Caption01 : Config.lang(captions[dataField], "en")) + ' ' + Config.lang('ERP_So_chi_tiet_giao_hang', 'en') + ' : ' + data.DeliveryNo;
                            if (type === "newValue") {
                                if (moment(data[dataField], moment.ISO_8601, true).isValid() || moment(data[dataField], 'YYYY/MM/DD', true).isValid()) {
                                    return moment(data[dataField]).format('DD-MM-YYYY');
                                }
                            }
                            if (type === "oldValue") {
                                if (moment(oldData[dataField], moment.ISO_8601, true).isValid() || moment(oldData[dataField], 'YYYY/MM/DD', true).isValid()) {
                                    return moment(oldData[dataField]).format('DD-MM-YYYY');
                                }
                            }
                        }
                        if (mode === "removeGrid") {
                            if (type === "description84") return "Chi tiết giao hàng";
                            if (type === "description01") return "Detail delivery";
                            if (type === "oldValue") return data.DeliveryNo;
                        }
                    }
                };
                break;
            default:
                break;
        }
        return options;
    }

    createHistorySwap = (MappingID, dataCu, rowData, isSO) => {
        let temp = {};
        temp.codeID = MappingID;
        temp.formID = 'W05F0006';
        temp.linkedTrans = '';
        temp.action = 1;
        temp.description84 = Config.lang(isSO ? 'ERP_Hop_dong_ban' : 'ERP_Hop_dong_mua', 'vi') + ' ' + Config.lang('ERP_So_mapping', 'vi') + ' : ' + rowData.MappingNo;
        temp.description01 = Config.lang(isSO ? 'ERP_Hop_dong_ban' : 'ERP_Hop_dong_mua', 'en') + ' ' + Config.lang('ERP_So_mapping', 'en') + ' : ' + rowData.MappingNo;
        temp.oldValue = dataCu.LotNo + ' - ' + dataCu.ContractNo;
        temp.newValue = rowData.LotNo + ' - ' + rowData.ContractNo;
        temp.hasURL = false;

        return temp;
    }
    saveHistory = async (MappingID) => {

        const { dataGridSO, oldDataGridSO, dataGridPO, oldDataGridPO, dataGridMappingInfo, oldDataGridMappingInfo, rowDataInfoMapping, wasSwap
        } = this.state;
        let data = [];
        //hợp đồng mua
        let options = this.getOptions("QuotationItemID", MappingID);
        data = await Config.createSaveHistory(dataGridSO, oldDataGridSO, this.getCaptions(), options, true);
        //hợp đồng bán
        options = this.getOptions("PurchaseItemID", MappingID);
        data = await Config.createSaveHistory(dataGridPO, oldDataGridPO, this.getCaptions(), { ...options, ortherData: data }, true);
        //thông tin mapping
        options = this.getOptions("MappingItemID", MappingID);
        data = await Config.createSaveHistory(dataGridMappingInfo, oldDataGridMappingInfo, this.getCaptions(), { ...options, ortherData: data }, true);
        // bao bì
        for (const dataCu of oldDataGridMappingInfo) {
            const rowData = dataGridMappingInfo.find(dMoi => dMoi.MappingItemID === dataCu.MappingItemID);
            if (rowData) {
                const oldPacking = dataCu.packing;
                const oldDelivery = dataCu.delivery;
                const newPacking = rowData.packing;
                const newDelivery = rowData.delivery;
                options = this.getOptions("PackItemID", MappingID);
                data = await Config.createSaveHistory(newPacking, oldPacking, this.getCaptions(), { ...options, ortherData: data }, true);
                options = this.getOptions("DeliveryItemID", MappingID);
                data = await Config.createSaveHistory(newDelivery, oldDelivery, this.getCaptionsD(), { ...options, ortherData: data }, true);
                if (wasSwap) {
                    if (rowData.QuotationItemID !== dataCu.QuotationItemID) {
                        data.push(this.createHistorySwap(MappingID, dataCu, rowData, true));
                    }
                    if (rowData.PurchaseItemID !== dataCu.PurchaseItemID) {
                        data.push(this.createHistorySwap(MappingID, dataCu, rowData, false));
                    }
                }

            }
        }
        // console.log(data)
        // this.setState({ isSave: false, });

        const params = {
            attributes: JSON.stringify(data),
        };
        await Config.saveHistory(params, (er, data) => {
            if (data) {
                Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                this.getFormEdit(MappingID);
                this.getHistory(MappingID);
                this.key = 0;
                if (rowDataInfoMapping !== null) {
                    this.refGridPackingInfo.setKey(rowDataInfoMapping.packing.length, rowDataInfoMapping.delivery.length);
                }
                this.setState({
                    isSave: false, isView: true, isEdit: false, isUpdated: false, MappingID: MappingID, isAdd: false, oldDataGridMappingInfo: dataGridMappingInfo,
                    wasSwap: false, oldDataGridSO: dataGridSO, oldDataGridPO: dataGridPO, rowDataInfoMapping: null
                }, () => {
                    Config.unCheckRedirect();
                });
            } else {
                this.setState({ isSave: false });
                Config.notify.show("error", Config.lang("ERP_Loi"), 2000);
            }
        });
    }
    onDeleteHistory = async () => {
        let params = {
            MappingID: this.state.MappingID,
            DataType: 'DeleteMapping',
            Mode: this.IsTempData
        };
        this.props.W05F0007Actions.onDelete(params, async (error, dataDelete) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                this.setState({ isSave: false });
                return false;
            }
            if (dataDelete.Status !== 0) {
                this.setState({ isSave: false });
                Config.notify.show("error", dataDelete.Message);
                return false;
            }
            let data = [];
            let el = {};
            el.codeID = this.state.MappingID;
            el.formID = 'W05F0006';
            el.action = 3;
            el.description84 = 'Mapping';
            el.description01 = 'Mapping';
            el.oldValue = this.state.MappingID;
            el.hasURL = false;
            el.linkedTrans = '';
            data.push(el);
            const params = {
                attributes: JSON.stringify(data),
            };
            await Config.saveHistory(params, (er, dataHistory) => {
                if (dataHistory) {
                    this.setState({ isSave: false, isView: true, isEdit: false });
                    Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                    // this.getFormEdit(this.state.MappingID);
                    // this.getHistory(this.state.MappingID);
                    this.key = 0;
                    browserHistory.goBack()
                } else {
                    this.setState({ isSave: false });
                    Config.notify.show("error", Config.lang("ERP_Loi"), 2000);
                }
            });
        });

    }

    onDeleteTemp = () => {
        let params = {
            MappingID: this.state.MappingID,
            DataType: 'TempDeleteMapping',
            Mode: this.IsTempData
        };
        this.props.W05F0007Actions.onDelete(params, async (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                this.setState({ isSave: false });
                return false;
            }
            if (data.Status !== 0) {
                this.setState({ isSave: false });
                Config.notify.show("error", data.Message);
                return false;
            }
            this.setState({ isSave: false, isView: true, isEdit: false });
            Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
            this.key = 0;
            browserHistory.goBack();
        });
    }

    createSave = async (newData, oldData, options) => {
        if (!(newData && oldData && typeof newData === typeof oldData)) return false;
        if (!options) return false;
        if (!newData && !oldData) return false;
        const { wasSwap } = this.state;
        //Process..
        const { keyExpr, mode,
            //  excludeFields
        } = options || {};
        let data = {
            added: [],
            edited: [],
            removed: []
        };
        // let keys = [];
        let dataType = newData ? Array.isArray(newData) ? "array" : (typeof newData === "object" ? "object" : null) : null;
        dataType = dataType ? dataType : (oldData ? Array.isArray(oldData) ? "array" : (typeof oldData === "object" ? "object" : null) : null);
        switch (dataType) {
            case "array":
                //Array..
                newData = newData ? newData : [];
                oldData = oldData ? oldData : [];
                const newDataKeys = newData.map(d => d[keyExpr]);
                const oldDataKeys = oldData.map(d => d[keyExpr]);
                const added = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) <= -1);
                const edited = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) > -1);
                const removed = oldData.filter(d => newDataKeys.indexOf(d[keyExpr]) <= -1);
                //Added..
                if (!mode || mode.length < 1 || mode.indexOf("add") > -1) {
                    added.forEach(item => {
                        data.added.push(item);
                    });
                }
                //Edited..
                if (!mode || mode.length < 1 || mode.indexOf("edit") > -1) {
                    edited.forEach(item => {
                        if (wasSwap) {
                            const dataFilter = oldData.find(rs => rs[keyExpr] === item[keyExpr]);
                            if (dataFilter) {
                                if (dataFilter.PurchaseItemID !== item.PurchaseItemID || dataFilter.QuotationItemID !== item.QuotationItemID) {
                                    data.edited.push(item);
                                } else if (item.IsUpdated === 1) {
                                    data.edited.push(item)
                                }
                            }
                        } else {
                            if (item.IsUpdated === 1) {
                                data.edited.push(item)
                            }
                        }
                        // const rowDataOld = oldData.find(d => d[keyExpr] === item[keyExpr]);
                        // keys = Object.keys(item);
                        // if (excludeFields && excludeFields.length > 0) {
                        //     keys = keys.filter(k => excludeFields.indexOf(k) <= -1);
                        // }
                        // keys.forEach(k => {
                        //     if (item[k] !== rowDataOld[k]) {
                        //         data.edited.push(item);
                        //     }
                        // });
                    });
                }
                //Removed..
                if (!mode || mode.length < 1 || mode.indexOf("remove") > -1) {
                    removed.forEach(item => {
                        data.removed.push(item);
                    });
                }
                break;
            default:
                break;
        }
        return await data;
    };

    diff = (a1, a2) => {
        return a1.concat(a2).filter((val, index, arr) => {
            return arr.indexOf(val) === arr.lastIndexOf(val);
        });
    }

    onSave = async () => {
        const { isEdit, dataGridPO, dataGridSO, dataGridMappingInfo, oldDataGridSO, oldDataGridPO, oldDataGridMappingInfo, isOpenInfoPacking } = this.state;
        await this.refGridMappingInfo?.onSaveGrid();
        if (isOpenInfoPacking) {
            this.refGridPackingInfo.onSaveSwipeTab(true);
        }
        const _dataGridMappingInfo = JSON.parse(JSON.stringify(dataGridMappingInfo));
        const itemMapping = _dataGridMappingInfo.map(rs => rs.QuotationItemID);
        const itemSO = dataGridSO.map(rs => rs.QuotationItemID);
        let difference = this.diff(itemMapping, itemSO);
        if (difference.length > 0) {
            Config.popup.show('INFO', Config.lang('Co_hop_dong_ban_chua_thuc_hien_mapping'));
            return false;
        }
        // this.setState({ isSave: true });
        const params = {
            salesContract: JSON.stringify(dataGridSO),
            purchaseContract: JSON.stringify(dataGridPO),
            mapping: JSON.stringify(_dataGridMappingInfo)
        }
        let paramEdit = null;
        if (isEdit) {

            const optionSO = {
                keyExpr: "QuotationItemID",
                mode: ["add", "remove"],
                excludeFields: '',
            };
            const dataSO = await this.createSave(dataGridSO, oldDataGridSO, optionSO);
            const optionPO = {
                keyExpr: "PurchaseItemID",
                mode: ["add", "remove"],
                excludeFields: '',
            };
            const dataPO = await this.createSave(dataGridPO, oldDataGridPO, optionPO);


            const optionMapping = {
                keyExpr: "MappingItemID",
                excludeFields: ["IsUpdated", "QuantityActual", "QuantityRemain", "packing", "delivery"],
            };
            const dataMapping = await this.createSave(_dataGridMappingInfo, oldDataGridMappingInfo, optionMapping);
            const optionPacking = {
                keyExpr: "PackItemID",
                excludeFields: ["IsUpdated", "WarehousePackingName", "QuantityRemain", "UnitPackName",],
            };
            const optionDelivery = {
                keyExpr: "DeliveryItemID",
                excludeFields: ["IsUpdated", "QuantityRemain"],
            };
            let dataPacking = [];
            let dataDelivery = [];
            const dataFilter = _dataGridMappingInfo.map(rs => ({ ...rs, delivery: rs.delivery.map(rs1 => ({ ...rs1 })), packing: rs.packing.map(rs2 => ({ ...rs2 })) }));
            for (const dataCu of oldDataGridMappingInfo) {
                const rowData = dataFilter.find(dMoi => dMoi.MappingItemID === dataCu.MappingItemID);
                if (rowData) {
                    const oldPacking = dataCu.packing;
                    const oldDelivery = dataCu.delivery;
                    const newPacking = rowData.packing;
                    const newDelivery = rowData.delivery;

                    dataPacking = await this.createSave(newPacking, oldPacking, optionPacking);
                    dataDelivery = await this.createSave(newDelivery, oldDelivery, optionDelivery);
                    //edit
                    //packing
                    if ((dataPacking.edited && dataPacking.edited.length > 0) || (dataPacking.added && dataPacking.added.length > 0) || (dataPacking.removed && dataPacking.removed.length > 0)) {
                        const editedMapping = dataMapping.edited;
                        const itemEdited = editedMapping.find(ed => ed.MappingItemID === rowData.MappingItemID)
                        rowData.packing = dataPacking;
                        if (!itemEdited) {
                            dataMapping.edited.push(rowData);
                        } else {
                            itemEdited.packing = dataPacking;
                        }
                    }
                    //delivery
                    if ((dataDelivery.edited && dataDelivery.edited.length > 0) || (dataDelivery.added && dataDelivery.added.length > 0) || (dataDelivery.removed && dataDelivery.removed.length > 0)) {
                        const editedMapping = dataMapping.edited;
                        const itemEdited = editedMapping.find(ed => ed.MappingItemID === rowData.MappingItemID)
                        rowData.delivery = dataDelivery;
                        if (!itemEdited) {
                            dataMapping.edited.push(rowData);
                        } else {
                            itemEdited.delivery = dataDelivery;
                        }
                    }
                }
            }
            paramEdit = {
                MappingID: this.state.MappingID,
                salesContract: JSON.stringify(dataSO),
                purchaseContract: JSON.stringify(dataPO),
                mapping: JSON.stringify(dataMapping)
            };
            // this.setState({ isSave: false });
            // console.log({
            //     MappingID: this.state.MappingID,
            //     salesContract: dataSO,
            //     purchaseContract: dataPO,
            //     mapping: dataMapping
            // })
            // return false;
        }
        // this.saveHistory(this.state.MappingID)
        this.props.W05F0007Actions[!isEdit ? "onInsert" : "onUpdate"](!isEdit ? params : paramEdit, async (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                this.setState({ isSave: false });
                return false;
            }
            await this.saveHistory(!isEdit ? data.MappingID : this.state.MappingID);
        });
    }
    renderNumberFormat = (e) => {
        if (!e) return false;
        const { decimals } = this.state;
        const { data } = e.row;
        const { column } = e;
        const dataField = column.dataField;
        let decimalValue = data[dataField];
        let decimalScale = 5;
        switch (dataField) {
            case "Quantity":
            case "QuantityActual":
            case "QuantityRemain":
                decimalScale = decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5;
                break;
            default:
                break;
        }
        return <NumberFormat value={decimalValue} displayType={"text"} thousandSeparator={true} decimalScale={decimalScale} />
    };
    onCheckDelete = () => {
        if (this.state.dataGridSO.length <= 0 && this.state.dataGridPO.length <= 0) {
            this.setState({ onDelete: true });
        }
    }
    setIsUpdate = () => {
        if (!this.state.isUpdated) {
            this.setState({ isUpdated: true });
        }
    }
    goBack = () => {
        if (this.state.isUpdated) {
            Config.popup.show("YES_NO", Config.lang("ERP_Du_lieu_chua_duoc_luu_ban_co_muon_tiep_tuc_khong"), () => {
                browserHistory.goBack();
            });
        } else {
            browserHistory.goBack();
        }
    }
    onSwapMapping = (e) => {
        this.state.dataGridSO.map(rs => {
            if (rs.QuotationItemID === e.data.QuotationItemID) {
                rs.isUsed = 1;
            } else {
                rs.isUsed = 0;
            }
            return rs;
        });
        this.state.dataGridPO.map(rs => {
            if (rs.PurchaseItemID === e.data.PurchaseItemID) {
                this.refGridPO.setSelectedRowKeyOnSwap(rs, [e.data.PurchaseItemID]);
            }
            return rs;
        });
        let oldDataGridSOSwap = this.state.dataGridSO.map(rs => ({ ...rs }));
        let oldDataGridPOSwap = this.state.dataGridPO.map(rs => ({ ...rs }));
        this.setState({ isSwapMapping: true, isOpenContract: true, itemSwap: e.data, oldDataGridSOSwap: oldDataGridSOSwap, oldDataGridPOSwap: oldDataGridPOSwap });
    }
    onCancelSwap = () => {
        this.state.oldDataGridSOSwap.map(rs => {
            rs.isUsed = 0;
            return rs;
        });
        this.refGridPO.setSelectedRowKeyOnSwap([], []);
        this.setState({ isSwapMapping: false, itemSwap: '', dataGridSO: this.state.oldDataGridSOSwap, dataGridPO: this.state.oldDataGridPOSwap });
    }
    onSetDataGridSwap = (mode, data) => {
        this.setState({ [mode]: data });
    }
    onSaveSwap = async () => {
        let { dataGridPO, dataGridSO, dataGridMappingInfo, itemSwap } = this.state;
        let SO = await dataGridSO.find(rs => rs.isUsed === 1);
        let PO = await dataGridPO.find(rs => rs.isUsed === 1);
        let dataMappingFilter = await dataGridMappingInfo.map(rs => ({ ...rs }));
        await dataMappingFilter.map(item => {
            if (itemSwap.MappingNo === item.MappingNo) {
                item.ContractIDS = SO.ContractID;
                item.QuotationID = SO.QuotationID;
                item.QuotationItemID = SO.QuotationItemID;
                item.LotNo = SO.LotNo;
                item.InventoryIDSO = SO.InventoryID;
                item.InventoryNameSO = SO.InventoryNameU;

                if (!_.isUndefined(PO)) {
                    item.ContractIDP = PO.ContractID;
                    item.PurchaseItemID = PO.PurchaseItemID;
                    item.ContractNo = PO.ContractNo;
                    item.Supplier = PO.Supplier;
                    item.InventoryIDPO = PO.InventoryID;
                    item.InventoryNamePO = PO.InventoryNameU;
                }
            }

            return item;
        });
        dataGridSO.map(rs => {
            rs.isUsed = 0;
            return rs;
        });
        this.refGridPO.setSelectedRowKeyOnSwap([], []);
        this.setState({
            dataGridMappingInfo: dataMappingFilter, itemSwap: '', isSwapMapping: false, wasSwap: true
        });
    }

    numberFormat = (number, limitNumber = 0) => {
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: limitNumber,
            maximumFractionDigits: limitNumber,
        });
        const _number = formatter.format(String(number));
        return !_number || _number === "NaN" ? 0 : _number;
    };

    countDecimals = (value) => {
        if (Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0;
    }

    cellRenderNumber = (number, decimal) => {
        if (Config.isEmpty(number) && number !== 0) return null;
        return this.numberFormat(number, decimal ?? this.countDecimals(number));
    }

    setSelectedRow_SO_PO = (e) => {
        this.refGridSO.setSelectedRowDetail(e);
        this.refGridPO.setSelectedRowDetail(e);
    }

    setDisabledAllForSave = (bool = false) => {
        this.setState({ disabledAllForSave: bool });
    }

    render() {
        if (!this.getInfo()) return null;
        const { Mode, LocationNo } = this.getInfo();
        const { isView, isEdit, dataGridSO, isOpenContract, openedSO, selectedRowKeysPopupSO, dataGridPO, openedPO, selectedRowKeysPopupPO, isMapping, isOpenInfoMapping,
            dataGridMappingInfo, decimals, isOpenInfoPacking, captionP, captionS, dataGridP, dataGridS, rowDataInfoMapping, cbDelivery, cbDeliveryStatus,
            cbMarking, isSave, dataHistory, isOpenHistory, oldDataGridMappingInfo, loading, isAdd, MappingID, isSwapMapping, itemSwap,
            // oldDataGridSO, oldDataGridPO,
            oldDataGridSOSwap, oldDataGridPOSwap, disabledAllForSave
        } = this.state;
        let isSaveSwap = [];
        if (isSwapMapping) {
            let SO = dataGridSO.find(rs => rs.isUsed === 1);
            let PO = dataGridPO.find(rs => rs.isUsed === 1);
            if (itemSwap.QuotationItemID !== SO.QuotationItemID) {
                isSaveSwap.push('SO');
            }
            if (PO && itemSwap.PurchaseItemID !== PO.PurchaseItemID) {
                isSaveSwap.push('PO');
            }
        }
        return (
            <div id={"W05F0007"}>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ my: 'center', of: '#root' }}
                    visible={isSave}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <LoadPanel
                    position={{ my: 'center', of: '#Packing' }}
                    visible={loading}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                />
                {openedSO && <W05F0007PopupSO MappingID={MappingID} selectedRowKeysSO={selectedRowKeysPopupSO} dataGridSO={dataGridSO} openedSO={openedSO} refW05F0007={this}
                    cellRenderNumber={this.cellRenderNumber} LocationNo={LocationNo}
                />}
                {openedPO && <W05F0007PopupPO MappingID={MappingID} selectedRowKeysPO={selectedRowKeysPopupPO} dataGridPO={dataGridPO} openedPO={openedPO} refW05F0007={this}
                    cellRenderNumber={this.cellRenderNumber}
                />}
                <FormGroup className={"W05F0007-ActionToolbar"}>
                    <ActionToolbar allwaysTop title={Config.lang("ERP_Chi_tiet_mapping")} onBack={this.goBack} >
                        <div className={"text-row "} style={{ justifyContent: 'space-between', width: '100%' }}>
                            <div>
                                {isView ?
                                    Mode === 1 ?
                                        <ButtonGeneral disabled={disabledAllForSave}
                                            name={Config.lang("ERP_Xoa")}
                                            typeButton={"delete"}
                                            color={"warning"}
                                            variant={"contained"}
                                            onClick={async () => await this.onDeleteHistory()}

                                        />
                                        :
                                        <ButtonGeneral disabled={false}
                                            name={Config.lang("ERP_Sua")}
                                            typeButton={"edit"}
                                            style={{ textTransform: 'uppercase' }}
                                            onClick={() => this.setState({ isView: false, isEdit: true, loading: false })}
                                            icon={require("./edit.svg")}
                                        /> :
                                    <>
                                        {isAdd &&
                                            <ButtonGeneral disabled={disabledAllForSave}
                                                name={Config.lang("ERP_Luu")}
                                                typeButton={"save"}
                                                style={{ textTransform: 'uppercase', }}
                                                onClick={async () => await this.onSave()}

                                            />
                                        }
                                        {this.isDeleteTemp && this.state.onDelete &&
                                            <ButtonGeneral disabled={disabledAllForSave}
                                                name={Config.lang("Xoa_tam")}
                                                typeButton={"delete"}
                                                color={"warning"}
                                                variant={"contained"}
                                                style={{ marginRight: 10 }}
                                                onClick={this.onDeleteTemp}

                                            />
                                        }
                                        {isEdit && <>
                                            {!this.state.onDelete ?
                                                <ButtonGeneral disabled={isSwapMapping || disabledAllForSave}
                                                    name={Config.lang('ERP_Luu')}
                                                    typeButton={'save'}
                                                    style={{ textTransform: 'uppercase', }}
                                                    onClick={async () => await this.onSave()}

                                                /> :
                                                <ButtonGeneral disabled={disabledAllForSave}
                                                    name={Config.lang("ERP_Xoa")}
                                                    typeButton={"delete"}
                                                    color={"warning"}
                                                    variant={"contained"}
                                                    onClick={async () => await this.onDeleteHistory()}

                                                />
                                            }
                                        </>
                                        }

                                    </>
                                }
                            </div>
                            {(isView || isEdit) && <span className={"text-uppercase"} style={{ color: '#0095FF', fontWeight: 'bold' }}>Mapping: {MappingID}</span>}
                        </div>
                    </ActionToolbar>
                </FormGroup>
                <div style={{ paddingTop: 20 }}>
                    <div className={"cursor-pointer pdb10  pdl10"} style={{ display: 'inline-block' }} onClick={() => !disabledAllForSave && this.setState({ isOpenContract: !this.state.isOpenContract })}>
                        <i className={`${isOpenContract ? "fa fa-chevron-down" : "fa fa-chevron-right"} pdr10`} aria-hidden={"true"}></i>
                        <Label style={{ color: '#0095FF', fontWeight: 'bold' }} className={"cursor-pointer text-uppercase user-select"} >{Config.lang("ERP_Hop_dong_mapping")}</Label>
                    </div>
                    <Collapse isOpen={isOpenContract}>
                        <W07F0007SO isView={isView} isEdit={isEdit} dataGridSO={dataGridSO} refW05F0007={this} dataGridMappingInfo={dataGridMappingInfo}
                            isSwapMapping={isSwapMapping} itemSwap={itemSwap} onRefSO={ref => this.refGridSO = ref} oldDataGridSOSwap={oldDataGridSOSwap}
                            disabledAllForSave={disabledAllForSave}
                        />

                        <W07F0007PO isView={isView} dataGridPO={dataGridPO} refW05F0007={this} onRefPO={ref => this.refGridPO = ref} dataGridMappingInfo={dataGridMappingInfo}
                            isSwapMapping={isSwapMapping} itemSwap={itemSwap} oldDataGridPOSwap={oldDataGridPOSwap}
                            disabledAllForSave={disabledAllForSave}
                        />

                        {!isView &&
                            <div className="pdt10 ">
                                {!isSwapMapping ?
                                    <ButtonGeneral disabled={isMapping || disabledAllForSave}
                                        name={'mapping'}
                                        typeButton={'mapping'}
                                        color={"primary"}
                                        variant={"contained"}
                                        style={{ textTransform: 'uppercase' }}
                                        onClick={this.onMapping}
                                    />
                                    :
                                    <div>
                                        <ButtonGeneral disabled={isSaveSwap.length <= 0 || disabledAllForSave}
                                            name={Config.lang('ERP_Chuyen_mapping')}
                                            variant={"contained"}
                                            color={"primary"}
                                            style={{ textTransform: 'uppercase', marginRight: 20 }}
                                            onClick={this.onSaveSwap}
                                        />
                                        <ButtonGeneral disabled={disabledAllForSave}
                                            name={Config.lang('ERP_Huy')}
                                            variant={"contained"}
                                            color={"default"}
                                            style={{ textTransform: 'uppercase', backgroundColor: '#FFAA00', color: 'white' }}
                                            onClick={this.onCancelSwap}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </Collapse>
                </div>
                {
                    dataGridMappingInfo.length > 0 &&
                    <div style={{ paddingTop: 20 }}>
                        <div className={"cursor-pointer pdb10 pdl10"} style={{ display: 'inline-block' }} onClick={() => !disabledAllForSave && this.setState({ isOpenInfoMapping: !this.state.isOpenInfoMapping })}>
                            <i className={`${isOpenInfoMapping ? "fa fa-chevron-down" : "fa fa-chevron-right"} pdr10`} aria-hidden={"true"}></i>
                            <Label style={{ color: '#0095FF', fontWeight: 'bold' }} className={"cursor-pointer text-uppercase user-select"} >{Config.lang("ERP_Thong_tin_mapping")}</Label>
                        </div>
                        <Collapse isOpen={isOpenInfoMapping}>
                            <W05F0007MappingInfo rowDataInfoMapping={rowDataInfoMapping} decimals={decimals} dataGridMappingInfo={dataGridMappingInfo} isView={isView} refW05F0007={this}
                                refGridMappingInfo={ref => this.refGridMappingInfo = ref} onSwapMapping={this.onSwapMapping} isSwapMapping={isSwapMapping} isEdit={isEdit}
                                disabledAllForSave={disabledAllForSave} cellRenderNumber={this.cellRenderNumber}
                            />
                        </Collapse>
                    </div>
                }
                {rowDataInfoMapping && dataGridMappingInfo.length > 0 &&
                    <div id={"Packing"} style={{ paddingTop: 20 }}>
                        <div className={"cursor-pointer pdb10  pdl10"} style={{ display: 'inline-block' }} onClick={() => !disabledAllForSave && this.setState({ isOpenInfoPacking: !this.state.isOpenInfoPacking })}>
                            <i className={`${isOpenInfoPacking ? "fa fa-chevron-down" : "fa fa-chevron-right"} pdr10`} aria-hidden={"true"}></i>
                            <Label style={{ color: '#0095FF', fontWeight: 'bold' }} className={"cursor-pointer text-uppercase user-select"} >{Config.lang("ERP_Thong_tin_dong_goi")}</Label>
                        </div>
                        <Collapse isOpen={isOpenInfoPacking}>
                            <W05F007PackingInfo isView={isView} refW05F0007={this} captionP={captionP} captionS={captionS} isOpenContract={isOpenContract}
                                dataGridP={dataGridP} dataGridS={dataGridS} rowDataInfoMapping={rowDataInfoMapping} filterUnitPack={this.filterUnitPack}
                                filterWareHousePacking={this.filterWareHousePacking} cbDelivery={cbDelivery} cbDeliveryStatus={cbDeliveryStatus}
                                cbMarking={cbMarking} decimals={decimals} dataGridMappingInfo={dataGridMappingInfo}
                                refGridPackingInfo={ref => this.refGridPackingInfo = ref} oldDataGridMappingInfo={oldDataGridMappingInfo}
                                filterCbDelivery={this.filterCbDelivery} isSwapMapping={isSwapMapping}
                                disabledAllForSave={disabledAllForSave} cellRenderNumber={this.cellRenderNumber}

                            />
                        </Collapse>
                    </div>
                }
                {isView &&
                    <div className={"pdl10 pdt20"}>
                        <div className={"cursor-pointer pdb10"} onClick={() => this.setState({ isOpenHistory: !this.state.isOpenHistory })}>
                            <i className={`${isOpenHistory ? "fa fa-chevron-down" : "fa fa-chevron-right"} pdr10`} aria-hidden={"true"}></i>
                            <Label style={{ color: '#0095FF', fontWeight: 'bold' }} className={"cursor-pointer text-uppercase"} >{Config.lang("ERP_Lich_su")}</Label>
                        </div>
                        <Collapse isOpen={isOpenHistory}>
                            <ViewHistory
                                dataHistory={dataHistory.rows} totalItems={dataHistory.total} limit={this.filterHistory.limit} skip={this.filterHistory.skip}
                                onChangePage={this.onChangePageHistory} onChangePerPage={this.onChangePerPageHistory}
                            />
                        </Collapse>
                    </div>
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W05F0007Actions: bindActionCreators(W05F0007Actions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(W05F0007);