import { Tab, Tabs } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ReorderIcon from '@material-ui/icons/Reorder';
import { Column } from "devextreme-react/data-grid";
import _ from 'lodash';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import Config from '../../../../config/index';
import { Combo, TextField } from "../../../common/form-material";
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import GridActionBar from '../../../grid-container/grid-actionbar';
import GridContainer from "../../../grid-container/grid-container";
// import { Lookup } from 'devextreme-react/lookup';
import HeadClick from "../../../grid-container/head-click";
import CbLoadmore from './cbLoadmore';
import { MoneyInput, Tooltip, ButtonIcon } from 'diginet-core-ui/components';
import { TextBox } from 'devextreme-react';
import moment from 'moment';

const styles = {
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
    },
    MuiPopover: {
        left: '130px !important'
    },
    wordWrap: {
        '& div': {
            whiteSpace: 'normal !important',
            textAlign: 'right'
        }
    },
    removeBorder: {
        height: '37px !important',
        "& div": {
            "&:before": {
                borderBottomStyle: 'none !important',
                border: 'none !important'
            },
            "&:after": {
                border: 'none !important'
            },
            "& input": {
                height: "37x !important",
                textAlign: 'right !important',
            },
        },
        "& span": {

        },
        "& .dx-popover ": {
            display: 'none !important',
        },
        "& .dx-invalid-message ": {
            display: 'none !important'
        },
        '& .MuiFormControl-root ': {
            margin: 0
        },
    },
    numberInput: {
        width: '100%',
        paddingRight: '8px',
        paddingTop: '9px',
    },
    columnBtnAction: {
        height: '43px !important'
    },
    removeBg: {
        '& .cell-selected': {
            backgroundColor: '#EFF5FF !important'
        }
    },
    numberFormatInput: {
        backgroundColor: 'transparent',
        margin: '0 !important',
        paddingLeft: 10,
        paddingRight: 5
    }
};
class W05F0007PackingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            indexTab: 0,
            currentPosition: "",
            popOver: null,
            allowEditDelivery: false,
            selectedRange: {},
            editMode: "cell",
            flexCaptionP: [],
            flexCaptionS: [],
            btnDeleteAllDelivery: false,
        }
        this.selectedRowIndx = 0;
        this.rowData = "";
        this.keyP = 0;
        this.keyS = 0;
        this.component = null;
        this.isError = false;
        this.selectedRange = {};
        this.selectedRowIndxPacking = 0;
    }
    handleChanged = (event, value) => {
        if (this.props.disabledAllForSave) return false;
        this.onSaveSwipeTab();
        this.setState({ indexTab: value, editMode: 'cell' });
        if (value === 0 && this.isError) {
            this.keyS--;
            this.isError = false;
        }
    };
    onSaveSwipeTab = async (bool, cb) => {
        if (!bool) {
            this.setState({ isEditing: false });
        }
        if (this.gridTabP !== null && this.gridTabP !== undefined) {
            await this.gridTabP.instance.saveEditData();
            if (cb) cb(this.isError);
            this.isError = false;
        } else if (this.gridTabS !== null && this.gridTabS !== undefined) {
            await this.gridTabS.instance.saveEditData();
            if (cb) cb(this.isError);
            this.isError = false;
        }
        if (bool) {
            this.setState({ isEditing: false });
        }
    }
    componentDidMount() {
        let btnDeleteAllDelivery = false;
        if (this.props.refGridPackingInfo) this.props.refGridPackingInfo(this);
        const { captionP, captionS, dataGridS } = this.props;
        if (!_.isEmpty(dataGridS)) dataGridS.forEach(item => {
            if (item?.IsConfirmed === 1) btnDeleteAllDelivery = true;
        })
        if (btnDeleteAllDelivery) this.setState({ btnDeleteAllDelivery });
        let flexCaptionP = [];
        let flexCaptionS = [];
        if (!_.isEmpty(captionP)) flexCaptionP = captionP.filter(rs => rs.Disabled !== 1);
        if (!_.isEmpty(captionS)) {
            flexCaptionS = captionS.filter(rs => rs.Disabled !== 1);
        }
        if (!_.isEmpty(flexCaptionP) || !_.isEmpty(flexCaptionS)) this.setState({ flexCaptionP, flexCaptionS });
    }
    setMode = () => {
        this.setState({ editMode: 'cell' });
    }
    setKey = (keyP, keyS) => {
        this.keyP = keyP !== undefined ? keyP : 0;
        this.keyS = keyS !== undefined ? keyS : 0;
    }
    onDelete = async (e) => {
        Config.popup.show(
            "YES_NO",
            Config.lang("ERP_Ban_co_chac_muon_xoa?"),
            async () => {
                if (this.state.indexTab === 0) {
                    let dataFilterP = [...this.props.dataGridP];
                    this.gridTabP.instance.deleteRow(e.rowIndex);
                    this.gridTabP.instance.saveEditData();
                    let dataNotNewP = dataFilterP.filter(rs => rs.isNew === undefined);
                    if (dataNotNewP.length > 0) {
                        let keyP = dataNotNewP[dataNotNewP.length - 1].PackNo;
                        keyP = keyP.substring(keyP.lastIndexOf('.') + 1);
                        this.keyP = keyP;
                    } else {
                        this.keyP = 0;
                    }
                    dataFilterP.map(rs => {
                        if (rs.isNew && e.data.PackNo !== rs.PackNo) {
                            this.keyP++;
                            let key = this.keyP < 10 ? '.P.000' + this.keyP : this.keyP > 10 && this.keyP < 100 ? '.P.00' + this.keyP : this.keyP > 100 && this.keyP < 1000 ? '.P.0' + this.keyP : this.keyP;
                            rs.PackNo = rs.MappingNo + key
                        }
                        return rs;
                    })
                    this.props.refW05F0007.setIsUpdate();

                } else {
                    const dataTotal = [...this.props.dataGridS];
                    this.gridTabS.instance.deleteRow(e.rowIndex);
                    this.gridTabS.instance.saveEditData();
                    this.resetDataMapping();
                    const dataFilter = [...this.props.dataGridS];
                    let min = 0;
                    const dataFilterID = dataTotal.filter(rs => rs.DeliveryID === e.data.DeliveryID);
                    let dataQuantityRemainTemp = [];
                    let QuantityRemainTemp = null;
                    const indexID = dataFilterID.findIndex(item => item.DeliveryNo === e.data.DeliveryNo);
                    dataFilterID.forEach((rs, index) => {
                        if (indexID > index) {
                            dataQuantityRemainTemp.push(rs.QuantityRemain);
                        }
                    });
                    if (dataQuantityRemainTemp.length > 0) {
                        QuantityRemainTemp = Math.min.apply(Math, dataQuantityRemainTemp);
                    } else {
                        QuantityRemainTemp = e.data.Quantity;
                    }
                    const isLast = e.rowIndex === dataTotal.length - 1;
                    dataFilter.map((rs, index) => {
                        if (rs.DeliveryID === e.data.DeliveryID) {
                            if (e.rowIndex === 0) {
                                if (e.rowIndex === 0 && index === 0) {
                                    rs.QuantityRemain = rs.Quantity - rs.QuantityPack;
                                    min = rs.Quantity - rs.QuantityPack;
                                } else {
                                    rs.QuantityRemain = min - rs.QuantityPack;
                                    min = min - rs.QuantityPack;
                                }
                            } else if (isLast) {
                                if (e.rowIndex === index) {
                                    rs.QuantityRemain = QuantityRemainTemp - rs.QuantityPack;
                                }
                            } else if (e.rowIndex > 0 && index > 0) {
                                if (e.rowIndex === index) {
                                    rs.QuantityRemain = QuantityRemainTemp - rs.QuantityPack;
                                    min = QuantityRemainTemp - rs.QuantityPack;
                                } else if (e.rowIndex < index + 1) {
                                    rs.QuantityRemain = min - rs.QuantityPack;
                                    min = min - rs.QuantityPack;
                                }
                            }
                        }
                        return rs;
                    });
                    let dataNotNew = dataTotal.filter(rs => rs.isNew === undefined);
                    if (dataNotNew.length > 0) {
                        let keyS = dataNotNew[dataNotNew.length - 1].DeliveryNo;
                        keyS = keyS.substring(keyS.lastIndexOf('.') + 1);
                        this.keyS = keyS;
                    } else {
                        this.keyS = 0;
                    }
                    dataFilter.map(rs => {
                        if (rs.isNew) {
                            this.keyS++;
                            let key = this.keyS < 10 ? '.D.000' + this.keyS : this.keyS > 10 && this.keyS < 100 ? '.D.00' + this.keyS : this.keyS > 100 && this.keyS < 1000 ? '.D.0' + this.keyS : this.keyS;
                            rs.DeliveryNo = rs.MappingNo + key
                        }
                        return rs;
                    })
                }

                this.setState({ popOver: null, currentPosition: null });
                this.props.refW05F0007.setIsUpdate();
            },
            () => {
                //no
            }
        );
    }
    onEditRow = (e) => {
        this.setState({ isEditing: true, popOver: null, currentPosition: null }, () => {
            setTimeout(() => {
                if (e) e.component.editRow(e.rowIndex);
            }, 300);
        });
    };
    onRowClick = (e) => {
        this.selectedRowIndx = e.rowIndex;
        this.rowData = e.data;
        this.selectedRowIndxPacking = e.rowIndex;
    }
    // popupOpen = () => {
    //     const { currentPosition, popOver, isEditing } = this.state;
    //     const { classes } = this.props;
    //     return (
    //         <>
    //             {!isEditing && popOver !== null && <PopoverGrid
    //                 id={currentPosition}
    //                 anchorEl={popOver}
    //                 className={classes.MuiPopover}
    //                 onClose={() => this.handleClose("popOver")}
    //                 itemProps={
    //                     <div style={{ padding: 5 }}>
    //                         <Tooltip title={Config.lang("ERP_Sua")}>
    //                             <IconButton
    //                                 aria-label="edit"
    //                                 style={{ height: 30, width: 30, marginRight: 10 }}
    //                                 onClick={() => this.onEditRow(this.component)}
    //                                 disabled={false}
    //                             >
    //                                 <EditIcon />
    //                             </IconButton>
    //                         </Tooltip>
    //                         <Tooltip title={Config.lang("ERP_Xoa")}>
    //                             <IconButton
    //                                 aria-label="edit"
    //                                 style={{ height: 30, width: 30 }}
    //                                 onClick={this.onDelete}
    //                                 disabled={false}
    //                             >
    //                                 <DeleteIcon />
    //                             </IconButton>
    //                         </Tooltip>
    //                     </div>
    //                 }
    //             />}
    //         </>
    //     )
    // }
    // handleClick = (event, data, stateName, e) => {
    //     this.component = e;
    //     this.setState({ [stateName]: event.currentTarget, currentPosition: data && data });
    // };
    // handleClose = (stateName) => {
    //     this.component = null;
    //     this.setState({ [stateName]: null, currentPosition: null });
    // };
    // renderButtonAction = (e, mode) => {
    //     const { classes, } = this.props;
    //     const { data } = e.row;
    //     return (
    //         <>
    //             {!e.row.isEditing ?
    //                 <IconButton
    //                     aria-describedby={mode === 'PackItemID' ? data.PackItemID : data.DeliveryItemID}
    //                     aria-label="view"
    //                     className={classes.divIconItem}
    //                     onClick={(element) => this.handleClick(element, data[mode], "popOver", e)}
    //                     disabled={this.state.popOver !== null}
    //                 >
    //                     <MoreVertIcon />
    //                 </IconButton>
    //                 : <>
    //                     <IconButton aria-label="view" className={"mgr5"}
    //                         size={'small'}
    //                         onClick={async () => {
    //                             await e.component.saveEditData();
    //                             this.setState({ isEditing: false });
    //                         }}>
    //                         <SaveIcon fontSize="small" />
    //                     </IconButton>
    //                     <IconButton aria-label="view" className={"mgr5"}
    //                         size={'small'}
    //                         onClick={async () => {
    //                             await e.component.cancelEditData();
    //                             if (e.row.isNewRow) {
    //                                 if (this.state.indexTab === 0) {
    //                                     this.keyP--;
    //                                 } else {
    //                                     this.keyS--;
    //                                 }
    //                             }
    //                             this.setState({ isEditing: false });
    //                         }}>
    //                         <UndoIcon fontSize="small" />
    //                     </IconButton>
    //                 </>}
    //         </>
    //     );
    // };
    onAddRow = (e) => {
        const { isOpenContract } = this.props;
        if (isOpenContract) {
            this.props.refW05F0007.hideMapping(() => {
                this.setState({ isEditing: true, allowEditDelivery: true, editMode: 'row' }, () => {
                    setTimeout(() => {
                        e.instance.addRow();
                    }, 300);
                });

            });
        } else {
            this.setState({ isEditing: true, allowEditDelivery: true, editMode: 'row' }, () => {
                setTimeout(() => {
                    e.instance.addRow();
                }, 300);
            });
        }
        this.props.refW05F0007.setDisabledAllForSave(true);

    }
    getDataFlName = (dataField, rowData) => {
        let rs = null;
        switch (dataField) {
            case "UnitPackID":
                rs = rowData.UnitPackName;
                break;
            case "WarehousePacking":
                rs = rowData.WarehousePackingName;
                break;
            case "DeliveryID":
                rs = rowData.DeliveryName;
                break;
            default:
                break;
        }
        return rs;
    }
    cellRenderPopupAction = (cellData) => {
        const dataField = cellData.column.dataField,
            rowData = cellData.data;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {rowData.DeliveryID === undefined &&
                    <div className={"pull-left mgr5"}>
                        <IconButton size={'small'} style={{ display: `${this.props.isView ? 'none' : 'block'}`, padding: 4 }}
                            onClick={() => this.showPopup(rowData[dataField], dataField, cellData)}>
                            <ReorderIcon fontSize={"small"} />
                        </IconButton>
                    </div>
                }
                <div className={"pull-left textCellHidden"} title={this.getDataFlName(dataField, rowData)}>{this.getDataFlName(dataField, rowData)}</div>
            </div>
        );
    };
    showPopup = (data, dataField, e) => {
        this.selectedRowIndx = e.rowIndex;
        this.rowData = e.data;
        const value = data ? data.replace(/,/g, ';').split(';') : [];
        switch (dataField) {
            case "UnitPackID":
                this.popupUnitPack.tmpSelectedRowKeys = value;
                setTimeout(() => {
                    this.popupUnitPack && this.popupUnitPack.open();
                }, 100);
                break;
            case "WarehousePacking":
                this.popupWareHouse.tmpSelectedRowKeys = value;
                setTimeout(() => {
                    this.popupWareHouse && this.popupWareHouse.open();
                }, 100);
                break;
            case "DeliveryID":
                this.popupDelivery.tmpSelectedRowKeys = value;
                setTimeout(() => {
                    this.popupDelivery && this.popupDelivery.open();
                }, 100);
                break;
            default:
                break;
        }

    };
    onSelectedPopup = async (data, field) => {
        switch (field) {
            case "UnitPackID":
                this.props.filterUnitPack.limit = 15;
                this.props.filterUnitPack.skip = 0;
                this.gridTabP.instance.cellValue(this.selectedRowIndx, 'UnitPackName', data[0].UnitPackName);
                this.gridTabP.instance.cellValue(this.selectedRowIndx, field, data[0].UnitPackID);
                this.gridTabP.instance.cellValue(this.selectedRowIndx, 'IsUpdated', 1);
                if (this.state.editMode === 'cell') {
                    this.gridTabP.instance.saveEditData();
                }
                break;
            case "WarehousePacking":
                this.props.filterWareHousePacking.limit = 15;
                this.props.filterWareHousePacking.skip = 0;
                this.gridTabP.instance.cellValue(this.selectedRowIndx, 'WarehousePackingName', data[0].WarehousePackingName);
                this.gridTabP.instance.cellValue(this.selectedRowIndx, field, data[0].WarehousePacking);
                this.gridTabP.instance.cellValue(this.selectedRowIndx, 'IsUpdated', 1);
                if (this.state.editMode === 'cell') {
                    this.gridTabP.instance.saveEditData();
                }
                break;
            case "DeliveryID":
                this.props.filterCbDelivery.limit = 15;
                this.props.filterCbDelivery.skip = 0;
                this.gridTabS.instance.cellValue(this.selectedRowIndx, field, data[0].VesselID);
                this.gridTabS.instance.cellValue(this.selectedRowIndx, "DeliveryName", data[0].VesselName);
                this.gridTabS.instance.cellValue(this.selectedRowIndx, "Quantity", data[0].GrossWeight);
                this.gridTabS.instance.cellValue(this.selectedRowIndx, 'IsUpdated', 1);
                // this.resetDataMapping();
                let datafilter = {
                    DeliveryID: data[0].VesselID
                }
                this.setQuantityRemain(datafilter, (QuantityRemain) => {
                    if (QuantityRemain === false) {
                        this.gridTabS.instance.cellValue(this.selectedRowIndx, "QuantityRemain", data[0].GrossWeight - this.rowData.QuantityPack);
                        // e.data.QuantityRemain = e.data.Quantity - (e.data.QuantityPack !== undefined ? e.data.QuantityPack : 0);
                    } else {
                        this.gridTabS.instance.cellValue(this.selectedRowIndx, "QuantityRemain", QuantityRemain - this.rowData.QuantityPack);
                        // e.data.QuantityRemain = QuantityRemain - (e.data.QuantityPack !== undefined ? e.data.QuantityPack : 0);
                    }
                });
                if (this.state.editMode === "cell") {
                    this.gridTabS.instance.saveEditData();
                }
                // this.gridTabS.instance.saveEditData();
                break;
            default:
                break;
        }
        // if (this.state.editMode === "cell") {
        //     this.setState({ flag: false });
        // }

    };

    setQuantityRemain = (data, cb) => {
        const { dataGridS } = this.props;
        let array = [];
        dataGridS.forEach(rs => {
            if (rs.QuantityRemain !== undefined && data.DeliveryID === rs.DeliveryID) {
                array.push(rs.QuantityRemain);
            }
        });
        if (array.length > 0) {
            const QuantityRemain = Math.min.apply(Math, array);
            if (cb) cb(QuantityRemain)
        } else {
            if (cb) cb(false)
        }

    }
    resetDataMapping = () => {
        const { dataGridS, dataGridMappingInfo, rowDataInfoMapping } = this.props;
        const QuantityActual = this.props.refW05F0007.sumData(dataGridS, "QuantityPack",);
        dataGridMappingInfo.map(item => {
            if (item.QuantityActual !== QuantityActual && rowDataInfoMapping.MappingItemID === item.MappingItemID) {
                item.QuantityActual = QuantityActual;
                item.QuantityRemain = rowDataInfoMapping.Quantity - QuantityActual;
            }
            return item;
        });
        this.props.refW05F0007.setDataTemp();
    }
    dataFieldName = (fieldName) => {
        let rs = '';
        switch (fieldName) {
            case "UnitPackID":
                rs = "UnitPackName";
                break;
            case "WarehousePacking":
                rs = "WarehousePackingName";
                break;
            case 'Status':
                rs = "StatusName";
                break;
            case "MarkingID":
                rs = "MarkingName";
                break;
            default:
                break;
        }
        return rs;
    }
    renderHeaderColumns = (e) => {
        if (!e) return false;
        // return <div style={{color: col.HeaderTextColor}}>{col.Caption}</div>
        return <HeadClick selectedRange={this.state.selectedRange}
            style={{ whiteSpace: 'normal' }}
            dataHeaderCell={e}
            allowClick={true}
            dataFieldName={this.dataFieldName}
            callbackAfterCopy={rs => {
                const { column } = e
                const dataField = column.dataField;
                const startRowIndx = this.state.selectedRange.startRowIndex;
                const getDataSource = e.component.getDataSource();
                const dataSource = e.component.option("dataSource");
                switch (dataField) {
                    case "UnitPackID":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            let dataFilter = dataSource.find(c => c.UnitPackID === data.UnitPackID)
                            data["UnitPackName"] = dataFilter.UnitPackName;
                        }
                        getDataSource.reload();
                        break;
                    case "WarehousePacking":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            let dataFilter = dataSource.find(c => c.WarehousePacking === data.WarehousePacking)
                            data["WarehousePackingName"] = dataFilter.WarehousePackingName;
                        }
                        getDataSource.reload();
                        break;
                    default:
                        break;
                }
                this.setState({ isUpdated: rs.IsUpdated });
                this.props.refW05F0007.setIsUpdate();
            }}
        />
    };
    onDeleteAll = (mode) => {
        if (mode === 0) {
            if (this.gridTabP.instance.option("dataSource").length > 0) {
                Config.popup.show(
                    "YES_NO",
                    Config.lang("ERP_Ban_co_chac_muon_xoa?"),
                    () => {
                        // this.gridTabP.instance.option("dataSource", []);
                        this.props.dataGridP.length = 0;
                        this.gridTabP.instance.refresh();
                        this.props.refW05F0007.setIsUpdate();
                    },
                    () => {
                        //no
                    }
                );
            }
        } else {
            if (this.gridTabS.instance.option("dataSource").length > 0) {
                Config.popup.show(
                    "YES_NO",
                    Config.lang("ERP_Ban_co_chac_muon_xoa?"),
                    () => {
                        // this.gridTabP.instance.option("dataSource", []);
                        this.props.dataGridS.length = 0;
                        this.gridTabS.instance.refresh();
                        this.resetDataMapping();
                        this.props.refW05F0007.setIsUpdate();
                    },
                    () => {
                        //no
                    }
                );
            }
        }
    }

    onCopy = (e) => {
        const { rowDataInfoMapping, cbDeliveryStatus, } = this.props;
        let Status = cbDeliveryStatus.find(rs => rs.Status === "1");
        this.keyS++;
        const key = this.keyS < 10 ? '.D.000' + this.keyS : this.keyS > 10 && this.keyS < 100 ? '.D.00' + this.keyS : this.keyS > 100 && this.keyS < 1000 ? '.D.0' + this.keyS : this.keyS;
        let obj = {};
        obj.DeliveryID = e.data.DeliveryID;
        obj.DeliveryName = e.data.DeliveryName;
        obj.Quantity = e.data.Quantity;
        obj.DeliveryNo = rowDataInfoMapping && rowDataInfoMapping.MappingNo ? rowDataInfoMapping.MappingNo + key : '';
        obj.MappingItemID = rowDataInfoMapping && rowDataInfoMapping.MappingItemID;
        obj.QuantityPack = 0;
        obj.Status = 1;
        obj.StatusName = Status?.StatusName ?? '';
        obj.MarkingName = '';
        obj.isNew = true;
        obj.MappingNo = rowDataInfoMapping?.MappingNo ?? '';
        obj.Date02 = new Date();
        this.setQuantityRemain(obj, (QuantityRemain) => {
            if (QuantityRemain === false) {
                obj.QuantityRemain = obj.Quantity;
            } else {
                obj.QuantityRemain = QuantityRemain;
            }
        });
        this.gridTabS.instance.getDataSource().store().insert(obj).done(() => this.gridTabS.instance.refresh()); //copy rowData
    }

    renderButtonAction = (e, mode) => {
        const { editMode } = this.state;
        if (e.row?.data?.IsConfirmed === 1) return;
        return (
            <GridActionBar >
                <div className="display_row align-center">
                    {editMode !== "row" ?
                        <>
                            <Tooltip title={Config.lang('Xoa')}>
                                <ButtonIcon
                                    circular
                                    size="small"
                                    viewType="text"
                                    name="delete"
                                    onClick={() => this.onDelete(e)}
                                />
                            </Tooltip>
                            {mode === 'DeliveryItemID' &&
                                <Tooltip title={Config.lang('Sao_chep')}>
                                    <ButtonIcon
                                        circular
                                        size="small"
                                        viewType="text"
                                        name="copy"
                                        onClick={() => this.onCopy(e)}
                                    />
                                </Tooltip>
                            }
                        </>
                        :
                        e.row.isNewRow &&
                        <>
                            <Tooltip title={Config.lang('Luu')}>
                                <ButtonIcon
                                    circular
                                    size="small"
                                    viewType="text"
                                    name="save"
                                    onClick={async () => {
                                        await e.component.saveEditData();
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title={Config.lang('Xoa')}>
                                <ButtonIcon
                                    circular
                                    size="small"
                                    viewType="text"
                                    name="delete"
                                    onClick={() => {
                                        this.setState({ isEditing: false, allowEditDelivery: false, editMode: 'cell' });
                                        this.props.refW05F0007.setDisabledAllForSave();
                                    }}
                                />
                            </Tooltip>
                        </>
                    }
                </div>
            </GridActionBar>
        )
    };



    render() {
        const {
            isView, dataGridP, dataGridS, rowDataInfoMapping, cbDeliveryStatus, cbMarking, decimals, classes,
            refW05F0007, isSwapMapping, disabledAllForSave, cellRenderNumber
        } = this.props;
        const { indexTab, editMode, flexCaptionP, flexCaptionS, btnDeleteAllDelivery } = this.state;
        const btnDeleteAllDeliveryStatus = indexTab === 1 ? btnDeleteAllDelivery : false;
        const columnUnitPacks = [
            {
                caption: Config.lang("ERP_Ma"),
                dataField: "UnitPackID",
                width: 200
            },
            {
                caption: Config.lang("ERP_Ten"),
                dataField: "UnitPackName"
            }
        ];
        const columnWareHouse = [
            {
                caption: Config.lang("ERP_Ma"),
                dataField: "WarehousePacking",
                width: 200
            },
            {
                caption: Config.lang("ERP_Ten"),
                dataField: "WarehousePackingName"
            }
        ];
        const columnDelivery = [
            {
                caption: Config.lang("ERP_Ma"),
                dataField: "VesselID",
                width: 200
            },
            {
                caption: Config.lang("ERP_Ten"),
                dataField: "VesselName"
            },
            {
                caption: Config.lang("ERP_Tai_trong"),
                dataField: "GrossWeight",
            }
        ];
        return (
            <div>
                <CbLoadmore
                    ref={ref => this.popupUnitPack = ref}
                    mode={'single'}
                    keyExpr={"UnitPackID"}
                    title={Config.lang("ERP_Don_vi_det_bao")}
                    onClose={() => {
                        this.props.filterUnitPack.limit = 15;
                        this.props.filterUnitPack.skip = 0;
                    }}
                    filter={this.props.filterUnitPack}
                    column={columnUnitPacks}
                    onSave={(data) => (this.onSelectedPopup(data, "UnitPackID"))}
                    listPerPage={[15, 30, 45, 60]}
                />
                <CbLoadmore
                    ref={ref => this.popupWareHouse = ref}
                    mode={"single"}
                    keyExpr={"WarehousePacking"}
                    title={Config.lang("ERP_Kho_dong_hang")}
                    onClose={() => {
                        this.props.filterWareHousePacking.limit = 15;
                        this.props.filterWareHousePacking.skip = 0;
                    }}
                    filter={this.props.filterWareHousePacking}
                    column={columnWareHouse}
                    onSave={(data) => (this.onSelectedPopup(data, "WarehousePacking"))}
                    listPerPage={[15, 30, 45, 60]}
                />
                <CbLoadmore
                    ref={ref => this.popupDelivery = ref}
                    mode={"single"}
                    keyExpr={"VesselID"}
                    title={Config.lang("ERP_Phuong_tien_van_chuyen")}
                    onClose={() => {
                        this.props.filterCbDelivery.limit = 15;
                        this.props.filterCbDelivery.skip = 0;
                    }}
                    filter={this.props.filterCbDelivery}
                    column={columnDelivery}
                    onSave={(data) => (this.onSelectedPopup(data, "DeliveryID"))}
                    listPerPage={[15, 30, 45, 60]}
                />

                <div className={"display_row align-center flex-wrap align-between"}>
                    <Tabs
                        indicatorColor={"primary"}
                        textColor={"primary"}
                        value={indexTab}
                        variant={"scrollable"}
                        scrollButtons={Config.isMobile ? "on" : "off"}
                        onChange={this.handleChanged}
                    >
                        <Tab
                            value={0}
                            label={Config.lang("ERP_Chi_tiet_bao_bi")}
                            onClick={() => { }}
                        />
                        <Tab
                            value={1}
                            label={Config.lang("ERP_Chi_tiet_giao_hang")}
                            onClick={() => { }}
                        />
                    </Tabs>
                    {!isView && !disabledAllForSave &&
                        <div style={{ paddingTop: 15 }}>
                            <Tooltip title={Config.lang('Xoa')}>
                                <ButtonIcon
                                    circular
                                    size="small"
                                    viewType="text"
                                    name="delete"
                                    onClick={() => this.onDeleteAll(indexTab)}
                                    disabled={isSwapMapping || btnDeleteAllDeliveryStatus}
                                />
                            </Tooltip>
                        </div>
                    }
                </div>
                <TabContent
                    style={{ paddingBottom: 15, backgroundColor: '#fff' }}
                    activeKey={indexTab}
                    disableSwipe={true}
                    lazyLoading={true}
                >
                    <TabPanel index={0}>
                        <React.Fragment>
                            <GridContainer
                                reference={ref => this.gridTabP = ref}
                                dataSource={dataGridP}
                                columnAutoWidth={true}
                                elementAttr={{
                                    class: `noBGSelect`
                                }}
                                disabled={isSwapMapping}
                                listPerPage={[5, 10, 15, 30, 45, 60]}
                                itemPerPage={5}
                                typePaging={"normal"}
                                pagerFullScreen={false}
                                keyExpr={"PackItemID"}
                                height={300}
                                typeShort={true}
                                showColumnLines={false}
                                showRowLines={false}
                                onRowClick={this.onRowClick}
                                onCellClick={(e) => {
                                    if (e.rowIndex !== undefined) {
                                        setTimeout(() => {
                                            this.selectedRowIndxPacking = e.rowIndex;
                                        }, 500)
                                    }
                                }}
                                onKeyDown={(e) => {
                                    Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.gridTabP,
                                        (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                                        , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                                    if (e.event.keyCode === 13) {
                                        const { startRowIndex } = this.selectedRange;
                                        const dataSource = this.gridTabP.instance.option("dataSource");
                                        const rowIndex = startRowIndex === 0 ? dataSource.length - 1 : startRowIndex - 1;
                                        this.selectedRowIndxPacking = rowIndex;
                                    }
                                    if (e.event.keyCode === 27 && disabledAllForSave) {
                                        setTimeout(() => {
                                            this.props.refW05F0007.setDisabledAllForSave();
                                            this.setState({ isEditing: false, allowEditDelivery: false, editMode: 'cell' });
                                        }, 300);
                                    }
                                }}
                                allowCellSelection={true}
                                hoverStateEnabled={true}
                                sorting={{
                                    mode: "none"
                                }}
                                editing={{
                                    mode: editMode,
                                    refreshMode: "reshape",
                                    allowUpdating: editMode === 'cell' ? !isView : false,
                                    texts: {
                                        confirmDeleteMessage: ""
                                    },
                                    // startEditAction: "dblClick"
                                }}
                                onInitNewRow={(e) => {
                                    this.keyP++;
                                    const key = this.keyP < 10 ? '.P.000' + this.keyP : this.keyP >= 10 && this.keyP < 100 ? '.P.00' + this.keyP : this.keyP >= 100 && this.keyP < 1000 ? '.P.0' + this.keyP : this.keyP;
                                    e.data.PackNo = rowDataInfoMapping && rowDataInfoMapping.MappingNo ? rowDataInfoMapping.MappingNo + key : '';
                                    e.data.ContractNo = rowDataInfoMapping && rowDataInfoMapping.ContractNo ? rowDataInfoMapping.ContractNo : '';
                                    e.data.LotNo = rowDataInfoMapping && rowDataInfoMapping.LotNo ? rowDataInfoMapping.LotNo : '';
                                    e.data.UnitPackID = rowDataInfoMapping && rowDataInfoMapping.UnitPackID ? rowDataInfoMapping.UnitPackID : '';
                                    e.data.UnitPackName = rowDataInfoMapping && rowDataInfoMapping.UnitPackName ? rowDataInfoMapping.UnitPackName : '';
                                    e.data.MappingItemID = rowDataInfoMapping && rowDataInfoMapping.MappingItemID ? rowDataInfoMapping.MappingItemID : '';
                                    e.data.isNew = true;
                                    e.data.MappingNo = rowDataInfoMapping && rowDataInfoMapping.MappingNo ? rowDataInfoMapping.MappingNo : '';
                                    e.data.Num01 = 0;
                                    e.data.Num02 = dataGridP.length <= 0 ? rowDataInfoMapping.QuantityPack - e.data.Num01 : dataGridP[dataGridP.length - 1].Num02 - e.data.Num01;

                                }}
                                onRowUpdating={async (e) => {
                                    if (e.newData.Num01) {
                                        await this.props.refW05F0007.setDataGridPacking(e, this.selectedRowIndxPacking, (isCancel) => {
                                            if (isCancel) {
                                                e.cancel = true;
                                                Config.popup.show('INFO', Config.lang("ERP_So_luong_da_giao_vua_nhap_khong_hop_le"));
                                            }
                                        })

                                    }
                                }}
                                onRowUpdated={(e) => {
                                    e.data.IsUpdated = 1;
                                    this.setState({ isUpdated: true, isEditing: false, editMode: 'cell' });
                                    this.props.refW05F0007.setIsUpdate();
                                    const { startRowIndex, startColumnIndex } = this.selectedRange;
                                    let dataField = ['PackNo', 'ContractNo', 'LotNo', 'WarehousePacking', 'UnitPackID', 'VesselID', 'DeliveryNo', 'DeliveryID', 'Num02'];
                                    let currentFieldName = this.gridTabP && this.gridTabP.instance.getVisibleColumns()[startColumnIndex]?.dataField;
                                    if (!dataField.includes(currentFieldName)) {
                                        setTimeout(() => {
                                            this.gridTabP && this.gridTabP.instance.editCell(startRowIndex, startColumnIndex);
                                        }, 100);
                                    }
                                }}
                                onRowInserting={(e) => {
                                    if (e.data.Num01) {
                                        const Num02 = dataGridP.length <= 0 ? rowDataInfoMapping.QuantityPack - e.data.Num01 : dataGridP[dataGridP.length - 1].Num02 - e.data.Num01;
                                        if (Num02 < 0) {
                                            e.cancel = true;
                                            Config.popup.show('INFO', Config.lang("ERP_So_luong_da_giao_vua_nhap_khong_hop_le"));
                                        } else {
                                            e.data.Num02 = Num02;
                                        }
                                    }
                                }}
                                onRowInserted={(e) => {
                                    if (this.state.isEditing === true) this.setState({ isEditing: false, editMode: 'cell' });
                                    this.props.refW05F0007.setIsUpdate();
                                    this.props.refW05F0007.setDisabledAllForSave();
                                }}
                                onCellSelectionChanged={(e) => {
                                    let dataFieldPopup = ['WarehousePacking', 'UnitPackID'];
                                    let currentFieldNamePopup = e.component.getVisibleColumns()[e.selectedRange.startColumnIndex]?.dataField;
                                    if (e.component.hasEditData() && this.state.editMode === 'cell' && !dataFieldPopup.includes(currentFieldNamePopup)) {
                                        this.gridTabP.instance.saveEditData();
                                    }
                                    if (e.selectedRange && e.selectedRange.rowType !== "header" && e.selectedRange.rowType !== '' && !this.state.isEditing && !isView) {
                                        if (JSON.stringify(this.state.selectedRange) !== JSON.stringify(e.selectedRange)) {
                                            this.selectedRange = e.selectedRange;
                                            this.setState({ selectedRange: e.selectedRange }, () => {
                                                let dataField = ['PackNo', 'ContractNo', 'LotNo', 'WarehousePacking', 'UnitPackID', 'VesselID', 'DeliveryNo', 'DeliveryID', 'Num02'];
                                                let currentFieldName = e.component.getVisibleColumns()[e.selectedRange.startColumnIndex]?.dataField;
                                                if (!dataField.includes(currentFieldName)) {
                                                    setTimeout(() => {
                                                        this.gridTabP && this.gridTabP.instance.editCell(e.selectedRange.startRowIndex, e.selectedRange.startColumnIndex)
                                                    }, 100);
                                                }
                                            });
                                        }
                                    }
                                }}

                            >
                                <Column dataField={'MappingItemID'} visible={false} />

                                <Column
                                    caption={Config.lang("ERP_So_chi_tiet_bao_bi")}
                                    dataField={'PackNo'}
                                    allowEditing={false}
                                    minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_So_hop_dong_mua")}
                                    dataField={"ContractNo"}
                                    allowEditing={false}
                                    minWidth={150}
                                />
                                <Column
                                    caption={Config.lang("ERP_Ma_lot")}
                                    dataField={"LotNo"}
                                    allowEditing={false}
                                    minWidth={150}
                                />
                                <Column
                                    dataField={"UnitPackName"}
                                    width={10}
                                    visible={false}
                                />
                                <Column
                                    caption={Config.lang("ERP_Don_vi_det_bao")}
                                    dataField={"UnitPackID"}
                                    allowEditing={false} minWidth={150}
                                    cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                    headerCellRender={this.renderHeaderColumns}
                                />
                                <Column
                                    dataField={"WarehousePackingName"} width={10} visible={false}
                                />
                                <Column
                                    caption={Config.lang("ERP_Kho_dong_hang")} dataField={'WarehousePacking'} allowEditing={false} minWidth={150}
                                    cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                    headerCellRender={this.renderHeaderColumns}
                                />
                                {flexCaptionP.map((item, index) => (
                                    <Column key={index}
                                        dataType={item.DataType === 'D' ? 'date' : item.DataType === 'S' ? 'string' : 'number'}
                                        format={item.DataType === 'D' ? 'dd/MM/yyyy' : ''}
                                        caption={Config.language === '84' ? item.Caption84 : item.Caption01} dataField={item.FieldName} minWidth={220}
                                        headerCellRender={this.renderHeaderColumns}
                                        allowEditing={!isView ? Number(item.ReadOnly) === 0 : false}
                                        cellRender={(e) => item.DataType === 'N' ? cellRenderNumber(e.displayValue) :
                                            item.DataType === 'D' ? e.value ? moment(e.value).format('DD/MM/YYYY') : '' : e.value}
                                        {...(item.DataType === 'D' ? {
                                            editorOptions: {
                                                placeholder: "DD/MM/YYYY",
                                                dateSerializationFormat: 'yyyy-MM-dd'
                                            }
                                        } : {
                                            editCellRender: e => (
                                                item.DataType === 'N' ?
                                                    <MoneyInput
                                                        thousandSeparator={','}
                                                        value={e.value}
                                                        className={classes.numberFormatInput}
                                                        nonStyle
                                                        decimalDigit={5}
                                                        allowZero
                                                        onChange={(vl) => e.setValue(Number(vl.value))}
                                                    />
                                                    :
                                                    <TextBox defaultValue={e.value}
                                                        onValueChanged={vl => e.setValue(vl.value)} />
                                            )
                                        })}
                                    />
                                ))}
                                <Column
                                    alignment={"center"}
                                    fixed={true}
                                    cssClass={classes.columnBtnAction}
                                    fixedPosition={"right"}
                                    minWidth={100}
                                    allowEditing={false}
                                    cellRender={(e) => this.renderButtonAction(e, "PackItemID")}
                                    visible={!isView || Config.isMobile}
                                />
                            </GridContainer>

                            {!isView &&
                                <Tooltip title={Config.lang('Them')}>
                                    <IconButton
                                        aria-label="add"
                                        disabled={this.state.isEditing || isSwapMapping}
                                        className="pdt20"
                                        onClick={() => this.onAddRow(this.gridTabP)}
                                        color={"default"}
                                    >
                                        <AddCircleIcon color={"primary"} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </React.Fragment>
                    </TabPanel>
                    <TabPanel index={1}>
                        <React.Fragment>
                            <GridContainer
                                reference={ref => this.gridTabS = ref}
                                dataSource={dataGridS}
                                elementAttr={{
                                    class: `noBGSelect`
                                }}
                                disabled={isSwapMapping}
                                columnAutoWidth={true}
                                listPerPage={[5, 10, 15, 30, 45, 60]}
                                itemPerPage={5}
                                typePaging={"normal"}
                                pagerFullScreen={false}
                                keyExpr={'DeliveryItemID'}
                                height={300}
                                typeShort={true}
                                showColumnLines={false}
                                showRowLines={false}
                                onRowClick={this.onRowClick}
                                onCellClick={(e) => {
                                    if (e.rowIndex !== undefined) {
                                        setTimeout(() => {
                                            this.selectedRowIndx = e.rowIndex;
                                        }, 500)
                                        this.rowData = e.data;
                                    }
                                }}
                                onKeyDown={(e) => {
                                    Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.gridTabS,
                                        (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                                        , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                                    if (e.event.keyCode === 27 && disabledAllForSave) {
                                        setTimeout(() => {
                                            this.props.refW05F0007.setDisabledAllForSave();
                                            this.setState({ isEditing: false, allowEditDelivery: false, editMode: 'cell' });
                                        }, 300);
                                    }
                                }}
                                allowCellSelection={true}
                                hoverStateEnabled={true}
                                sorting={{
                                    mode: "none"
                                }}
                                editing={{
                                    mode: editMode,
                                    refreshMode: "reshape",
                                    allowUpdating: editMode === 'cell' ? !isView : false,
                                    texts: {
                                        confirmDeleteMessage: ""
                                    },
                                    // startEditAction: "dblClick"
                                }}
                                onInitNewRow={(e) => {
                                    let Status = cbDeliveryStatus.find(rs => rs.Status === "1");
                                    this.keyS++;
                                    const key = this.keyS < 10 ? '.D.000' + this.keyS : this.keyS >= 10 && this.keyS < 100 ? '.D.00' + this.keyS : this.keyS >= 100 && this.keyS < 1000 ? '.D.0' + this.keyS : this.keyS;
                                    e.data.DeliveryNo = rowDataInfoMapping && rowDataInfoMapping.MappingNo ? rowDataInfoMapping.MappingNo + key : '';
                                    e.data.MappingItemID = rowDataInfoMapping && rowDataInfoMapping.MappingItemID;
                                    e.data.QuantityPack = 0;
                                    e.data.Status = 1;
                                    e.data.StatusName = Status !== undefined ? Status.StatusName : '';
                                    e.data.MarkingName = '';
                                    e.data.isNew = true;
                                    e.data.MappingNo = rowDataInfoMapping && rowDataInfoMapping.MappingNo ? rowDataInfoMapping.MappingNo : '';
                                    e.data.Date02 = new Date();

                                }}
                                onRowUpdating={async (e) => {
                                    if (e.newData.QuantityPack !== undefined) {
                                        await this.props.refW05F0007.setDataGridShip(e.oldData, e.newData, this.selectedRowIndx, rowDataInfoMapping, async (bool, QuantityPack) => {
                                            if (bool) {
                                                e.newData.QuantityPack = QuantityPack !== undefined ? QuantityPack : e.oldData.QuantityPack;
                                                this.isError = true;
                                                Config.popup.show('INFO', Config.lang("ERP_So_luong_khong_hop_le"));
                                            } else {
                                                e.oldData.QuantityPack = Number(e.newData.QuantityPack)
                                                e.oldData.IsUpdated = 1;
                                                if (this.state.isEditing === true) this.setState({ isEditing: false });
                                            }
                                        });
                                    }
                                }}
                                onRowUpdated={(e) => {
                                    e.data.IsUpdated = 1;
                                    this.setState({ isUpdated: true, isEditing: false });
                                    this.props.refW05F0007.setIsUpdate();
                                    const { startRowIndex, startColumnIndex } = this.selectedRange;
                                    const dataField = ["PackNo", "ContractNo", "LotNo", "WarehousePacking", "UnitPackID", "VesselID", "DeliveryNo", "DeliveryID"];
                                    // dataField = [...new Set(dataField.concat(captionSName))];
                                    let currentFieldName = this.gridTabS && this.gridTabS.instance.getVisibleColumns()[startColumnIndex]?.dataField;
                                    if (!dataField.includes(currentFieldName)) {
                                        setTimeout(() => {
                                            this.gridTabS && this.gridTabS.instance.editCell(startRowIndex, startColumnIndex);
                                        }, 100);
                                    }
                                }}
                                onRowInserting={e => {
                                    if (e.data.DeliveryID !== undefined) {
                                        if (e.data.QuantityPack !== undefined) {
                                            const QuantityActual = this.props.refW05F0007.sumData(dataGridS, "QuantityPack");
                                            if (rowDataInfoMapping.MappingItemID === e.data.MappingItemID) {
                                                if (rowDataInfoMapping.Quantity - (QuantityActual + Number(e.data.QuantityPack)) < 0) {
                                                    e.cancel = true;
                                                    Config.popup.show("INFO", Config.lang("ERP_So_luong_khong_hop_le"));
                                                } else {
                                                    this.setQuantityRemain(e.data, (QuantityRemain) => {
                                                        if (QuantityRemain === false) {
                                                        } else {
                                                            if (QuantityRemain - Number(e.data.QuantityPack) < 0) {
                                                                e.cancel = true;
                                                                Config.popup.show('INFO', Config.lang("ERP_So_luong_khong_hop_le"));
                                                            }
                                                        }

                                                    });
                                                }
                                            }
                                        }
                                    } else {
                                        e.cancel = true;
                                        this.isError = true;
                                        Config.popup.show("INFO", Config.lang("ERP_Phuong_tien_van_chuyen_bat_buoc_nhap"));
                                    }
                                }}
                                onRowInserted={(e) => {
                                    if (e.data.QuantityPack !== undefined) {
                                        this.resetDataMapping();
                                        this.setQuantityRemain(e.data, (QuantityRemain) => {
                                            if (QuantityRemain === false) {
                                                e.data.QuantityRemain = e.data.Quantity - (e.data.QuantityPack !== undefined ? Number(e.data.QuantityPack) : 0);
                                            } else {
                                                e.data.QuantityRemain = QuantityRemain - (e.data.QuantityPack !== undefined ? Number(e.data.QuantityPack) : 0);
                                            }
                                        });
                                    }
                                    if (this.state.isEditing === true) this.setState({ isEditing: false, allowEditDelivery: false, editMode: 'cell' });
                                    this.props.refW05F0007.setIsUpdate();
                                    this.props.refW05F0007.setDisabledAllForSave();
                                }}
                                onCellSelectionChanged={(e) => {
                                    if (e.component.hasEditData() && this.state.editMode === 'cell') {
                                        this.gridTabS.instance.saveEditData();
                                    }
                                    if (e.selectedRange && e.selectedRange.rowType !== "header" && e.selectedRange.rowType !== '' && !this.state.isEditing) {
                                        if (JSON.stringify(this.state.selectedRange) !== JSON.stringify(e.selectedRange)) {
                                            this.setState({ selectedRange: e.selectedRange });
                                            this.selectedRange = e.selectedRange;
                                        }
                                    }
                                }}
                                onCellPrepared={e => {
                                    if (e.row?.data?.IsConfirmed === 1) {
                                        e.cellElement.style.pointerEvents = 'none';
                                        e.cellElement.style.opacity = 0.5;
                                    }
                                }}
                            >
                                <Column dataField={"MappingItemID"} visible={false} />
                                <Column
                                    caption={Config.lang("ERP_So_chi_tiet_giao_hang")}
                                    dataField={"DeliveryNo"}
                                    allowEditing={false}
                                    minWidth={200}
                                />
                                <Column
                                    dataField={"DeliveryName"} width={10} visible={false}
                                />
                                <Column
                                    caption={Config.lang("ERP_Phuong_tien_van_chuyen")}
                                    dataField={"DeliveryID"}
                                    minWidth={200}
                                    allowEditing={false}
                                    cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                />
                                <Column
                                    caption={Config.lang("ERP_Dien_thoai")}
                                    dataField={"PhoneNo"}
                                    minWidth={150}
                                    headerCellRender={this.renderHeaderColumns}
                                />
                                <Column
                                    caption={Config.lang("ERP_Tai_trong_phuong_tien")}
                                    dataField={"Quantity"}
                                    minWidth={200}
                                    allowEditing={false}
                                    dataType={"number"}
                                    format={{
                                        type: 'decimal',
                                        min: 0
                                    }}
                                    editorOptions={{
                                        min: 0,
                                        showClearButton: true,
                                        format: {
                                            type: "fixedPoint",
                                            precision: decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5,
                                        },
                                    }}
                                    cellRender={refW05F0007.renderNumberFormat}
                                />
                                <Column
                                    caption={Config.lang("ERP_So_luong_phuong_tien_da_dong_goi")}
                                    dataField={"QuantityPack"}
                                    width={150}
                                    dataType={"number"}
                                    cellRender={refW05F0007.renderNumberFormat}
                                    cssClass={`${classes.removeBorder} ${classes.wordWrap}`}
                                    editCellRender={e => {
                                        setTimeout(() => {
                                            e.component.focus(e.cellElement)
                                        }, 100);
                                        return (
                                            <NumberFormat
                                                className={classes.numberInput}
                                                customInput={TextField}
                                                thousandSeparator={true}
                                                style={{ marginTop: -8, marginBottom: -8, backgroundColor: 'transparent' }}
                                                autoFocus={true}
                                                decimalScale={decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5}
                                                value={String(e.value) || ""}
                                                onValueChange={rs => e.setValue(rs.value)}
                                            />
                                        )
                                    }}

                                />
                                <Column
                                    caption={Config.lang("ERP_So_luong_con_lai")}
                                    dataField={"QuantityRemain"}
                                    minWidth={150}
                                    allowEditing={false}
                                    dataType={"number"}
                                    format={{
                                        type: "decimal",
                                        min: 0
                                    }}
                                    editorOptions={{
                                        min: 0,
                                        showClearButton: true,
                                        format: {
                                            type: "fixedPoint",
                                            precision: decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5,
                                        },
                                    }}
                                    cellRender={refW05F0007.renderNumberFormat}
                                />
                                <Column dataField={"Status"} width={50} visible={false} />
                                <Column
                                    caption={Config.lang("ERP_Trang_thai_phuong_tien")}
                                    dataType={"string"}
                                    dataField={"StatusName"}
                                    minWidth={200}
                                    // lookup={{
                                    //     dataSource: cbDeliveryStatus,
                                    //     valueExpr: "Status",
                                    //     displayExpr: "StatusName",
                                    // }}
                                    calculateCellValue={e => {
                                        return String(e.StatusName)
                                    }}
                                    editCellRender={(e) => {
                                        const selectedItem = cbDeliveryStatus.filter((c) => {
                                            return c.StatusName === e.value;
                                        });
                                        return (
                                            <Combo
                                                dataSource={cbDeliveryStatus}
                                                displayExpr={"StatusName"}
                                                stylingMode={"underlined"}
                                                margin={"dense"}
                                                cssClass={classes.dateBoxGrid}
                                                className={"no-margin"}
                                                style={{ backgroundColor: 'transparent' }}
                                                shrink={true}
                                                placeholder={Config.lang("ERP_Chon")}
                                                value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                onValueChanged={(rs) => {
                                                    e.setValue(rs.value && rs.value.StatusName ? rs.value.StatusName : "");
                                                    e.component.cellValue(e.rowIndex, "Status", rs.value && rs.value.Status ? rs.value.Status : "");
                                                }}
                                            />
                                        );
                                    }}
                                    headerCellRender={this.renderHeaderColumns}
                                />
                                <Column dataField={"MarkingID"} width={50} visible={false} />
                                <Column
                                    caption={Config.lang("ERP_Nhan_mac")}
                                    dataField={"MarkingName"}
                                    minWidth={150}
                                    // lookup={{
                                    //     dataSource: cbMarking,
                                    //     valueExpr: "MarkingID",
                                    //     displayExpr: "MarkingName"
                                    // }}
                                    editCellRender={(e) => {
                                        const selectedItem = cbMarking.filter((c) => {
                                            return c.MarkingName === e.value;
                                        });
                                        return (
                                            <Combo
                                                dataSource={cbMarking}
                                                displayExpr={"MarkingName"}
                                                stylingMode={"underlined"}
                                                margin={"dense"}
                                                cssClass={classes.dateBoxGrid}
                                                className={"no-margin"}
                                                style={{ backgroundColor: "transparent" }}
                                                shrink={true}
                                                placeholder={Config.lang("ERP_Chon")}
                                                value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                onValueChanged={(rs) => {
                                                    e.setValue(rs.value && rs.value.MarkingName ? rs.value.MarkingName : "");
                                                    e.component.cellValue(e.rowIndex, "MarkingID", rs.value && rs.value.MarkingID ? rs.value.MarkingID : "");
                                                }}
                                            />
                                        );
                                    }}
                                    headerCellRender={this.renderHeaderColumns}
                                />
                                {flexCaptionS.map((item, index) => (
                                    <Column key={index}
                                        dataType={item.DataType === 'D' ? 'date' : item.DataType === 'S' ? 'string' : 'number'}
                                        format={item.DataType === 'D' ? 'dd/MM/yyyy' : ''}
                                        // editorOptions={{
                                        //     placeholder: item.DataType === 'D' ? "DD/MM/YYYY" : '',
                                        //     allowUpdating: !isView,
                                        // }}
                                        caption={Config.language === '84' ? item.Caption84 : item.Caption01} dataField={item.FieldName} minWidth={150}
                                        headerCellRender={this.renderHeaderColumns}
                                        allowEditing={!isView ? Number(item.ReadOnly) === 0 : false}
                                        cellRender={(e) => item.DataType === 'N' ? cellRenderNumber(e.displayValue) :
                                            item.DataType === 'D' ? e.value ? moment(e.value).format('DD/MM/YYYY') : '' : e.value}
                                        {...(item.DataType === 'D' ? {
                                            editorOptions: {
                                                placeholder: "DD/MM/YYYY",
                                                dateSerializationFormat: 'yyyy-MM-dd'
                                            }
                                        } : {
                                            editCellRender: e => (
                                                item.DataType === 'N' ?
                                                    <MoneyInput
                                                        thousandSeparator={','}
                                                        value={e.value}
                                                        className={classes.numberFormatInput}
                                                        nonStyle
                                                        decimalDigit={5}
                                                        allowZero
                                                        onChange={(vl) => e.setValue(Number(vl.value))}
                                                    />
                                                    :
                                                    <TextBox defaultValue={e.value}
                                                        onValueChanged={vl => e.setValue(vl.value)} />
                                            )
                                        })}
                                    />
                                ))}
                                <Column
                                    alignment={"center"}
                                    fixedPosition={"right"}
                                    minWidth={100}
                                    cssClass={classes.columnBtnAction}
                                    visible={!isView || Config.isMobile}
                                    fixed={true}
                                    allowEditing={false}
                                    cellRender={(e) => this.renderButtonAction(e, "DeliveryItemID")}
                                />
                            </GridContainer>
                            {!isView &&
                                <Tooltip title={Config.lang('Them')}>
                                    <IconButton
                                        aria-label="add"
                                        disabled={this.state.isEditing || isSwapMapping}
                                        className="pdt20"
                                        onClick={() => this.onAddRow(this.gridTabS)}
                                        color={"default"}
                                    >
                                        <AddCircleIcon color={"primary"} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </React.Fragment>
                    </TabPanel>
                </TabContent>
            </div>
        );
    }
}

export default withStyles(styles)(W05F0007PackingInfo);
