import {
    Dialog,


    DialogContent,
    // DialogActions,
    DialogTitle, TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from '@material-ui/icons/Search';
import { Column } from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React, { PureComponent } from 'react';
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Config from '../../../../config/index';
import * as W05F0005Actions from '../../../../redux/W0X/W05F0005/W05F0005_actions';
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
    paper: {
        alignSelf: 'start',
        overflowX: 'hidden'
    },
    searchTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#222B45',
        fontSize: '12px',
        marginTop: 17
    },
    ModalTitle: {
        justifyContent: 'space-between',
        height: 50,
        margin: '4px 0',
        paddingRight: '0 !important'
    }
};

class cbLoadmore extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            loading: false,
            dataGrid: [],
            totalDataGrid: 0,
            selectedRowKeys: [],
            filterGridValue: [],

        };
        this.filter = this.props.filter;
        this.tmpSelectedRowData = [];
        this.tmpSelectedRowKeys = [];
        this.changePage = false;
        this.timer = null;
    }

    comboApiName = () => {
        let apiName = "getCbVessel";
        const { keyExpr } = this.props;
        switch (keyExpr) {
            case "ObjectID":
            case "EstimateID":
            case "UnitPackID":
            case "ObjectIDCont":
            case "FumigationCompany":
                apiName = "getObjects";
                break;
            default:
                break;
        }
        return { apiName };
    }

    customDataGrid = (data, keyExpr) => {
        let dataCombo = [];
        switch (keyExpr) {
            case "EstimateID":
                dataCombo = data.map((item) => ({ EstimateID: item.ObjectID, EstimateName: item.ObjectNameU }));
                break;
            case "UnitPackID":
                dataCombo = data.map((item) => ({ UnitPackID: item.ObjectID, UnitPackName: item.ObjectNameU }));
                break;
            case "ObjectID":
                dataCombo = data.map((item) => ({ ObjectID: item.ObjectID, ObjectName: item.ObjectNameU }));
                break;
            case "ObjectIDCont":
                dataCombo = data.map((item) => ({ ObjectIDCont: item.ObjectID, ObjectNameCont: item.ObjectNameU }));
                break;
            default:
                break;
        }
        return dataCombo;
    }

    getCbData = (filter) => {
        this.setState({ loading: true });
        if (this.filter.O08ID === undefined) {
            const { apiName } = this.comboApiName();
            this.props.W05F0005Actions[apiName](filter, (error, data) => {
                this.setState({ loading: false });
                if (error) {
                    let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data) {
                    let dataGrid = data.rows;
                    if (this.props && this.props.keyExpr && data.rows.length > 0) {
                        dataGrid = this.customDataGrid(data.rows, this.props.keyExpr);
                    }
                    this.setState({ dataGrid, totalDataGrid: data.total });
                }
            });
        } else {
            this.props.W05F0005Actions.getCbGeneral(filter, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
                let temp = [];
                switch (filter.O08ID) {
                    case "10":
                        data.rows.map((item) => {
                            temp.push({ UnitPackID: item.ObjectID, UnitPackName: item.ObjectNameU });
                            return temp;
                        });
                        break;
                    case "20":
                        data.rows.map((item) => {
                            temp.push({ EstimateID: item.ObjectID, EstimateName: item.ObjectNameU });
                            return temp;
                        });

                        break;
                    case "30":
                        data.rows.map((item) => {
                            temp.push({ FumigationCompany: item.ObjectID, FumigationCompanyName: item.ObjectNameU });
                            return temp;
                        });
                        break;
                    default:
                        break;
                }
                this.setState({ dataGrid: temp, totalDataGrid: data.total, loading: false });
            });
        }
    }

    open = () => {
        const { onOpen } = this.props;
        this.getCbData(this.filter);
        this.setState({
            isOpen: true,
            selectedRowKeys: this.tmpSelectedRowKeys
        }, () => {
            if (onOpen) onOpen();
        });
    };

    close = (e) => {
        const { onClose } = this.props;
        this.filter.StrSearch = '';
        this.setState({
            isOpen: false,
            searchValue: ''
        }, () => {
            if (onClose) onClose(e);
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.getCbData(this.filter)
        this.changePage = true;
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.getCbData(this.filter)
        this.changePage = true;
    };

    onSearch = () => {
        this.getCbData(this.filter);
    }

    onDbCellClick = (e) => {
        const { onSave } = this.props;
        this.filter.StrSearch = '';
        this.setState({ searchValue: '' });
        if (onSave) onSave([e.data]);
        this.close();
    }

    render() {
        const { dataSource, column, itemPerPage, title, component, children, className, style, fullWidth, maxWidth, mode,
            keyExpr, transition, onOpen, onClose, typeShort, skipPerPage, pagerFullScreen,
            reference, isStrSearch, dialogProps, classes, listPerPage,
            ...props } = this.props;
        const { isOpen, dataGrid, loading, totalDataGrid } = this.state;
        const popupTransitions = Config.helpers.popupTransitions;
        const Trans = transition && popupTransitions[transition] ? popupTransitions[transition] : Config.popupTransition;
        if (props.onSave) delete props.onSave;
        const searchFieldWidth = maxWidth === "sm" ? 12 : 6;

        return (
            <React.Fragment >
                <Dialog
                    {...dialogProps}
                    open={isOpen}
                    className={className}
                    style={style}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth ? maxWidth : "md"}
                    PaperProps={{
                        style: styles.paper,
                    }}
                    aria-labelledby={"alert-dialog-title"}
                    aria-describedby={"alert-dialog-description"}
                    onEntered={this._onEntered}
                    onClose={this.close}
                    TransitionComponent={Trans}
                >
                    <DialogTitle
                        disableTypography
                        className={`center ${classes.ModalTitle}`}>
                        <Typography className={"mgr10 text-uppercase"} variant={"h6"}>{title ? title : ''}</Typography>
                        <div className={'display_row'} {...(window.innerWidth < 576 ? { style: { width: '100%', justifyContent: 'flex-end' } } : {})}>
                            <ButtonGeneral
                                name={Config.lang('ERP_Dong1')}
                                typeButton={"cancel"}
                                // disabled={loading}
                                style={{ textTransform: 'uppercase', marginRight: 15 }}
                                size={"large"}
                                onClick={this.close} />
                        </div>
                    </DialogTitle>
                    <DialogContent dividers style={{ height: '100%' }} className={"content"}>
                        <FormGroup>
                            {isStrSearch &&
                                <Row >
                                    <Col xs={12} sm={12} md={searchFieldWidth} lg={searchFieldWidth}>
                                        <span className={classes.searchTitle}>{Config.lang("ERP_Tim_kiem")}</span>
                                        <FormGroup>
                                            <TextField
                                                placeholder={Config.lang("ERP_Tim_kiem")}
                                                variant={"outlined"}
                                                fullWidth
                                                onChange={(e) => {
                                                    clearTimeout(this.timer);
                                                    const value = e.target.value;
                                                    this.timer = setTimeout(() => {
                                                        this.filter.StrSearch = value;
                                                        this.onSearch();
                                                    }, 1000);
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <Button
                                                            style={{
                                                                borderRadius: '100%',
                                                                minWidth: 0
                                                            }}
                                                            onClick={this.onSearch}
                                                        >
                                                            <SearchIcon />
                                                        </Button>
                                                    ),
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>}
                            <GridContainer
                                reference={ref => this.dataGrid = ref}
                                totalItems={totalDataGrid}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                listPerPage={listPerPage}
                                dataSource={dataGrid}
                                disabled={false}
                                keyExpr={keyExpr}
                                gridProps={{
                                    style: { maxHeight: 410 }
                                }}
                                pagerFullScreen={false}
                                showBorders={false}
                                columnAutoWidth={true}
                                typeShort={window.innerWidth < 768}
                                loadPanel={{
                                    enabled: loading
                                }}
                                height={"calc(100vh - 300px)"}
                                selection={{
                                    allowSelectAll: false,
                                    mode: 'single',
                                    selectAllMode: "",
                                    showCheckBoxesMode: "always"
                                }}
                                allowColumnResizing={true}
                                filterRow={{
                                    visible: false,
                                    showOperationChooser: false,
                                }}
                                filterPanel={{
                                    visible: false,
                                }}
                                filterBuilderPopup={{
                                    onInitialized: (e) => {
                                        e.component.dispose();
                                        e.element.remove();
                                    }
                                }}
                                selectedRowKey={this.tmpSelectedRowKeys}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onDbCellClick={this.onDbCellClick}
                            >
                                {column.map((e) => {
                                    return (
                                        <Column
                                            key={e.dataField}
                                            caption={e.caption}
                                            dataField={e.dataField}
                                            width={e.width}
                                        />
                                    );
                                })}
                            </GridContainer>
                        </FormGroup>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}
cbLoadmore.defaultProps = {
    isStrSearch: true,
    mode: 'single'
};
cbLoadmore.propTypes = {
    dataSource: PropTypes.any,
    column: PropTypes.any,
    keyExpr: PropTypes.string,
    open: PropTypes.bool,
    value: PropTypes.any,
    mode: PropTypes.string, //'single', 'multiple'
    className: PropTypes.string,
    style: PropTypes.string,
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,

    onClose: PropTypes.func,
    onSave: PropTypes.func,

    isStrSearch: PropTypes.bool,
    dialogProps: PropTypes.any,
    selectedRowKeys: PropTypes.any,
    listPerPage: PropTypes.array
};

const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W05F0005Actions: bindActionCreators(W05F0005Actions, dispatch)
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }), withStyles(styles))(cbLoadmore);
