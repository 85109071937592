import { FormLabel as Label, Tab, Tabs, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as w05f0006Actions from "../../../../redux/W0X/W05F0006/W05F0006_actions";
import ButtonGeneral from "../../../common/button/button-general";
import { Combo, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import MForm from "../../../common/form-material/form";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Filter from "../../../filter/filter";
import GridActionBar from '../../../grid-container/grid-actionbar';
import GridContainer from "../../../grid-container/grid-container";
import PopoverGrid from "../../../grid-container/popover-grid";
import W05F0006MappingList from "./W05F0006MappingList";
import ModalOldMappingW05F0006 from './modalOldMappingW05F0006';

const styles = {
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "45px"
  },
  divHeaderTitle: {
    color: "#000000",
    fontWeight: "500",
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
  },
  mappingGrid: {
    paddingTop: 10,
  },
  divIcon: {
    right: "50px",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    height: "36px",
    padding: 0,
    zIndex: 999,
    position: "absolute",
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    listStyle: "none",
    marginTop: "-17px",
    borderRadius: "4px",
  },
  divIconItem: {
    padding: 0,
    width: 30,
    height: 30,
    marginRight: 4,
  },
};

class W05F0006 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loadMapping: false,
      isPermission: 0,
      total: 0,
      dataSources: [],
      mappingSources: [],
      currentMappingRow: {},
      StrSearch: "",
      ContractNo: "",
      VoucherNo: "",
      LotNo: "",
      InventoryID: "",
      indexTab: 0,
      selectedRowKeys: [],
      mode: "single",
      dataCbInventory: {
        rows: [],
        total: 0,
      },
      popOver: null,
      showFormItem: false,
      error: {},
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      W06F0004Permission: 0,
      W06F0005Permission: 0,

      openModalOldMappingW05F006: false
    };
    this.filter = {
      skip: 0,
      limit: 10,
      selectedDate: 7, // 7 Default giá trị là Nam nay
      StrSearch: "",
      ContractNo: "",
      VoucherNo: "",
      LotNo: "",
      InventoryID: "",
      firstDayMonthDateFrom: moment().startOf("year").format("YYYY-MM-DD"), // 7 Default giá trị là Nam nay
      lastDayMonthDateTo: moment().endOf("year").format("YYYY-MM-DD"), // 7 Default giá trị là Nam nay
    };
    this.filterCboInventory = {
      StrSearch: "",
      ContractNo: "",
      limit: 10,
      skip: 0,
    }
    this.filterMapping = {
      skip: 0,
      limit: 10,
    };
    this.tmpSelectedRowKeys = [];
    this.tmpSelectedRowData = [];
    this.changePage = false;
    this.isCtrlKey = false;
    this.isLoadDone = false;
    this.toolFilter = null;
    this.dataDayByCombo = [
      {
        id: 1,
        fieldName: Config.lang("ERP_Hom_nay"),
        dateFrom: moment().format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD")
      },
      {
        id: 2,
        fieldName: Config.lang("ERP_Hom_qua"),
        dateFrom: moment().subtract(1, 'day').format("YYYY-MM-DD"),
        dateTo: moment().subtract(1, 'day').format("YYYY-MM-DD")
      },
      {
        id: 3,
        fieldName: Config.lang("ERP_Tuan_nay"),
        dateFrom: moment().startOf('week').format("YYYY-MM-DD"),
        dateTo: moment().endOf('week').format("YYYY-MM-DD")
      },
      {
        id: 4,
        fieldName: Config.lang("ERP_Tuan_truoc"),
        dateFrom: moment().subtract(1, 'week').startOf('week').format("YYYY-MM-DD"),
        dateTo: moment().subtract(1, 'week').endOf('week').format("YYYY-MM-DD")
      },
      {
        id: 5,
        fieldName: Config.lang("ERP_Thang_nay"),
        dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
        dateTo: moment().endOf("month").format("YYYY-MM-DD")
      },
      {
        id: 6,
        fieldName: Config.lang("ERP_Thang_truoc"),
        dateFrom: moment().subtract(1, 'months').startOf("month").format("YYYY-MM-DD"),
        dateTo: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
      },
      {
        id: 7,
        fieldName: Config.lang("ERP_Nam_nay"),
        dateFrom: moment().startOf("year").format("YYYY-MM-DD"),
        dateTo: moment().endOf("year").format("YYYY-MM-DD")
      },
      {
        id: 8,
        fieldName: Config.lang("ERP_Nam_truoc"),
        dateFrom: moment().subtract(1, 'years').startOf("year").format("YYYY-MM-DD"),
        dateTo: moment().subtract(1, 'years').endOf("year").format("YYYY-MM-DD")
      },
      {
        id: 9,
        fieldName: Config.lang("ERP_Chon_thoi_gian"),
        dateFrom: moment().format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD")
      },
    ];
  }

  showFormSearchDay = (key, data) => {
    if (!key || !data || !data.value || _.isEmpty(this.dataDayByCombo)) return false;
    const checked = this.dataDayByCombo.find(value => value.id === data.value);
    const showFormItem = checked.id === 9; // 9 Là tùy Chọn Ngày
    this.filter.selectedDate = data.value;
    this.filter.firstDayMonthDateFrom = checked.dateFrom;
    this.filter.lastDayMonthDateTo = checked.dateTo;
    this.setState({ showFormItem });
  };

  handleFilterChange(key, data) {
    if (!key) return false;
    // this.setState({ [key]: data.target.value });
    switch (key) {
      case "dateFrom":
        this.setState({ dateFrom: data.value });
        this.filter.firstDayMonthDateFrom = data.value;
        break;
      case "dateTo":
        this.setState({ dateTo: data.value });
        this.filter.lastDayMonthDateTo = data.value;
        break;
      case "id":
        this.showFormSearchDay(key, data);
        break;
      case "ContractNo":
      case "StrSearch":
      case "VoucherNo":
      case "LotNo":
        this.setState({ [key]: data.target.value })
        break;
      case "InventoryID":
        this.setState({ InventoryID: data.value ? data.value.InventoryID : "" })
        break;
      default:
        break;
    }
  }

  renderFilters = () => {
    const { ContractNo, VoucherNo, LotNo, InventoryID, dataCbInventory, loadingCbInventory, showFormItem, error, dateFrom, dateTo } = this.state;
    const { firstDayMonthDateFrom, lastDayMonthDateTo, selectedDate } = this.filter;
    const { limit, skip } = this.filterCboInventory;
    return (
      <Filter
        dropdownProps={{ ref: ref => this.toolFilter = ref }}
        onTextChanged={this.onSearch}
        placeholder={Config.lang("ERP_Noi_dung_can_tim_kiem")}
        onOpenLoaded={this.onLoadDataFilter}
        renderFilter={() => {
          return (
            <>
              <FormGroup>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo
                      dataSource={Config.storeDataSoureDevExtreme(this.dataDayByCombo)}
                      valueExpr={"id"}
                      stylingMode={"outlined"}
                      label={Config.lang("ERP_Chon_thoi_gian")}
                      displayExpr={"fieldName"}
                      value={selectedDate || 7}
                      onValueChanged={(e) => this.handleFilterChange("id", e)}
                    />
                  </Col>
                </Row>

                {showFormItem ? (
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <div className={"display_row align-center"}>
                        <Label className={"mgr5"}>
                          {Config.lang("ERP_Tu")}
                        </Label>
                        <DateBoxPicker
                          error={error && error["DateFrom"]}
                          max={dateTo}
                          placeholder={"DD/MM/YYYY"}
                          useMaskBehavior={true}
                          showClearButton={true}
                          width={"100%"}
                          value={firstDayMonthDateFrom}
                          onValueChanged={(e) =>
                            this.handleFilterChange("dateFrom", e)
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <div className={"display_row align-center"}>
                        <Label className={"mgr5"}>
                          {Config.lang("ERP_Den")}
                        </Label>
                        <DateBoxPicker
                          error={error && error["DateTo"]}
                          min={dateFrom}
                          placeholder={"DD/MM/YYYY"}
                          useMaskBehavior={true}
                          showClearButton={true}
                          width={"100%"}
                          value={lastDayMonthDateTo}
                          onValueChanged={(e) =>
                            this.handleFilterChange("dateTo", e)
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div>
                      <TextField
                        label={Config.lang("ERP_So_hop_dong_ban")}
                        variant={"outlined"}
                        value={ContractNo}
                        onChange={(e) => this.handleFilterChange("ContractNo", e)}
                        fullWidth
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div>
                      <TextField
                        label={Config.lang("ERP_Dot_trien_khai")}
                        variant={"outlined"}
                        value={VoucherNo}
                        onChange={(e) => this.handleFilterChange("VoucherNo", e)}
                        fullWidth
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div>
                      <TextField
                        label={Config.lang("ERP_Ma_lot")}
                        variant={"outlined"}
                        value={LotNo}
                        onChange={(e) =>
                          this.handleFilterChange("LotNo", e)
                        }
                        fullWidth
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo.LoadMore
                      dataSource={dataCbInventory}
                      displayExpr={"InventoryNameU"}
                      valueExpr={"InventoryID"}
                      loading={loadingCbInventory}
                      value={InventoryID}
                      onValueChanged={e => this.handleFilterChange("InventoryID", e)}
                      skip={skip}
                      limit={limit}
                      keyExpr={'InventoryID'}
                      stylingMode={'outlined'}
                      label={Config.lang("ERP_Hang_hoa")}
                      showClearButton={true}
                      onInputChanged={(e) => {
                        this.filterCboInventory.strSearch = e.target.value;
                        this.filterCboInventory.skip = 0;
                        this.loadCboInventory(true);
                      }}
                      itemRender={(e) => {
                        const { data } = e;
                        if (!data) return null;
                        return `${data.InventoryID} - ${data.InventoryNameU}`;
                      }}
                      onLoadMore={(e) => {
                        this.filterCboInventory.skip = e.skip;
                        this.filterCboInventory.limit = e.limit;
                        this.loadCboInventory();
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className={"display_row align-center valign-middle"}>
                <ButtonGeneral
                  name={Config.lang("ERP_Tim_kiem")}
                  size={"large"}
                  typeButton={"search"}
                  color={"primary"}
                  variant={"outlined"}
                  onClick={this.onFilter}
                />
              </FormGroup>
            </>
          );
        }}
      />
    );
  };

  loadCboInventory = (isReset) => {
    const { strSearch: StrSearch = "", ContractID = "", skip, limit } = this.filterCboInventory;
    const params = {
      StrSearch,
      ContractID,
      skip,
      limit
    };
    this.setState({ loadingCbInventory: true });
    this.props.w05f0006Actions.getCboInventory(params, (error, data) => {
      this.setState({ loadingCbInventory: false });
      if (error) {
        const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        return false;
      }
      if (data) {
        const { dataCbInventory } = this.state;
        const rows = data && data.rows ? data.rows : data;
        const total = data && data.total ? data.total : data.length;
        this.setState({
          dataCbInventory: {
            rows: isReset ? rows : dataCbInventory.rows.concat(rows),
            total: total
          }
        });
      }
    });
  };

  redirectScreen = (mode, data = {}) => {
    let screen = "";
    let params = {
      MappingID: data && data.MappingID,
    };
    switch (mode) {
      case "add":
        params.mode = "add";
        screen = "W05F0007";
        break;
      case "view":
        params.mode = "view";
        params.Mode = 0;
        screen = "W05F0007";
        break;
      case "edit":
        params.mode = "edit";
        params.Mode = 0;
        screen = "W05F0007";
        break;
      case "W06F0004":
        screen = "W06F0004";
        break;
      case "W06F0005":
        screen = "W06F0005";
        break;
      default:
        break;
    }
    browserHistory.push({
      pathname: Config.getRootPath() + screen,
      state: params
    });
  };

  setStateErrorText = (value) => {
    const { error } = this.state;
    this.setState({
      error: {
        ...error,
        ...value
      }
    });
    return Object.keys(value).length !== 0;
  };

  loadSaleContracts = (isReset, contractNoNew) => {
    const { firstDayMonthDateFrom, lastDayMonthDateTo } = this.filter;
    if (
      (firstDayMonthDateFrom !== null && lastDayMonthDateTo === null) ||
      (firstDayMonthDateFrom === null && lastDayMonthDateTo !== null)
    ) {
      const validateRules = [
        {
          name: "DateFrom",
          rules: "isRequired",
          value: firstDayMonthDateFrom,
        },
        {
          name: "DateTo",
          rules: "isRequired",
          value: lastDayMonthDateTo,
        },
      ];
      const validateFrom = MForm.formValidation(validateRules);
      if (Object.keys(validateFrom).length > 0) {
        this.setStateErrorText(validateFrom);
        return false;
      }
    } else {
      this.setState({ error: {} });
      const { StrSearch = "", ContractNo = "", VoucherNo = "", LotNo = "", InventoryID = "" } = this.state;
      const { skip, limit } = this.filter;
      const params = {
        Language: Config.language || "84",
        StrSearch,
        ContractNo: contractNoNew ? contractNoNew : ContractNo,
        VoucherNo,
        LotNo,
        InventoryID,
        DateFrom: firstDayMonthDateFrom,
        DateTo: lastDayMonthDateTo,
        skip,
        limit,
      };
      if (!isReset) {
        this.changePage = true;
      }
      this.setState({ loaded: true });
      this.props.w05f0006Actions.getSaleContracts(params, (error, data) => {
        this.setState({ loaded: false });
        if (error) {
          const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
          Config.popup.show("INFO", message);
          return false;
        }
        if (data) {
          const dataSource = data.rows ? data.rows.map((item) => ({ ...item })) : [];
          const selectKeys = dataSource.map(d => d.QuotationItemID).filter(d => this.tmpSelectedRowKeys.indexOf(d) > -1);
          this.setState({
            dataSources: dataSource,
            total: data.total ? data.total : 0,
            selectedRowKeys: isReset ? [dataSource[0]?.QuotationItemID] : selectKeys,
          });
        }
      });
    }
  };

  setSelected = (e) => {
    const selectedRowsData = e.selectedRowsData;
    const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
    if (currentDeselectedRowKeys.length > 0 && !this.changePage) {
      this.tmpSelectedRowData = this.tmpSelectedRowData.filter((d) => {
        return currentDeselectedRowKeys.indexOf(d.QuotationItemID) < 0;
      });
      this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((d) => {
        return currentDeselectedRowKeys.indexOf(d) < 0;
      });
    }
    if (selectedRowsData.length > 0) {
      selectedRowsData.forEach((val) => {
        if (this.tmpSelectedRowKeys.indexOf(val.QuotationItemID) < 0) {
          this.tmpSelectedRowData.push(val);
          this.tmpSelectedRowKeys.push(val.QuotationItemID);
        }
      });
    }
  };

  onSelectionChanged = (e) => {
    const { mode, selectedRowKeys } = this.state;
    const { currentSelectedRowKeys, currentDeselectedRowKeys } = e;

    if (mode === "single") {
      if (
        currentSelectedRowKeys.length === 0 &&
        currentDeselectedRowKeys.length > 0
      ) {
        this.setState({ selectedRowKeys: currentDeselectedRowKeys });
        return;
      } else {
        let isNewSelected = false;
        this.tmpSelectedRowKeys.forEach((item) => {
          if (e.selectedRowKeys.indexOf(item) < 0) {
            isNewSelected = true;
            return false;
          }
        });
        if (this.tmpSelectedRowKeys.length <= 0 || isNewSelected) {
          if (this.isCtrlKey && isNewSelected) {
            this.setSelected(e);
            this.setState({ mode: "multiple" }, () => {
              this.setState(
                {
                  selectedRowKeys: selectedRowKeys.concat(this.tmpSelectedRowKeys),
                },
                () => {
                  let values = this.tmpSelectedRowData.map(
                    ({ QuotationID, QuotationItemID }) => {
                      return { QuotationID, QuotationItemID };
                    }
                  );
                  this.loadMappingBySaleContract(values);
                }
              );
            });
            // this.setSelected(e);

          } else {
            // this.setSelected(e);
            this.tmpSelectedRowKeys = e.selectedRowKeys;
            this.tmpSelectedRowData = e.selectedRowsData;
            this.setState({ selectedRowKeys: e.selectedRowKeys }, () => {
              let values = e.selectedRowsData.map(
                ({ QuotationID, QuotationItemID }) => {
                  return { QuotationID, QuotationItemID };
                }
              );
              this.loadMappingBySaleContract(values);
            });
          }
        } else {
          // this.setSelected(e)
          this.setState({ selectedRowKeys: e.selectedRowKeys });
        }
      }
    } else {
      if (this.isCtrlKey) {
        this.setSelected(e);
        this.setState({ selectedRowKeys: e.selectedRowKeys }, () => {
          let values = this.tmpSelectedRowData.map(
            ({ QuotationID, QuotationItemID }) => {
              return { QuotationID, QuotationItemID };
            }
          );
          this.loadMappingBySaleContract(values);
        });
      } else {
        this.setSelected(e);
        this.setState({ selectedRowKeys: e.selectedRowKeys }, () => {
          let values = this.tmpSelectedRowData.map(
            ({ QuotationID, QuotationItemID }) => {
              return { QuotationID, QuotationItemID };
            }
          );
          this.loadMappingBySaleContract(values);
        });
      }
      if (this.tmpSelectedRowKeys.length < 2) {
        this.setState({ mode: "single" });
      }
    }
    if (this.changePage) {
      this.changePage = false;
    }
  };

  loadMappingBySaleContract = (values, isReset) => {
    const { skip, limit } = this.filterMapping;
    if (values.length > 0) {
      const params = {
        values: JSON.stringify(values),
        skip: skip,
        limit: limit,
      };
      this.setState({ loadMapping: true });
      this.props.w05f0006Actions.getMappingBySaleContract(
        params,
        (error, data) => {
          this.isLoadDone = true;
          this.setState({ loadMapping: false });
          if (error) {
            const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
            Config.popup.show("INFO", message);
            return false;
          }
          if (data) {
            this.setState({ mappingSources: data.rows ? data.rows.map((item) => ({ ...item })) : [] });
          }
        }
      );
    } else {
      this.setState({ mappingSources: [] })
    }
  };

  onLoadDataFilter = () => {
    this.loadCboInventory();
  };

  onSearch = (e) => {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ StrSearch: e.target.value });
      this.filter.skip = 0;
      this.filter.limit = 10;
      this.loadSaleContracts(true);
    }, 500);
  };

  loadPermission = async () => {
    await this.props.generalActions.getPermission(["W05F0006", "W06F0004", "W06F0005"], (isPer) => {
      if (!_.isEmpty(isPer)) {
        const indexW05F0006 = isPer.findIndex(item => item.ScreenID === "W05F0006");
        const indexW06F0004 = isPer.findIndex(item => item.ScreenID === "W06F0004");
        const indexW06F0005 = isPer.findIndex(item => item.ScreenID === "W06F0005");
        this.setState({
          isPermission: isPer[indexW05F0006].Permission,
          W06F0004Permission: isPer[indexW06F0004].Permission,
          W06F0005Permission: isPer[indexW06F0005].Permission,
        });
      }
    });
  };

  componentDidMount = async () => {
    const { state = {} } = this.props.location;
    await this.loadPermission();
    if (this.state.isPermission <= 0) return;
    if (state?.form_id === "W05F0006" && !_.isEmpty(state?.voucher_id)) {
      this.loadSaleContracts(true, state.voucher_id); // Load theo ContractNo nhận từ Notify
    } else {
      this.loadSaleContracts(true);
    }
    // this.loadMappingBySaleContract();
    // this.loadCboStatus();
  }

  onFilter = () => {
    this.loadSaleContracts(true);
    if (this.toolFilter) this.toolFilter.instance.close(); // Đóng dropdown
  };

  onChangePage = (page) => {
    this.filter.skip = page * this.filter.limit;
    this.loadSaleContracts();
    // this.changePage = true;
  };

  onChangePerPage = (per) => {
    this.filter.skip = 0;
    this.filter.limit = per;
    this.loadSaleContracts();
    // this.changePage = true;
  };

  renderSaleContracts = (tabIndex) => {
    const { loaded, dataSources, total, loadMapping, mode, selectedRowKeys } = this.state;
    const { limit, skip } = this.filter;
    const info = tabIndex === 1;
    const detail = tabIndex === 0;
    return (
      <>
        <GridContainer
          totalItems={total}
          itemPerPage={limit}
          skipPerPage={skip}
          dataSource={dataSources}
          loading={loaded}
          disabled={loadMapping || !this.isLoadDone}
          showBorders={false}
          keyExpr={"QuotationItemID"}
          pagerFullScreen={false}
          showColumnLines={false}
          hoverStateEnabled={true}
          showRowLines={false}
          selection={{
            allowSelectAll: false,
            mode: mode,
            selectAllMode: "",
            showCheckBoxesMode: mode === "multiple" ? "always" : "none",
          }}
          onCellClick={(e) => {
            this.isCtrlKey = e.event.ctrlKey;
            const { mode, selectedRowKeys } = this.state;
            if (mode === "multiple" && e.rowIndex > -1) {
              if (e.event.ctrlKey) {
                const key = e.component.getKeyByRowIndex(e.rowIndex);
                if (selectedRowKeys.indexOf(key) > -1)
                  e.component.deselectRows([key]);
              }
            }
          }}
          selectedRowKey={selectedRowKeys}
          onSelectionChanged={this.onSelectionChanged}
          onChangePage={this.onChangePage}
          onChangePerPage={this.onChangePerPage}
          height={Config.getHeightGrid() / 3}
        >
          <Column
            caption={Config.lang("ERP_So_hop_dong")}
            dataField={"ContractNo"}
            alignment={"left"}
            visible={info}
          />
          <Column
            caption={Config.lang("ERP_Khach_hang")}
            dataField={"BuyerName"}
            alignment={"left"}
            width={175}
            visible={info}
          />
          <Column
            caption={Config.lang("ERP_Phuong_tien")}
            dataField={"Transportation"}
            width={175}
            visible={info}
          />
          <Column
            caption={Config.lang("ERP_Dot_trien_khai")}
            dataField={"VoucherNo"}
            alignment={"left"}
            width={175}
            visible={info}
          />
          <Column
            caption={Config.lang(
              "ERP_Thoi_gian_giao_hang_du_kien_cua_dot_trien_khai"
            )}
            dataField={"ValidTo"}
            width={385}
            alignment={"center"}
            dataType={"date"}
            cellRender={(e) => {
              const { data } = e.row;
              if (
                moment(data.ValidTo).isValid() &&
                moment(data.ValidFrom).isValid()
              ) {
                return (
                  `${moment.utc(data.ValidFrom).format("DD/MM/YYYY")} - ${moment.utc(data.ValidTo).format("DD/MM/YYYY")}`
                );
              } else {
                return null;
              }
            }}
            visible={info}
          />
          <Column
            caption={Config.lang("ERP_So_luong_tong_cua_dot_trien_khai")}
            dataField={"TotalQuantity"}
            alignment={"right"}
            width={285}
            visible={info}
          />

          {/* Tab detail */}
          <Column
            caption={Config.lang("ERP_Ma_lot")}
            dataField={"LotNo"}
            alignment={"left"}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_Ma_hang")}
            dataField={"InventoryID"}
            alignment={"left"}
            width={175}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_Ten_hang")}
            dataField={"InventoryNameU"}
            alignment={"left"}
            width={145}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_So_luong_lot")}
            dataField={"TotalQuantity"}
            alignment={"right"}
            width={145}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_So_luong_da_mapping")}
            dataField={"MAPQuantity"}
            alignment={"right"}
            width={185}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_So_luong_con_lai")}
            dataField={"Quantity"}
            alignment={"right"}
            width={175}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_Quy_cach_dong_goi")}
            dataField={"PackingMethodName"}
            alignment={"left"}
            width={175}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_Loai_bao_dong_goi")}
            dataField={"PackingTypeName"}
            alignment={"left"}
            width={175}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_Nhan_mac")}
            dataField={"MarkingName"}
            alignment={"left"}
            width={175}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_Thong_tin_bao_bi")}
            dataField={"PackNote"}
            alignment={"left"}
            width={175}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_Giam_dinh")}
            dataField={"EstimateName"}
            alignment={"left"}
            width={175}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_Phun_trung")}
            dataField={"Fumigation"}
            alignment={"left"}
            width={175}
            visible={detail}
          />
          <Column
            caption={Config.lang("ERP_Thoi_gian_giao_hang_theo_lot")}
            dataField={"DeliveryDateTo"}
            width={245}
            alignment={"center"}
            dataType={"date"}
            cellRender={(e) => {
              const { data } = e.row;
              if (
                moment(data.DeliveryDateTo).isValid() &&
                moment(data.DeliveryDateFrom).isValid()
              ) {
                return (
                  `${moment.utc(data.DeliveryDateFrom).format("DD/MM/YYYY")} - ${moment.utc(data.DeliveryDateTo).format("DD/MM/YYYY")}`
                );
              } else {
                return null;
              }
            }}
            visible={detail}
          />

        </GridContainer>
      </>
    );
  };


  renderAction = (e) => {
    const { classes } = this.props;
    const { data } = e.row;
    return (
      <GridActionBar>
        <IconButton
          aria-describedby={data.MappingItemID}
          aria-label={"view"}
          className={classes.divIconItem}
          onClick={(e) => this.handleClick(e, data, "popOver")}
          disabled={this.state.popOver === null ? false : true}
        >
          <MoreVertIcon />
        </IconButton>
      </GridActionBar>
    );
  };

  handleClick = (event, data, stateName) => {
    this.setState({ [stateName]: event.currentTarget, currentMappingRow: data });
  };

  handleClose = (stateName) => {
    this.setState({ [stateName]: null });
  };


  onView = (mode, data) => {
    this.redirectScreen(mode, data);
  };

  onEdit = (mode, data) => {
    this.redirectScreen(mode, data);
  };

  popupOpen = () => {
    const { classes } = this.props;
    const { popOver, isPermission, mappingSources, currentMappingRow } = this.state;
    return (
      <>
        {mappingSources.length > 0 && popOver !== null && <PopoverGrid
          id={currentMappingRow.MappingItemID}
          anchorEl={popOver}
          onClose={() => this.handleClose("popOver")}
          itemProps={
            <div style={{ padding: 5 }}>
              <Tooltip title={Config.lang("ERP_Xem")}>
                <IconButton
                  disabled={!(isPermission > 0)}
                  aria-label={"View"}
                  className={classes.divIconItem}
                  onClick={() => this.onView("view", currentMappingRow)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={Config.lang("ERP_Sua")}>
                <IconButton
                  disabled={!(isPermission > 2)}
                  aria-label={"Edit"}
                  className={classes.divIconItem}
                  onClick={() => this.onEdit("edit", currentMappingRow)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </div>
          }
        />}
      </>
    )
  }

  handleChanged = (event, value) => {
    this.setState({ indexTab: value });
  };

  onOpenModalOldMappingW05F006 = () => {
    this.setState({ openModalOldMappingW05F006: !this.state.openModalOldMappingW05F006 });
  }

  render() {
    const { classes } = this.props;
    const { isPermission, indexTab, mappingSources, loadMapping, W06F0004Permission, W06F0005Permission, openModalOldMappingW05F006 } = this.state;
    return (
      <>
        {openModalOldMappingW05F006 && <ModalOldMappingW05F0006 onClose={this.onOpenModalOldMappingW05F006} />}
        <ActionToolbar allwaysTop title={Config.lang("ERP_Danh_sach_mapping")}>
          <ButtonGeneral
            disabled={!isPermission > 1}
            name={Config.lang("ERP_Them_mapping")}
            typeButton={"add"}
            style={{ textTransform: "uppercase" }}
            onClick={() => this.redirectScreen("add")}
          />
          <ButtonGeneral
            name={Config.lang("Mapping_cu")}
            size={"large"}
            style={{ textTransform: "uppercase", marginLeft: Config.isMobile ? 0 : 10 }}
            onClick={this.onOpenModalOldMappingW05F006}
          />
          {W06F0004Permission > 1 &&
            <ButtonGeneral
              name={Config.lang("ERP_Thong_bao_giao_hang")}
              size={"large"}
              typeButton={"W06F0004"}
              style={{ textTransform: "uppercase", marginLeft: Config.isMobile ? 0 : 10 }}
              onClick={() => this.redirectScreen("W06F0004")}
            />
          }
          {W06F0005Permission > 0 &&
            <ButtonGeneral
              name={Config.lang("ERP_Xac_nhan_giao_hang")}
              size={"large"}
              typeButton={"confirm_delivery"}
              style={{ textTransform: "uppercase", marginLeft: Config.isMobile ? 0 : 10 }}
              onClick={() => this.redirectScreen("W06F0005")}
            />
          }
        </ActionToolbar>
        <div className={"hidden"}>{this.renderFilters()}</div>
        <div className={classes.titleRow}>
          <Label className={classes.divHeaderTitle}>
            {Config.lang("ERP_Hop_dong_ban")}
          </Label>
          <Tabs
            indicatorColor={"primary"}
            textColor={"primary"}
            value={indexTab}
            onChange={this.handleChanged}
          >
            <Tab value={1} label={Config.lang("ERP_Thong_tin_chung")} />
            <Tab value={0} label={Config.lang("ERP_Chi_tiet")} />
          </Tabs>
        </div>
        {this.renderSaleContracts(indexTab)}
        <div className={classes.titleRow}>
          <Label className={classes.divHeaderTitle}>
            {Config.lang("ERP_Danh_sach_mapping")}
          </Label>
        </div>
        <W05F0006MappingList
          mappingSources={mappingSources}
          loadMapping={loadMapping}
          isPermission={isPermission}
          cellRender={this.renderAction}
          isLoadDone={this.isLoadDone}
        />
        {this.popupOpen()}
      </>
    );
  }
}

W05F0006.propsTypes = {
  w05f0006Actions: PropTypes.any,
};

export default compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      generalActions: bindActionCreators(generalActions, dispatch),
      w05f0006Actions: bindActionCreators(w05f0006Actions, dispatch),
    })
  ),
  withStyles(styles)
)(W05F0006);
