import React, { Component } from 'react';
import { FormLabel as Label } from "@material-ui/core";
import Config from '../../../../config/index';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import GridContainer from "../../../grid-container/grid-container";
import { Column, } from "devextreme-react/data-grid";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckBox from 'devextreme/ui/check_box';
import GridActionBar from '../../../grid-container/grid-actionbar';
import { ButtonIcon, Tooltip } from 'diginet-core-ui/components';
const styles = {
    dataGridHover: {
        left: '0 !important',
        '& button': {
            marginBottom: '0 !important',
            height: '10px',
            width: '10px',
        }
    },
    columns: {
        height: '40px !important',
        minHeight: '40px !important',
    }
};
class W05F0007PO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: []
        }
        this.tmpSelectedRowData = [];
        this.isChecking = false;
        this.isFisrt = true;
    }
    onDelete = (e) => {
        const dataFilter = this.props.dataGridMappingInfo.filter(rs => rs.PurchaseItemID === e.data.PurchaseItemID);
        if (dataFilter.length > 0) {
            Config.popup.show("INFO", "Dữ liệu đã được sử dụng. Bạn không được xóa");
        } else {
            Config.popup.show(
                "YES_NO",
                Config.lang("ERP_Ban_co_chac_muon_xoa?"),
                () => {
                    e.component.deleteRow(e.rowIndex);
                    this.gridTabPO.instance.saveEditData();
                    this.props.refW05F0007.onCheckDelete();
                    this.props.refW05F0007.setIsUpdate();
                },
                () => {
                    //no
                }
            );
        }
    }
    componentDidMount() {
        if (this.props.onRefPO) this.props.onRefPO(this);
    }
    renderActions = (e) => {
        return (
            <GridActionBar >
                <Tooltip title={Config.lang('Xoa')}>
                    <ButtonIcon
                        circular
                        size="small"
                        viewType="text"
                        name="delete"
                        onClick={() => this.onDelete(e)} />
                </Tooltip>
            </GridActionBar>
        )
    }
    onSelectionChanged = (e) => {
        const { isSwapMapping, itemSwap, oldDataGridPOSwap, disabledAllForSave } = this.props;
        if (disabledAllForSave) return false;
        if (isSwapMapping) {
            const selectedRowsData = e.selectedRowsData;
            if (!this.isFisrt && e.selectedRowKeys.length === 1) {
                let dataPOFilter = oldDataGridPOSwap.map(rs => ({ ...rs }));
                dataPOFilter.map(item => {
                    if (itemSwap.PurchaseItemID === item.PurchaseItemID) {
                        item.Quantity = itemSwap.Quantity + item.Quantity;
                        item.MAPQuantity = item.MAPQuantity - itemSwap.Quantity;
                    }
                    if (item.PurchaseItemID === e.selectedRowKeys[0]) {
                        item.Quantity = item.Quantity - itemSwap.Quantity;
                        item.MAPQuantity = item.MAPQuantity + itemSwap.Quantity;
                        item.isUsed = 1;
                    } else {
                        item.isUsed = 0;
                    }
                    return item;
                });
                this.props.refW05F0007.onSetDataGridSwap('dataGridPO', dataPOFilter);
            } else {
                this.isFisrt = false;
            }
            if (selectedRowsData.length > 0) {
                selectedRowsData.forEach((val) => {
                    this.tmpSelectedRowData = [val];
                    this.setState({ selectedRowKeys: [val['PurchaseItemID']] });
                });
            }

        } else {
            if (!this.isChecking) {
                this.isChecking = true
                const { selectedRowsData, selectedRowKeys } = e;
                this.tmpSelectedRowData = selectedRowsData;
                this.props.refW05F0007.onCheckPO(this.tmpSelectedRowData);
                this.setState({
                    selectedRowKeys: selectedRowKeys,
                });
                setTimeout(() => {
                    this.isChecking = false
                }, 100);
            }
        }

    }
    setData = () => {
        this.tmpSelectedRowData = [];
        this.setState({
            selectedRowKeys: [],
        });
    }
    setSelectedRowKeyOnSwap = (data, key) => {
        this.tmpSelectedRowData = data;
        this.setState({
            selectedRowKeys: key,
        });
    }

    setSelectedRowDetail = (e) => {
        if (!e) return;
        const _dataSource = this.gridTabPO.instance.option('dataSource');
        const rowIndex = _dataSource.findIndex(rs => rs.PurchaseItemID === e.PurchaseItemID);
        this.tmpSelectedRowData = _dataSource[rowIndex];
        this.setState({
            selectedRowKeys: [e.PurchaseItemID],
        });
        // this.gridTabPO.instance.selectRowsByIndexes([rowIndex]);
    }

    render() {
        const { dataGridPO, isView, refW05F0007, classes, isSwapMapping, itemSwap, disabledAllForSave } = this.props;
        const { selectedRowKeys } = this.state;
        return (
            <div>
                <div className="text-row pdb10 pdt10">
                    {!isSwapMapping && !disabledAllForSave &&
                        <Tooltip title={Config.lang('Them')}>
                            <div className="text-row cursor-pointer user-select" onClick={() => !isView && this.props.refW05F0007.onOpenList('PO')}>
                                {!isView && <AddCircleIcon />}
                                <Label style={{ fontWeight: 500, color: 'black' }} className="cursor-pointer pdl10 text-title" >{Config.lang('ERP_Hop_dong_mua')}</Label>
                            </div>
                        </Tooltip>
                    }
                </div>
                <GridContainer
                    reference={ref => this.gridTabPO = ref}
                    dataSource={dataGridPO}
                    columnAutoWidth={true}
                    listPerPage={[5, 10, 15, 30, 45, 60]}
                    itemPerPage={5}
                    typePaging={"normal"}
                    pagerFullScreen={false}
                    keyExpr={'PurchaseItemID'}
                    height={300}
                    typeShort={true}
                    showColumnLines={false}
                    showRowLines={false}
                    onRowClick={this.onRowClick}
                    hoverStateEnabled={true}
                    elementAttr={{ class: `w05f0007-grid` }}
                    sorting={{
                        mode: "none"
                    }}
                    selection={{
                        allowSelectAll: !disabledAllForSave && !isSwapMapping,
                        mode: isView ? 'single' : "multiple",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: "always",
                    }}
                    onCellPrepared={e => {
                        if (isSwapMapping) {
                            if (e.row?.data && itemSwap.Quantity > e.row?.data?.Quantity) {
                                let editor = CheckBox.getInstance(e.cellElement.querySelector(".dx-select-checkbox"));
                                editor && editor.option("disabled", true);
                                e.cellElement.style.pointerEvents = 'none';
                                e.cellElement.style.backgroundColor = '#efefef';
                            }
                        }
                    }}
                    selectedRowKey={selectedRowKeys}
                    onSelectionChanged={this.onSelectionChanged}
                >
                    <Column
                        caption={Config.lang("ERP_So_hop_dong")} dataField={'ContractNo'} allowEditing={false} minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Nha_cung_cap")} dataField={'Supplier'} allowEditing={false} minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_hang")} dataField={'InventoryID'} allowEditing={false} minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Ten_hang")} dataField={'InventoryNameU'} allowEditing={false} minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong")} dataField={'SUMQuantity'} allowEditing={false} minWidth={150}
                        cellRender={refW05F0007.renderNumberFormat}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_da_mapping")} dataField={'MAPQuantity'} allowEditing={false} minWidth={150}
                        cellRender={refW05F0007.renderNumberFormat}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_con_lai")} dataField={'Quantity'} allowEditing={false} minWidth={150}
                        cellRender={refW05F0007.renderNumberFormat}
                    />
                    <Column
                        cellRender={this.renderActions}
                        visible={(!isView || Config.isMobile) && !isSwapMapping && !disabledAllForSave}
                        alignment='center'
                        width={80}
                        cssClass={classes.columns}
                        fixed={true}
                        fixedPosition={"right"}
                    />
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(W05F0007PO);
