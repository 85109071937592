import React, { useEffect, useState } from 'react';
import { Dropdown, Button, Modal, ModalHeader, ModalBody } from 'diginet-core-ui/components';
import Config from '../../../../config/index';
import * as w05f0006Actions from "../../../../redux/W0X/W05F0006/W05F0006_actions";
import { useDispatch } from 'react-redux';
import { browserHistory } from "react-router";
const ModalOldMappingW05F0006 = (props) => {
    const dispatch = useDispatch();
    const { onClose } = props;
    const [cbOldMapping, setCbOldMapping] = useState([]);
    const [MappingID, setMappingID] = useState('');

    useEffect(() => {
        getCboOldMapping();
        /* eslint-disable */
    }, [])

    const getCboOldMapping = () => {
        dispatch(w05f0006Actions.getCboOldMapping({ MappingID: '' }, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            setCbOldMapping(data);
        }));
    }

    const onAccept = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + 'W05F0007',
            state: {
                MappingID,
                Mode: 1,
                mode: 'view'
            }
        });
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            width={'50%'}
        >
            <ModalHeader title={Config.lang("Danh_sach_mapping_cu")} />
            <ModalBody>
                <Dropdown
                    label={Config.lang("Chon_mapping")}
                    dataSource={cbOldMapping}
                    onChange={e => setMappingID(e.value)}
                    valueExpr={"MappingID"}
                    displayExpr={"MappingNo"}
                    required
                    value={MappingID}
                    viewType="outlined"
                />
                <div className="text-center">
                    <Button viewType="filled" color='info' disabled={!MappingID} label={Config.lang("Dong_y")} onClick={onAccept} />
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ModalOldMappingW05F0006;