import { FormLabel as Label, TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Column } from "devextreme-react/data-grid";
import CheckBox from 'devextreme/ui/check_box';
import React, { Component } from 'react';
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Config from '../../../../config/index';
import * as W05F0007Actions from '../../../../redux/W0X/W05F0007/W05F0007_actions';
import ButtonGeneral from "../../../common/button/button-general";
import { Combo } from "../../../common/form-material";
import Modal from '../../../common/modal/modal';
import Filter from "../../../filter/filter";
import GridContainer from "../../../grid-container/grid-container";
import withStyles from "@material-ui/core/styles/withStyles";
const styles = {
    showFilter: {
        '& .filter-input': {
            display: 'block !important'
        }
    }
};
class W05F0007PopupPO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataGridPO: [],
            totalDataGridPO: 0,
            selectedRowKeys: [],
            dataCboListObjects: {
                rows: [],
                total: 0
            },
            loadingCboListObjects: false,
            dataCboListInventories: {
                rows: [],
                total: 0
            },
            loadingCboListInventories: false,
            ContractNo: '',
            StrSearch: ''
        }
        this.filter = {
            ContractNo: '',
            Supplier: '',
            InventoryID: '',
            StrSearch: '',
            skip: 0,
            limit: 15,
            MappingID: this.props.MappingID
        }
        this.tmpSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
        this.filterCboListObject = {
            strSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 10
        };
        this.filterCboListInventories = {
            strSearch: "",
            ContractID: "",
            skip: 0,
            limit: 10
        };
        this.changePage = false;
        this.isFirst = true;
        this.oldKey = [];
        this.isTemp = true;
    }
    componentDidMount = () => {
        this.getPurchaseContract();
        const { selectedRowKeysPO } = this.props;
        this.oldKey = selectedRowKeysPO.map(rs => { return rs; });
    }
    getPurchaseContract = () => {
        const { selectedRowKeysPO } = this.props;
        this.setState({ loading: true });
        this.props.W05F0007Actions.getPurchaseContract(this.filter, (error, data) => {
            if (error) {
                let message = error.message || Config.lang('ERP_Loi_chua_xac_dinh');
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                this.tmpSelectedRowKeys = selectedRowKeysPO;
                this.setState({ totalDataGridPO: data.total, dataGridPO: data.rows, loading: false, selectedRowKeys: this.tmpSelectedRowKeys });
            }
        });
    }

    handleChecked = (currentSelectedRowKeys, keyExpr) => {
        const dataCombine = this.props.dataGridPO.concat(currentSelectedRowKeys);
        dataCombine.forEach((val) => {
            if (this.tmpSelectedRowData.indexOf(val) < 0) {
                this.tmpSelectedRowData.push(val);
                this.tmpSelectedRowKeys.push(val[keyExpr]);
            }
        });
    }

    handleUnChecked = (currentDeselectedRowKeys, keyExpr) => {
        this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
        });
        this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e) < 0;
        });
    }

    onSelectionChanged = (e) => { // Fix bug chuyển trang mấy check lần đầu tiên
        const keyExpr = "PurchaseItemID";
        let currentSelectedRowKeys = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        // if (!_.isEmpty(currentSelectedRowKeys) && currentSelectedRowKeys.length < this.props.dataGridPO.length) {
        //     currentSelectedRowKeys.concat(this.props.dataGridPO);
        // }
        if (this.changePage) {
            if (currentSelectedRowKeys.length > 0) {
                this.handleChecked(currentSelectedRowKeys, keyExpr);
            }
            this.changePage = false;
        } else {
            if (currentDeselectedRowKeys.length > 0) {// UnSelect
                this.handleUnChecked(currentDeselectedRowKeys, keyExpr);
            }
            else if (currentSelectedRowKeys.length > 0) {
                this.handleChecked(currentSelectedRowKeys, keyExpr);
            }
            this.setState({ selectedRowKeys: e.selectedRowKeys });
        }
    }

    onSelect = () => {
        this.tmpSelectedRowData.map(item => {
            item.MAPQuantity = 0;
            item.Quantity = item.SUMQuantity;
            return item;
        });
        const uniqueDataKeys = [...new Set(this.tmpSelectedRowKeys)];
        this.props.refW05F0007.getDataPopup(this.tmpSelectedRowData, uniqueDataKeys, 'PO');
        this.props.refW05F0007.onOpenList('PO');
    }
    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.changePage = true;
        this.getPurchaseContract();
    }
    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.changePage = true;
        this.getPurchaseContract();
    };
    handleFilterChange(key, data) {
        if (!key) return false;
        switch (key) {
            case "ContractNo":
                this.setState({ ContractNo: data.target.value });
                break;
            case "Supplier":
                this.filter.Supplier = data.value !== null ? data.value.ObjectID : '';
                break;
            case "InventoryID":
                this.filter.InventoryID = data.value !== null ? data.value.InventoryID : '';
                break;
            default:
                break;
        }
    }
    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ['loading' + key]: status });
    };
    loadCboListObjects = (isReset) => {
        const { skip, limit, ObjectTypeID, strSearch } = this.filterCboListObject;
        const params = {
            ObjectTypeID: ObjectTypeID ? ObjectTypeID : "CC",
            StrSearch: strSearch ? strSearch : "",
            skip: skip,
            limit: limit
        };
        this.setLoading('CboListObjects', true);
        this.props.W05F0007Actions.getListObjects(params, (error, data) => {
            this.setLoading('CboListObjects', false);
            if (error) {
                let message = error.message || Config.lang('ERP_Loi_chua_xac_dinh');
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListObjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboListObjects: {
                        rows: isReset ? rows : dataCboListObjects.rows.concat(rows),
                        total: total
                    }
                });
            }
        });
    };
    getCboListInventories = (isReset) => {
        const { skip, limit, ContractID, strSearch } = this.filterCboListInventories;
        const params = {
            ContractID: ContractID ? ContractID : "",
            StrSearch: strSearch ? strSearch : "",
            skip: skip,
            limit: limit
        };
        this.setLoading('CboListInventories', true);
        this.props.W05F0007Actions.getInventories(params, (error, data) => {
            this.setLoading('CboListInventories', false);
            if (error) {
                let message = error.message || Config.lang('ERP_Loi_chua_xac_dinh');
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListInventories } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboListInventories: {
                        rows: isReset ? rows : dataCboListInventories.rows.concat(rows),
                        total: total
                    }
                });
            }
        });
    };

    onLoadDataFilter = () => {
        this.loadCboListObjects();
        this.getCboListInventories();
    };
    onFilter = () => {
        this.filter.ContractNo = this.state.ContractNo;
        this.getPurchaseContract();
    }
    onSearch = (e) => {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({ strSearch: e.target.value }, () => {
                this.filter.StrSearch = this.state.strSearch;
                this.getPurchaseContract();
            });
        }, 500
        )
    };
    renderFilters = () => {
        const { dataCboListObjects, loadingCboListObjects, dataCboListInventories, loadingCboListInventories } = this.state;
        const { ContractNo, } = this.state;
        const { Supplier, InventoryID, } = this.filter;
        return (
            <Filter
                onTextChanged={this.onSearch}
                placeholder={Config.lang("ERP_Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                className='hidden'
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_So_hop_dong")} </Label>
                                <TextField
                                    label=''
                                    variant="outlined"
                                    value={ContractNo}
                                    onChange={(e) => this.handleFilterChange("ContractNo", e)}
                                    fullWidth
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_Nha_cung_cap")} </Label>
                                <Combo.LoadMore
                                    dataSource={dataCboListObjects}
                                    skip={this.filterCboListObject.skip}
                                    limit={this.filterCboListObject.limit}
                                    displayExpr={'ObjectNameU'}
                                    keyExpr={'ObjectID'}
                                    valueExpr={'ObjectID'}
                                    value={Supplier}
                                    loading={loadingCboListObjects}
                                    stylingMode={'outlined'}
                                    label={''}
                                    showClearButton={true}
                                    onValueChanged={(e) => this.handleFilterChange("Supplier", e)}
                                    onInputChanged={(e) => {
                                        this.filterCboListObject.strSearch = e.target.value;
                                        this.filterCboListObject.skip = 0;
                                        this.loadCboListObjects(true);
                                    }}
                                    itemRender={(e) => {
                                        const { data } = e;
                                        if (!data) return null;
                                        return data.ObjectID + ' - ' + data.ObjectNameU;
                                    }}
                                    onLoadMore={(e) => {
                                        this.filterCboListObject.skip = e.skip;
                                        this.filterCboListObject.limit = e.limit;
                                        this.loadCboListObjects();
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_Hang_hoa")} </Label>
                                <Combo.LoadMore
                                    dataSource={dataCboListInventories}
                                    skip={this.filterCboListInventories.skip}
                                    limit={this.filterCboListInventories.limit}
                                    displayExpr={'InventoryNameU'}
                                    keyExpr={'InventoryID'}
                                    valueExpr={'InventoryID'}
                                    value={InventoryID}
                                    loading={loadingCboListInventories}
                                    stylingMode={'outlined'}
                                    label={''}
                                    showClearButton={true}
                                    onValueChanged={(e) => this.handleFilterChange("InventoryID", e)}
                                    onInputChanged={(e) => {
                                        this.filterCboListInventories.strSearch = e.target.value;
                                        this.filterCboListInventories.skip = 0;
                                        this.getCboListInventories(true);
                                    }}
                                    itemRender={(e) => {
                                        const { data } = e;
                                        if (!data) return null;
                                        return data.InventoryID + ' - ' + data.InventoryNameU;
                                    }}
                                    onLoadMore={(e) => {
                                        this.filterCboListInventories.skip = e.skip;
                                        this.filterCboListInventories.limit = e.limit;
                                        this.getCboListInventories();
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("ERP_Tim_kiem")}
                                    size={"large"}
                                    typeButton={"search"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onFilter}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };
    render() {
        const { openedPO, cellRenderNumber, classes } = this.props;
        const { totalDataGridPO, dataGridPO, loading } = this.state;

        return (
            <div>
                <Modal open={openedPO}
                    fullWidth={true}
                    maxWidth={'xl'}
                    className={classes.showFilter}
                >
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                            <div className={"display_row align-center"}>
                                <Typography variant="h6" className={"mgr10"}>{Config.lang("ERP_Danh_sach_hop_dong_mua")}</Typography>
                            </div>
                            <div>
                                <ButtonGeneral name={Config.lang('ERP_Dong1')}
                                    typeButton={'cancel'}
                                    disabled={loading}
                                    className={"mgr5"}
                                    style={{ textTransform: 'uppercase' }}
                                    size={"large"}
                                    onClick={() => this.props.refW05F0007.onOpenList('PO')} />
                                <ButtonGeneral name={Config.lang('ERP_Chon')}
                                    color={"primary"}
                                    variant={"contained"}
                                    loading={loading}
                                    style={{ textTransform: 'uppercase' }}
                                    size={"large"}
                                    onClick={this.onSelect} />
                            </div>
                        </div>
                    </Modal.Title>
                    <Divider />
                    <Modal.Content>
                        <FormGroup>
                            <Row >
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_Tim_kiem_doi_tuong")} </Label>
                                    <div >{this.renderFilters()}</div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <GridContainer
                            reference={ref => this.dataGridPopupPO = ref}
                            totalItems={totalDataGridPO}
                            itemPerPage={this.filter.limit}
                            skipPerPage={this.filter.skip}
                            listPerPage={[15, 30, 45, 60]}
                            dataSource={dataGridPO}
                            disabled={false}
                            keyExpr={"PurchaseItemID"}

                            gridProps={{
                                style: {
                                    minHeight: 400,
                                }
                            }}
                            pagerFullScreen={false}
                            showBorders={false}
                            showRowLines={false}
                            columnAutoWidth={true}
                            typeShort={window.innerWidth < 768}
                            loadPanel={{
                                enabled: loading
                            }}
                            height={"calc(100vh - 250px)"}
                            selection={{
                                allowSelectAll: true,
                                mode: "multiple",
                                selectAllMode: "",
                                showCheckBoxesMode: "always"
                            }}
                            allowColumnResizing={true}
                            filterRow={{
                                visible: false,
                                showOperationChooser: false,
                            }}
                            filterPanel={{
                                visible: false,
                            }}
                            filterBuilderPopup={{
                                onInitialized: (e) => {
                                    e.component.dispose();
                                    e.element.remove();
                                }
                            }}
                            // filterValue={filterGridValue}
                            selectedRowKey={this.tmpSelectedRowKeys}
                            onChangePage={this.onChangePage}
                            onChangePerPage={this.onChangePerPage}
                            onSelectionChanged={(e) => this.onSelectionChanged(e)}
                            onCellPrepared={e => {
                                const isValid = this.oldKey.find(rs => rs === e.row?.data?.PurchaseItemID);
                                if (isValid !== undefined) {
                                    let editor = CheckBox.getInstance(e.cellElement.querySelector(".dx-select-checkbox"));
                                    editor && editor.option("disabled", true);
                                    e.cellElement.style.pointerEvents = 'none';
                                }
                            }}
                        >
                            <Column
                                dataField={'ContractID'}
                                alignment={"left"}
                                visible={false}
                            />
                            <Column
                                caption={Config.lang("ERP_So_hop_dong")} dataField={'ContractNo'} minWidth={150}
                                fixed
                            />
                            <Column
                                caption={Config.lang("ERP_Nha_cung_cap")} dataField={'Supplier'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Ma_hang")} dataField={'InventoryID'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Ten_hang")} dataField={'InventoryNameU'} minWidth={150}
                            />
                            <Column
                                caption={Config.lang("ERP_Tong_so_luong")} dataField={'TotalQuantity'} minWidth={150}
                                cellRender={(e) => cellRenderNumber(e.displayValue)}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_da_mapping")} dataField={'FMAPQuantity'} minWidth={150}
                                cellRender={(e) => cellRenderNumber(e.displayValue)}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_co_the_mapping")} dataField={'SUMQuantity'} minWidth={200}
                                cellRender={(e) => cellRenderNumber(e.displayValue)}
                            />
                        </GridContainer>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W05F0007Actions: bindActionCreators(W05F0007Actions, dispatch)
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(W05F0007PopupPO);