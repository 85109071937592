import React, { Component } from 'react';
import Config from '../../../../config/index';
import GridContainer from "../../../grid-container/grid-container";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import HeadClick from "../../../grid-container/head-click";
import NumberFormat from 'react-number-format';
import { TextField } from "../../../common/form-material";
import GridActionBar from '../../../grid-container/grid-actionbar';
import SwapHorizIcon from "@material-ui/icons/SwapHoriz"
import InlineSVG from "react-inlinesvg";
import { Tooltip, ButtonIcon } from 'diginet-core-ui/components';
const styles = {
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
    },
    MuiPopover: {
        left: '130px !important'
    },
    wordWrap: {
        '& div': {
            whiteSpace: 'normal !important',
            maxWidth: '130px',
            textAlign: 'right'
        }
    },
    scaleHeader: {
        '& .dx-datagrid-headers .dx-header-row': {
            '& td': {
                padding: '5px !important'
            }
        }
    },
    columnActions: {
        height: '37px !important',
    },

    removeBorder: {
        height: '37px !important',
        "& div": {
            "&:before": {
                borderBottomStyle: 'none !important',
                border: 'none !important'
            },
            "&:after": {
                border: 'none !important'
            },
            "& input": {
                height: "37x !important",
                textAlign: 'right !important',
            },
        }
    },
    numberInput: {
        width: '100%',
        paddingRight: '8px',
        paddingTop: '9px',
    },
    headerCenter: {
        '& .dx-datagrid-text-content': {
            textAlign: 'center'
        }
    },
    removeBg: {
        '& .cell-selected': {
            backgroundColor: '#EFF5FF !important'
        }
    },
    hoverDetail: {
        pading: 0,
        '& :hover': {
            '& path': {
                fill: '#111D5E !important'
            }
        }
    }
};
class W05F0007MappingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            popOver: null,
            currentPosition: {},
            selectedRange: {},
        }
        this.selectedRowIndx = null;
        this.component = null;
        this.rowData = null;

        this.editorElement = null;
        this.selectedRange = {}
    }
    componentDidMount() {
        if (this.props.refGridMappingInfo) this.props.refGridMappingInfo(this);
    }
    onDeleteMapping = async (e) => {
        this.rowData = e.data;
        if (e.data.packing.length > 0 || e.data.delivery.length > 0) {
            Config.popup.show("INFO", "Dữ liệu đã được sử dụng. Bạn không được xóa");
        } else {
            Config.popup.show(
                "YES_NO",
                Config.lang("ERP_Ban_co_chac_muon_xoa?"),
                () => {
                    this.gridTabMappingInfo.instance.deleteRow(e.rowIndex);
                    // this.gridTabMappingInfo.instance.saveEditData();
                    this.props.refW05F0007.deleteDataMappingInfo(this.rowData.QuotationItemID, this.rowData.PurchaseItemID, () => {
                        this.rowData = null;
                        this.setState({ popOver: null, currentPosition: null });
                        this.props.refW05F0007.setIsUpdate();
                    });
                },
                () => {
                    //no
                }
            );
        }
    }
    // onEditRow = (e) => {
    //     this.setState({ isEditing: true, popOver: null, currentPosition: null }, () => {
    //         setTimeout(() => {
    //             if (e) e.component.editRow(e.rowIndex);
    //         }, 300);
    //     });
    // };
    onRowClick = (e) => {
        if (e.component.hasEditData() || this.selectedRowIndx === e.rowIndex) return;
        this.selectedRowIndx = e.rowIndex;
        this.rowData = e.data
        if (!this.state.isEditing && e.data !== undefined) {
            this.props.refW05F0007.setSelectedRow_SO_PO(e.data);
            if (this.props.rowDataInfoMapping === null) {
                this.props.refW05F0007.setRowDataInfoMapping(e.data)
            } else if (this.rowData.MappingNo !== this.props.rowDataInfoMapping.MappingNo) {
                this.props.refW05F0007.setRowDataInfoMapping(e.data)
            }
        }
    }

    // handleClick = (event, data, stateName, e) => {
    //     this.rowData = e.data
    //     this.component = e;
    //     this.props.refW05F0007.setRowDataInfoMapping(e.data)
    //     this.setState({ [stateName]: event.currentTarget, currentPosition: data && data });
    // };
    handleClose = (stateName) => {
        this.component = null;
        this.setState({ [stateName]: null, currentPosition: null });
    };

    renderHeaderColumns = (e) => {
        if (!e) return false;
        // return <div style={{color: col.HeaderTextColor}}>{col.Caption}</div>
        return <HeadClick selectedRange={this.state.selectedRange}
            style={{ whiteSpace: 'normal' }}
            dataHeaderCell={e}
            allowClick={true}
            callbackAfterCopy={rs => {
                this.setState({ isUpdated: rs.IsUpdated });
            }}
        />
    };
    renderButtonAction = e => {
        return (
            <GridActionBar >
                <Tooltip title={Config.lang('Xoa')}>
                    <ButtonIcon
                        circular
                        size="small"
                        viewType="text"
                        name="delete"
                        onClick={() => this.onDeleteMapping(e)} />
                </Tooltip>
            </GridActionBar>
        )
    };
    headerCellRender = (e) => (
        <label>{e.column.caption}</label>
    )

    onSaveGrid = async () => {
        this.selectedRowIndx = null;
        await this.gridTabMappingInfo.instance.saveEditData();
    }
    onSwapMapping = (e) => {
        this.props.onSwapMapping(e);
    }

    renderTotal = (e, dataField) => {
        let decimal = null;
        if (dataField.includes("Quantity") && Math.floor(e.value) !== e.value) {
            const {decimals} = this.props;
            decimal = decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5;
        }
        return this.props.cellRenderNumber(e.value, decimal);
    };

    render() {
        const { isView, dataGridMappingInfo, decimals, classes, refW05F0007, isSwapMapping, isEdit, disabledAllForSave } = this.props;
        return (
            <div>
                {/* {this.popupOpen()} */}
                <GridContainer
                    reference={ref => this.gridTabMappingInfo = ref}
                    dataSource={dataGridMappingInfo}
                    columnAutoWidth={true}
                    listPerPage={[5, 10, 15, 30, 45, 60]}
                    itemPerPage={5}
                    typePaging={"normal"}
                    pagerFullScreen={false}
                    keyExpr={'MappingItemID'}
                    height={300}
                    elementAttr={{
                        class: classes.scaleHeader + ' noBGSelect'
                    }}
                    typeShort={true}
                    showColumnLines={false}
                    showRowLines={false}
                    onCellClick={this.onRowClick}
                    onKeyDown={(e) => {
                        Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.gridTabMappingInfo,
                            (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                            , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                    }}
                    allowCellSelection={true}
                    hoverStateEnabled={true}
                    sorting={{
                        mode: "none"
                    }}
                    editing={{
                        mode: 'cell',
                        refreshMode: "reshape",
                        allowUpdating: !isView && !isSwapMapping && !disabledAllForSave,
                        texts: {
                            confirmDeleteMessage: ""
                        },
                        // startEditAction: "dblClick"
                    }}
                    onRowUpdated={(e) => {
                        e.data.IsUpdated = 1;
                        this.setState({ isUpdated: true, isEditing: false });
                        this.props.refW05F0007.setIsUpdate();
                        const { startRowIndex, startColumnIndex } = this.selectedRange;
                        let dataField = ['QuantityPack', 'Quantity', 'StartDate', 'EndDate'];
                        let currentFieldName = this.gridTabMappingInfo.instance.getVisibleColumns()[startColumnIndex]?.dataField;
                        if (dataField.includes(currentFieldName)) {
                            setTimeout(() => {
                                this.gridTabMappingInfo.instance.editCell(startRowIndex, startColumnIndex)
                            }, 100);
                        }
                    }}
                    onRowUpdating={async (e) => {
                        if (e.newData.Quantity !== undefined) {
                            const QuantityActual = e.newData.QuantityActual !== undefined ? e.newData.QuantityActual : e.oldData.QuantityActual !== undefined ? e.oldData.QuantityActual : 0;
                            const Quantity = e.newData.Quantity !== undefined ? e.newData.Quantity : e.oldData.Quantity !== undefined ? e.oldData.Quantity : 0;
                            if ((Number(Quantity) > Number(QuantityActual))) {
                                await this.props.refW05F0007.setSumQuantity(e.oldData, e.newData, (bool) => {
                                    if (bool) {
                                        // e.cancel = true
                                        e.newData.Quantity = e.oldData.Quantity;
                                        Config.popup.show('INFO', Config.lang('ERP_So_luong_khong_hop_le'));
                                    } else {
                                        e.newData.QuantityRemain = Number(Quantity) - Number(QuantityActual);
                                    }
                                });
                            } else {
                                e.newData.Quantity = e.oldData.Quantity;
                                Config.popup.show('INFO', Config.lang('ERP_So_luong_khong_hop_le'));
                            }

                        }
                        if (e.newData.QuantityPack) {
                            const sumNum01 = this.props.refW05F0007.sumData(e.oldData.packing, 'Num01');
                            if (e.newData.QuantityPack < sumNum01) {
                                e.cancel = true;
                                e.newData.QuantityPack = e.oldData.QuantityPack;
                                Config.popup.show('INFO', Config.lang('So_luong_bao_yeu_cau_vua_nhap_khong_hop_le'));
                            } else {
                                e.oldData.packing.map((rs, indx) => {
                                    if (indx === 0) {
                                        rs.Num02 = e.newData.QuantityPack - rs.Num01;
                                    } else {
                                        rs.Num02 = e.oldData.packing[indx - 1].Num02 - rs.Num01;
                                    }
                                    rs.IsUpdated = 1;
                                    return rs;
                                });
                                this.props.refW05F0007.setDataGridMappingInfo([...dataGridMappingInfo]);
                            }

                        }
                    }}
                    onCellSelectionChanged={(e) => {
                        let invalid = ['StartDate', 'EndDate'];
                        let invalidFieldName = e.component.getVisibleColumns()[e.selectedRange.startColumnIndex]?.dataField;
                        if (e.component.hasEditData() && !invalid.includes(invalidFieldName)) {
                            this.gridTabMappingInfo.instance.saveEditData();
                        }
                        if (e.selectedRange && e.selectedRange.rowType !== "header" && e.selectedRange.rowType !== '' && !this.state.isEditing && !isView) {
                            if (JSON.stringify(this.state.selectedRange) !== JSON.stringify(e.selectedRange)) {
                                this.selectedRange = e.selectedRange;
                                this.setState({ selectedRange: e.selectedRange }, () => {
                                    let dataField = ['QuantityPack', 'Quantity', 'StartDate', 'EndDate'];
                                    let currentFieldName = e.component.getVisibleColumns()[e.selectedRange.startColumnIndex]?.dataField;
                                    if (dataField.includes(currentFieldName)) {
                                        setTimeout(() => {
                                            this.gridTabMappingInfo.instance.editCell(e.selectedRange.startRowIndex, e.selectedRange.startColumnIndex)
                                        }, 100);
                                    }
                                });
                            }
                        }
                    }}
                >
                    <Column
                        width={80} caption={Config.lang('ERP_Chi_tiet')}
                        fixed={true} alignment="center"
                        cssClass={classes.hoverDetail}
                        allowEditing={false} cellRender={(e) => (
                            <Tooltip title={Config.lang('Xem_chi_tiet')}>
                                <IconButton
                                    disabled={isSwapMapping || disabledAllForSave}
                                    style={{ padding: 4 }}
                                    onClick={() => this.onRowClick(e)}
                                    aria-label="detail"
                                    color={'default'}
                                >
                                    <InlineSVG src={require('./detail.svg')} />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                    <Column
                        width={150} caption={Config.lang('ERP_Chuyen_mapping')}
                        alignment="center"
                        visible={isEdit}
                        cssClass={classes.hoverDetail}
                        allowEditing={false} cellRender={(e) => (
                            <Tooltip title={Config.lang('Chuyen_mapping')}>
                                <IconButton
                                    disabled={isSwapMapping || disabledAllForSave}
                                    style={{ padding: 4 }}
                                    onClick={() => this.onSwapMapping(e)}
                                    aria-label="delete"
                                    color={'default'}
                                >
                                    <SwapHorizIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                    <Column
                        caption={Config.lang("ERP_So_mapping")} dataField={'MappingNo'} allowEditing={false} minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_So_hop_dong_mua")} dataField={'ContractNo'} allowEditing={false} minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Nha_cung_cap")} dataField={'Supplier'} allowEditing={false} minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_lot")} dataField={'LotNo'} allowEditing={false} minWidth={150}
                    />
                    <Column caption={Config.lang("ERP_Hop_dong_mua")} alignment="center">
                        <Column
                            caption={Config.lang("ERP_Ma_hang")} dataField={'InventoryIDPO'} alignment="left"
                            allowEditing={false} minWidth={200} cssClass={classes.headerCenter} headerCellRender={this.headerCellRender}
                        />
                        <Column
                            caption={Config.lang("ERP_Ten_hang")} dataField={'InventoryNamePO'} alignment="left" allowEditing={false} minWidth={200}
                            cssClass={classes.headerCenter} headerCellRender={this.headerCellRender}
                        />
                    </Column>
                    <Column caption={Config.lang("ERP_Hop_dong_ban")} alignment="center">
                        <Column
                            caption={Config.lang("ERP_Ma_hang")} dataField={'InventoryIDSO'} alignment="left" allowEditing={false} minWidth={200}
                            cssClass={classes.headerCenter} headerCellRender={this.headerCellRender}
                        />
                        <Column
                            caption={Config.lang("ERP_Ten_hang")} dataField={'InventoryNameSO'} alignment="left" allowEditing={false} minWidth={200}
                            cssClass={classes.headerCenter} headerCellRender={this.headerCellRender}
                        />
                    </Column>
                    <Column
                        caption={Config.lang("ERP_So_luong_bao_yeu_cau")} dataField={'QuantityPack'} minWidth={200}
                        dataType={"number"}
                        // format={{
                        //     type: 'decimal',
                        //     min: 0
                        // }}
                        // editorOptions={{
                        //     min: 0,
                        //     showClearButton: true,
                        //     format: {
                        //         type: 'fixedPoint',
                        //         precision: decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5,
                        //     },
                        // }}

                        headerCellRender={this.renderHeaderColumns}
                        cellRender={refW05F0007.renderNumberFormat}
                        cssClass={classes.removeBorder}
                        editCellRender={e => {
                            setTimeout(() => {
                                e.component.focus(e.cellElement)
                            }, 100);
                            return (
                                <NumberFormat
                                    className={classes.numberInput}
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    style={{ marginBottom: -6, marginTop: -6, backgroundColor: 'transparent' }}
                                    autoFocus={true}
                                    decimalScale={decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5}
                                    value={String(e.value) || ""}
                                    onValueChange={rs => e.setValue(rs.value)
                                    }
                                />
                            )
                        }}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_yeu_cau_cua_mapping")} dataField={'Quantity'} width={150}
                        dataType={"number"}
                        // format={{
                        //     type: 'decimal',
                        //     min: 0
                        // }}
                        // editorOptions={{
                        //     min: 0,
                        //     showClearButton: true,
                        //     format: {
                        //         type: 'fixedPoint',
                        //         precision: decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5,
                        //     },
                        // }}
                        headerCellRender={this.renderHeaderColumns}
                        cellRender={refW05F0007.renderNumberFormat}
                        cssClass={classes.removeBorder}
                        editCellRender={e => {
                            setTimeout(() => {
                                e.component.focus(e.cellElement)
                            }, 100);
                            return (
                                <NumberFormat
                                    className={classes.numberInput}
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    style={{ marginBottom: -6, marginTop: -6, backgroundColor: 'transparent' }}
                                    autoFocus={true}
                                    decimalScale={decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5}
                                    value={String(e.value) || ""}
                                    onValueChange={rs => e.setValue(rs.value)
                                    }
                                />
                            )
                        }}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_thuc_hien_cua_mapping")} cssClass={classes.wordWrap} dataField={'QuantityActual'} width={150} allowEditing={false}
                        dataType={"number"}
                        format={{
                            type: 'decimal',
                            min: 0
                        }}
                        editorOptions={{
                            min: 0,
                            showClearButton: true,
                            format: {
                                type: 'fixedPoint',
                                precision: decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5,
                            },
                        }}
                        cellRender={refW05F0007.renderNumberFormat}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_con_lai")} dataField={'QuantityRemain'} minWidth={150} allowEditing={false}
                        dataType={"number"}
                        format={{
                            type: 'decimal',
                            min: 0
                        }}
                        editorOptions={{
                            min: 0,
                            showClearButton: true,
                            format: {
                                type: 'fixedPoint',
                                precision: decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5,
                            },
                        }}
                        cellRender={refW05F0007.renderNumberFormat}
                    />
                    <Column caption={Config.lang("ERP_Thoi_gian_dong_hang")} dataField={"dateFormTo"} alignment="center" >
                        <Column
                            caption={Config.lang("ERP_Tu")} dataField={'StartDate'}
                            format={'dd/MM/yyyy'}
                            alignment={'center'}
                            dataType={"date"}
                            width={150}
                            editorOptions={{
                                placeholder: "DD/MM/YYYY",
                                allowUpdating: !isView,
                                dateSerializationFormat: 'yyyy-MM-dd'
                            }}
                            headerCellRender={this.renderHeaderColumns}
                        />
                        <Column
                            caption={Config.lang("ERP_Den")} dataField={'EndDate'}
                            format={'dd/MM/yyyy'}
                            alignment={'center'}
                            dataType={"date"}
                            width={150}
                            editorOptions={{
                                placeholder: "DD/MM/YYYY",
                                allowUpdating: !isView,
                                dateSerializationFormat: 'yyyy-MM-dd'
                            }}
                            headerCellRender={this.renderHeaderColumns}
                        />
                    </Column>
                    <Column
                        width={100} visible={false}
                    />
                    <Column
                        fixed={true}
                        fixedPosition={"right"}
                        alignment="center"
                        cssClass={classes.columnActions}
                        cellRender={this.renderButtonAction}
                        visible={(!isView || Config.isMobile) && !isSwapMapping && !disabledAllForSave}
                        allowEditing={false}
                    />
                    <Summary recalculateWhileEditing={false} >
                        <TotalItem summaryType="sum" displayFormat="{0}" column="QuantityPack" customizeText={(e) => this.renderTotal(e, "QuantityPack")} />
                        <TotalItem summaryType="sum" displayFormat="{0}" column="Quantity" customizeText={(e) => this.renderTotal(e, "Quantity")} />
                        <TotalItem summaryType="sum" displayFormat="{0}" column="QuantityActual" customizeText={(e) => this.renderTotal(e, "QuantityActual")} />
                        <TotalItem summaryType="sum" displayFormat="{0}" column="QuantityRemain" customizeText={(e) => this.renderTotal(e, "QuantityRemain")} />
                    </Summary>
                </GridContainer>
            </div >
        );
    }
}

export default withStyles(styles)(W05F0007MappingInfo);
